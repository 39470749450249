import { FormFieldDefinition } from '../../../types';
import { CreateCustomerSupportFormFields } from '../containers/CustomerSupportContainer';

export type UseCustomerSupportFormFieldsValues = Array<
  FormFieldDefinition<CreateCustomerSupportFormFields>
>;

const useCustomerSupportFormFields = (): UseCustomerSupportFormFieldsValues => {
  const fields: Array<FormFieldDefinition<CreateCustomerSupportFormFields>> = [
    {
      labelId: 'customerSupport.form.contactUsEmail.label',
      name: CreateCustomerSupportFormFields.ContactUsEmail,
      type: 'text',
      required: false,
      isVisible: true,
    },
    {
      labelId: 'customerSupport.form.callUsNumber.label',
      name: CreateCustomerSupportFormFields.CallUsNumber,
      type: 'text',
      required: false,
      isVisible: true,
    },
    {
      labelId: 'customerSupport.form.chatSupportUrl.label',
      name: CreateCustomerSupportFormFields.ChatSupportUrl,
      type: 'text',
      required: false,
      isVisible: true,
    },
    {
      labelId: 'customerSupport.form.manageYourDataUrl.label',
      name: CreateCustomerSupportFormFields.ManageYourDataUrl,
      type: 'text',
      required: false,
      isVisible: true,
    },
    {
      labelId: 'customerSupport.form.faqLink.label',
      name: CreateCustomerSupportFormFields.FaqLink,
      type: 'text',
      required: false,
      isVisible: true,
    },
    {
      labelId: 'customerSupport.form.privacyLink.label',
      name: CreateCustomerSupportFormFields.PrivacyLink,
      type: 'text',
      required: false,
      isVisible: true,
    },
    {
      labelId: 'customerSupport.form.termsLink.label',
      name: CreateCustomerSupportFormFields.TermsLink,
      type: 'text',
      required: false,
      isVisible: true,
    },
  ];

  return fields;
};

export default useCustomerSupportFormFields;
