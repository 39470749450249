import { AxiosResponse } from 'axios';
import {
  CreateCompanyDto,
  CreateCompanyResponse,
  Company,
  UpdateCompanyDto,
} from '../types';
import { FlexApiUrls } from '../../../urls';
import { FlexApiResponse, FlexApiSingleResponse, Maybe } from '../../../types';
import { HttpClient } from '../../../httpClient';

export default class CompanyClient extends HttpClient {
  async fetchCompanies(
    partnerId: string,
    page: number,
    limit: number,
  ): Promise<AxiosResponse<FlexApiResponse<Company[]>>> {
    const response = await this.get<AxiosResponse<FlexApiResponse<Company[]>>>(
      FlexApiUrls.getCompaniesUrl(partnerId, page, limit),
    );
    return response;
  }
  async fetchCompany(
    partnerId: string,
    companyId: string,
  ): Promise<AxiosResponse<FlexApiSingleResponse<Company>>> {
    const response = await this.get<
      AxiosResponse<FlexApiSingleResponse<Company>>
    >(FlexApiUrls.getCompanyUrl(partnerId, companyId));
    return response;
  }

  async createCompany(
    dto: CreateCompanyDto,
    partnerId: string,
  ): Promise<AxiosResponse<CreateCompanyResponse>> {
    return await this.post<AxiosResponse<CreateCompanyResponse>>(
      FlexApiUrls.getCreateCompanyUrl(partnerId),
      dto,
    );
  }

  async updateCompany(
    partnerId: string,
    companyId: string,
    dto: UpdateCompanyDto,
  ): Promise<AxiosResponse<FlexApiResponse<any>>> {
    const response = await this.put<
      AxiosResponse<FlexApiResponse<CreateCompanyResponse>>
    >(FlexApiUrls.getUpdateCompanyUrl(partnerId, companyId), dto);
    return response;
  }

  async deleteCompany(
    partnerId: Maybe<string>,
    companyId: string,
  ): Promise<void> {
    await this.delete(FlexApiUrls.getDeleteCompanyUrl(partnerId, companyId));
  }
}
