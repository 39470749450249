import { TimeLinePeriodTypes } from '../types';

export const tickFormatter = (value: any, suffix: string = 'M') => {
  const parsedValue = parseInt(value, 10);
  if (parsedValue > 1000000) {
    const valueInMillion = parsedValue / 1000000;
    return `${valueInMillion}${suffix}`;
  }
  return parsedValue.toString();
};

export const renderXaxisTicks = (selectedPeriodType: any) => {
  if (selectedPeriodType === TimeLinePeriodTypes.Last24Hours) {
    return 2;
  } else if (
    selectedPeriodType === TimeLinePeriodTypes.CalendarMonth ||
    TimeLinePeriodTypes.Last30Days
  ) {
    return 5;
  }
};
