import React, { FunctionComponent } from 'react';
import { AreaChart, Block, Heading, PendingContent, Spinner } from 'plume-ui';
import { ChartPeriodContext } from '../../../contexts/ChartPeriodContext';
import FormattedMessage from '../../../../../utils/components/FormattedMessage';
import { TimeLinePeriodTypes } from '../../../types';
import MonthDropdown from '../../MonthDropdown/MonthDropdown';
import TimeLinePicker from '../../TimelinePicker/TimelinePicker';
import { useTranslation } from 'react-i18next';
import { useTimeLine } from '../../../hooks/useTimeLine';
import { BlockStyles } from 'plume-ui/dist/components/Block/Block';
import { selectedCompanySelector } from '../../../../../state';
import { useRecoilValue } from 'recoil';
import { useFetchData } from '../../../../../hooks/useFetchData';
import { DependencyContainer } from '../../../../../DependencyContainer';
import { renderXaxisTicks } from '../../../helpers/tick-formatter';

const addStylesToBlock = (current: BlockStyles) => ({
  ...current,
  root: `${current.root} EmployeeOnboarding`,
});
const { statusService } = new DependencyContainer();
const EmployeeOnboardingChartContainer: FunctionComponent = () => {
  const { t } = useTranslation();
  const {
    getContextDefaultValues,
    selectedPeriodType,
    selectedMonth,
    getPeriod,
    getType,
  } = useTimeLine();

  const employeeOnboardingGroups = [
    {
      label: t('employeeOnboarding.provisioned'),
      backgroundColor: {
        from: 'rgba(31, 220, 144, 0.15)',
        to: 'rgba(136, 132, 255, 0.15)',
      },
      stroke: '#58EEC7',
      strokeWidth: 2,
      key: 'groupA',
    },
    {
      label: t('employeeOnboarding.onboarded'),
      backgroundColor: {
        from: 'rgba(98, 105, 255, 0.15)',
        to: 'rgba(133, 190, 255, 0.15 )',
      },
      stroke: '#999EFF',
      strokeWidth: 2,
      key: 'groupB',
    },
  ];
  const selectedCompany = useRecoilValue(selectedCompanySelector);

  let [loading, data, error] = useFetchData<
    Array<Record<'xAxisLabel' | string, string | number>>,
    any
  >(
    async () => {
      if (!selectedCompany) {
        return;
      }
      const [period, type] = [getPeriod(), getType()];
      return await statusService.getEmployeeOnboarding(
        selectedCompany.id,
        period,
        type,
      );
    },
    undefined,
    [selectedCompany, selectedPeriodType, selectedMonth],
    () => selectedCompany === undefined,
  );

  return (
    <ChartPeriodContext.Provider value={getContextDefaultValues()}>
      <PendingContent
        loading={loading}
        isError={Boolean(error)}
        hideContent
        loader={Spinner}
      >
        <Block classes={addStylesToBlock} id="EmployeeOnboardingChart">
          <Heading level={3} size="xl" medium>
            <FormattedMessage id="employeeOnboarding.title" />
          </Heading>
          <div className="EmployeeOnboarding__timeline--container">
            <span className="EmployeeOnboarding__timeline--month">
              {selectedPeriodType === TimeLinePeriodTypes.CalendarMonth && (
                <MonthDropdown />
              )}
            </span>
            <TimeLinePicker />
          </div>
          {!data?.length ? (
            <div className="EmployeeOnboarding__empty--container">
              <FormattedMessage id="noDataAvailable" />
            </div>
          ) : (
            <AreaChart
              allowDecimalsYAxis={false}
              domainY={[0, 'auto']}
              isStacked={false}
              data={data}
              groups={employeeOnboardingGroups}
              legendPosition="bottom"
              xAxisTickInterval={renderXaxisTicks(selectedPeriodType)}
            />
          )}
        </Block>
      </PendingContent>
    </ChartPeriodContext.Provider>
  );
};
export default EmployeeOnboardingChartContainer;
