import React, { FormEvent, FunctionComponent } from 'react';
import { Button, Icons, InputField } from 'plume-ui';
import { FieldInputProps, FormikProps } from 'formik';
import FormattedMessage from '../../../../utils/components/FormattedMessage';
import { InputFieldStyles } from 'plume-ui/dist/components/InputField/InputField';
import { dedup } from '../../../../helpers/arrays';

export type FormikDHCPOptionValue = {
  id: number;
  value: string;
};

type FormikDHCPOptionsPickerProps = {
  messages: any[];
  field: FieldInputProps<FormikDHCPOptionValue[]>;
  form: FormikProps<FormikDHCPOptionValue[]>;
} & JSX.IntrinsicElements['div'];

const addStylesToOptionInputField = (current: InputFieldStyles) => ({
  ...current,
  root: `${current.root} DHCPOptions__field--small`,
});

const FormikDHCPOptionsPicker: FunctionComponent<FormikDHCPOptionsPickerProps> = ({
  messages,
  field,
  form,
}) => {
  const onInputId = (e: FormEvent<HTMLInputElement>, index: number) => {
    const value = e.currentTarget.value;
    updateOptionProperty('id', value, index);
  };

  const onInputValue = (e: FormEvent<HTMLInputElement>, index: number) => {
    const value = e.currentTarget.value;
    updateOptionProperty('value', value, index);
  };

  const getNextId = () => {
    if (field.value.length === 0) {
      return 1;
    }
    const ids = field.value.map((o) => o.id);
    const max = Math.max(...ids);
    return max + 1;
  };

  const renderMessages = (): string[] => {
    if (!messages) {
      return [];
    }
    const allMessages = messages
      .filter((a) => a !== undefined && Array.isArray(a.message))
      .reduce((acc, m) => {
        const forId = m.message
          .filter((a: any) => a !== undefined)
          .map((i: any) => i.id);
        const forValue = m.message
          .filter((a: any) => a !== undefined)
          .map((i: any) => i.value);
        return [...acc, ...forId, forValue];
      }, []);
    return dedup(allMessages.filter((a: any) => a !== undefined).flat());
  };

  const updateOptionProperty = (
    name: keyof FormikDHCPOptionValue,
    value: any,
    index: number,
  ) => {
    const updatedOptions = field.value.map((o, i) => {
      if (i === index) {
        return {
          ...o,
          [name]: value,
        };
      }
      return o;
    });
    form.setFieldValue(field.name, updatedOptions);
  };

  const removeOption = (index: number) => {
    const optionsWithRemovedElement = [...field.value];
    optionsWithRemovedElement.splice(index, 1);
    form.setFieldValue(field.name, optionsWithRemovedElement);
  };

  const addOption = () => {
    form.setFieldValue(field.name, [
      ...field.value,
      { id: getNextId(), value: '' },
    ]);
  };

  return (
    <div className="DHCPOptions">
      {field.value && Boolean(field.value.length) && (
        <div className="DHCPOptions__header">
          <small>
            <FormattedMessage id="WorkNetworkConfigurations.DHCP.integer" />
          </small>
          <small>
            <FormattedMessage id="WorkNetworkConfigurations.DHCP.value" />
          </small>
        </div>
      )}

      {field.value.map((option: FormikDHCPOptionValue, index: number) => (
        <div className="DHCPOptions__field" key={`${field.name}-${index}`}>
          <InputField
            value={option.id}
            noClearIcon
            type="number"
            classes={addStylesToOptionInputField}
            onInput={(e) => onInputId(e, index)}
            placeholder="id"
          />
          <InputField
            value={option.value}
            noClearIcon
            onInput={(e) => onInputValue(e, index)}
            placeholder="value"
          />
          <div className="DHCPOptions__options-field-icon">
            <Button
              styleVariant="small-pill"
              onClick={() => removeOption(index)}
              classes={(current) => ({
                ...current,
                root: `${current.root} DHCPOptions__options-button`,
              })}
            >
              <Icons.TrashIcon width={14} />
            </Button>
          </div>
        </div>
      ))}
      {renderMessages().map((message: string, index: number) => (
        <span
          key={`${field.name}-error-${index}`}
          className="DHCPOptions__field-message DHCPOptions__field-message--error"
        >
          {message}
        </span>
      ))}
      <Button
        type="button"
        classes={(current) => ({
          ...current,
          root: `${current.root} "DHCPOptions__options-add-button`,
        })}
        onClick={() => addOption()}
      >
        <FormattedMessage id="add" />
      </Button>
    </div>
  );
};

export default FormikDHCPOptionsPicker;
