import React, { FunctionComponent } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'plume-ui';
import { useTranslation } from 'react-i18next';
import FormattedMessage from '../../utils/components/FormattedMessage';
import { useRecoilValue } from 'recoil';
import { confirmationModalAtom } from '../../state';
import { ModalStyles } from 'plume-ui/dist/components/Modal/Modal';

export type ConfirmationModalProps = {
  isOpen: boolean;
  onRequestClose: () => void;
  onConfirm: () => void;
};

const ConfirmationModal: FunctionComponent<ConfirmationModalProps> = (
  props,
) => {
  const { t } = useTranslation();
  const confirmationModal = useRecoilValue(confirmationModalAtom);

  const onCancel = () => {
    props.onRequestClose();
  };

  const onConfirm = () => {
    props.onConfirm();
    props.onRequestClose();
  };

  return (
    <Modal isOpen={props.isOpen} onRequestClose={onCancel}>
      <ModalHeader title={confirmationModal.title || t('confirmation.title')} />
      <ModalBody
        classes={(current: ModalStyles) => ({
          ...current,
          root: `${current.root} ConfirmationModal__body`,
        })}
      >
        {confirmationModal.body || <FormattedMessage id="confirmation.body" />}
      </ModalBody>
      <ModalFooter>
        <Button
          type="button"
          styleVariant="tertiary-grey"
          onClick={() => onCancel()}
        >
          {confirmationModal.cancelButtonLabel || (
            <FormattedMessage id="confirmation.cancel" />
          )}
        </Button>
        <Button
          type="button"
          styleVariant="superprimary"
          onClick={() => onConfirm()}
          classes={(current) => ({
            ...current,
            root: `${current.root} m-l-xs`,
          })}
        >
          {confirmationModal.confirmButtonLabel || (
            <FormattedMessage id="confirmation.confirm" />
          )}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationModal;
