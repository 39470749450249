import { notify } from 'plume-ui';
import { useTranslation } from 'react-i18next';
import { copyToClipboard } from '../../../helpers/text';
import { Company } from '../types';

type UseCopyCompanyId<T extends Company> = (entity: T) => void;

export function useCopyTemplateId<T extends Company>(): UseCopyCompanyId<T> {
  const { t } = useTranslation();

  async function onCopyTemplateId<T extends Company>(company: T) {
    if (!company) {
      return;
    }
    await copyToClipboard(company.id, () => {
      notify({
        title: t('success'),
        body: t('companyIdCopied'),
        type: 'success',
      });
    });
  }

  return onCopyTemplateId;
}

export default useCopyTemplateId;
