import React from 'react';
import { AdjustableComponent } from '../../utils/hooks/useClasses';
import { Checkbox } from 'plume-ui';
import { FieldInputProps, FormikProps } from 'formik';

type FormikInputCheckboxGroupValue = {
  name: string;
  label: string;
  value: any;
  disabled: boolean;
};

type FormikInputCheckboxGroupProps = {
  field: FieldInputProps<string[]>;
  form: FormikProps<string[]>;
  label: string;
  values: FormikInputCheckboxGroupValue[];
} & Omit<JSX.IntrinsicElements['input'], 'value' | 'type'>;

export type FormikInputCheckboxStyles = {
  root: string;
};

const FormikInputCheckboxGroup: AdjustableComponent<
  FormikInputCheckboxGroupProps,
  FormikInputCheckboxStyles
> = ({ values, label, field, form, classes, ...props }) => {
  const isChecked = (value: FormikInputCheckboxGroupValue) => {
    return field.value.includes(value.value);
  };

  const onCheck = (value: FormikInputCheckboxGroupValue) => {
    const index = field.value.indexOf(value.value);
    const checkedValuesClone = [...field.value];
    if (index !== -1) {
      checkedValuesClone.splice(index, 1);
    } else {
      checkedValuesClone.push(value.value);
    }
    form.setFieldValue(field.name, checkedValuesClone);
  };

  return (
    <div className="FormikInputCheckboxGroup">
      {values.map((value) => (
        <Checkbox
          key={value.name}
          label={value.label}
          name={field.name}
          value={value.value}
          checked={isChecked(value)}
          onChange={() => onCheck(value)}
          disabled={value.disabled}
        />
      ))}
    </div>
  );
};

export default FormikInputCheckboxGroup;
