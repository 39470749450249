import React, { FunctionComponent, useCallback } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  notify,
  PendingContent,
  Spinner,
} from 'plume-ui';
import { DependencyContainer } from '../../DependencyContainer';
import { useRecoilValue } from 'recoil';
import { selectedCompanySelector } from '../../state';
import { ModalStyles } from 'plume-ui/dist/components/Modal/Modal';
import { useFetchData } from '../../hooks/useFetchData';
import { Maybe } from '../../types';
import {
  BulkUploadsItem,
  BulkUploadStatus,
} from '../../features/individualPolicies/types';
import { useTranslation } from 'react-i18next';
import FormattedMessage from '../../utils/components/FormattedMessage';

export type UploadModalProps = {
  uploadId: Maybe<string>;
  isOpen: boolean;
  onRequestClose: () => void;
};

const { individualPoliciesService } = new DependencyContainer();

const UploadDetailsModal: FunctionComponent<UploadModalProps> = ({
  uploadId,
  ...props
}) => {
  const { t } = useTranslation();
  const selectedCompany = useRecoilValue(selectedCompanySelector);
  const [
    loadingUploadDetails,
    uploadDetails,
    uploadDetailsError,
    runFetchUploadDetails,
  ] = useFetchData<BulkUploadsItem, any>(
    async () => {
      if (!selectedCompany || !uploadId) {
        return;
      }
      return await individualPoliciesService.getUploadById(
        uploadId,
        selectedCompany.id,
      );
    },
    undefined,
    [selectedCompany, uploadId],
    () => selectedCompany === undefined || uploadId === undefined,
  );

  const onAfterOpen = async () => {
    if (!selectedCompany || !uploadId) {
      return;
    }
    await runFetchUploadDetails();
  };

  const onClose = () => {
    props.onRequestClose();
  };

  const onDownloadReports = useCallback(async () => {
    if (!uploadDetails || !selectedCompany) {
      return;
    }
    if (uploadDetails.failedRowCount > 0) {
      try {
        const response = await individualPoliciesService.fetchFailedRowsUrl(
          uploadDetails.id,
          selectedCompany.id,
        );
        const newWindow = window.open(response.url, '_blank');
        await setTimeout(() => newWindow!.close(), 600);
      } catch (error) {
        notify({
          title: t('error'),
          body: t('somethingWentWrong'),
          type: 'error',
        });
      }
    }
  }, [uploadDetails, selectedCompany]);

  const failedRowsClassName =
    (uploadDetails?.failedRowCount || 0) > 0 ? 'color-status-error' : '';

  const renderDetailsFooter = (details: BulkUploadsItem) => {
    if (details.status === BulkUploadStatus.Wait) {
      return (
        <div className="UploadModal__details-footer">
          <FormattedMessage id="uploadModal.fileProcessed" />
        </div>
      );
    }
    if (details.failedRowCount > 0) {
      return (
        <div className="UploadModal__details-footer">
          <Button
            styleVariant="superprimary"
            onClick={() => onDownloadReports()}
          >
            <FormattedMessage id="uploadModal.downloadReportButton" />
          </Button>
        </div>
      );
    }
  };

  return (
    <Modal
      isOpen={props.isOpen}
      onAfterOpen={onAfterOpen}
      onRequestClose={onClose}
      classes={(current: ModalStyles) => ({
        ...current,
        root: `${current.root} UploadModal`,
      })}
    >
      <ModalHeader
        displayClose
        classes={(current) => ({
          ...current,
          root: `${current.root} UploadModal__header`,
          textWrapper: `${current.root} UploadModal__header-text`,
        })}
      />
      <ModalBody>
        <PendingContent
          loading={loadingUploadDetails}
          isError={Boolean(uploadDetailsError)}
          loader={Spinner}
          hideContent
        >
          {uploadDetails && (
            <div className="UploadModal__details">
              <h1 className="UploadModal__details-heading">
                <FormattedMessage id="uploadModal.uploaded" />{' '}
                {uploadDetails.createdAt}
              </h1>
              <p>
                <FormattedMessage
                  id="uploadModal.totalRows"
                  params={{ count: uploadDetails.rowCount }}
                />
              </p>
              <p className="color-status-success">
                <FormattedMessage
                  id="uploadModal.acceptedRows"
                  params={{ count: uploadDetails.successRowCount }}
                />
              </p>
              <p className={failedRowsClassName}>
                <FormattedMessage
                  id="uploadModal.failedRows"
                  params={{ count: uploadDetails.failedRowCount }}
                />
              </p>
              {renderDetailsFooter(uploadDetails)}
            </div>
          )}
        </PendingContent>
      </ModalBody>
    </Modal>
  );
};

export default UploadDetailsModal;
