import { User } from '../../../state';
import { DependencyContainer } from '../../../DependencyContainer';
import { CreateUserDto } from '../types';

export default class UsersService {
  constructor(private readonly factory: DependencyContainer) {}

  async createUser(
    dto: CreateUserDto,
    companyId: string,
  ): Promise<CreateUserDto> {
    //@ts-ignore
    const response = await this.factory.usersClient.createUser(dto, companyId);
    return response.data;
  }

  async getUsers(companyId: string): Promise<User[]> {
    const response = await this.factory.usersClient.fetchUsers(companyId);
    return response.data.items;
  }

  async resetPassword(
    email: string,
    companyId: string,
  ): Promise<CreateUserDto> {
    //@ts-ignore
    const response = await this.factory.usersClient.resetPasswordUser(
      email,
      companyId,
    );
    return response.data;
  }
  async updateUser(
    dto: CreateUserDto,
    companyId: string,
    email: string,
  ): Promise<CreateUserDto> {
    const response = await this.factory.usersClient.updateUser(
      dto,
      companyId,
      email,
    );
    return response.data;
  }

  async deleteUser(companyId: string, email: string): Promise<void> {
    await this.factory.usersClient.deleteUser(companyId, email);
  }
}
