import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Heading, Icons, notify } from 'plume-ui';
import { Form, useFormikContext } from 'formik';
import TemplateConfigurationSection from '../TemplateConfigurationSection/TemplateConfigurationSection';
import FormattedMessage from '../../../../utils/components/FormattedMessage';
import useFieldsDefinitions from '../../hooks/useFieldsDefinitions';
import { useTranslation } from 'react-i18next';
import { FormFieldDefinition } from '../../../../types';
import { TemplateConfigurationFieldsNames } from '../../form-data-marshaller';
import { Routes } from '../../../../config';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  sectionToggleDirectionAtom,
  selectedCompanySelector,
  templateForOperationsAtom,
  ToggleDirection,
} from '../../../../state';
import { useRedirectToRoute } from '../../../../utils/hooks/useRedirectToRoute';
import { useParams } from 'react-router-dom';
import useWithConfirmation from '../../../../hooks/useWithConfirmation';
import { DependencyContainer } from '../../../../DependencyContainer';
import { MixPanelEvents } from '../../../../mixPanelEvents';
import { useTrackEvent } from '../../../trackingAnalytics/hooks/useTrackEvent';
import { useRole } from '../../../login/hooks/useRole';
import { FlexRole } from '../../../login/types';

export type TemplateConfigurationSectionDefinition = {
  id: string;
  title: string;
  fieldsDefinitions: Array<
    FormFieldDefinition<TemplateConfigurationFieldsNames>
  >;
  isRequired: boolean;
  isOpen: boolean;
};

const { templatesService } = new DependencyContainer();

const TemplateConfigurationForm: FunctionComponent = () => {
  const [templateForOperations, setTemplateForOperations] = useRecoilState(
    templateForOperationsAtom,
  );
  const { id } = useParams();
  const isUpdating = Boolean(id);
  const {
    workNetworkFields,
    radiusAuthenticationFields,
    ipSecPrimaryFields,
    ipSecSecondaryFields,
  } = useFieldsDefinitions(isUpdating);
  const { t } = useTranslation();
  const trackEvent = useTrackEvent();
  const selectedCompany = useRecoilValue(selectedCompanySelector);
  const redirectToRoute = useRedirectToRoute();
  const withConfirmation = useWithConfirmation();
  const { isSubmitting } = useFormikContext();
  const { hasRole } = useRole();
  const isFlexAdmin = hasRole(FlexRole.FlexAdmin);
  const [sectionToggleDirection, setSectionToggleDirection] = useRecoilState(
    sectionToggleDirectionAtom,
  );
  const [availableSections, setAvailableSections] = useState<
    TemplateConfigurationSectionDefinition[]
  >([
    {
      id: 'worknetwork',
      title: t('WorkNetworkConfigurations.title'),
      fieldsDefinitions: workNetworkFields,
      isRequired: true,
      isOpen: false,
    },
    {
      id: 'radius',
      title: t('radiusAuthConfigurations.title'),
      fieldsDefinitions: radiusAuthenticationFields,
      isRequired: true,
      isOpen: false,
    },
    {
      id: 'ipsecprimary',
      title: t('IPsecConfigurations.title'),
      fieldsDefinitions: ipSecPrimaryFields,
      isRequired: true,
      isOpen: false,
    },
    {
      id: 'ipsecsecondary',
      title: t('IPsecSecondaryConfigurations.title'),
      fieldsDefinitions: ipSecSecondaryFields,
      isRequired: false,
      isOpen: false,
    },
  ]);

  const getCancelOrDeleteButtonCopy = (): string => {
    return templateForOperations?.name
      ? 'configurations.cancel'
      : 'configurations.delete.button';
  };

  const onCancelOrDelete = async () => {
    if (templateForOperations?.name) {
      // cancelling
      onCancel();
      return;
    }
    await onDelete();
  };

  const onCancel = () => {
    setTemplateForOperations(undefined);
    redirectToRoute(Routes.Templates);
  };

  const onDelete = async () => {
    if (!selectedCompany || !id) {
      return;
    }
    withConfirmation({
      title: t('templates.deleteTemplateModal.title'),
      body: t('templates.deleteTemplateModal.description'),
      confirmButtonLabel: t('delete'),
      cancelButtonLabel: t('cancel'),
      onConfirm: async () => {
        try {
          await templatesService.deleteTemplate(selectedCompany.id, id);
          setTimeout(() => {
            notify({
              title: t('success'),
              body: t('configurations.templateDeleted'),
              type: 'success',
            });
          }, 500);
          trackEvent({
            eventName: MixPanelEvents.DELETE_COMPANY_TEMPLATE_SUCCESS,
            additionalContent: {
              companyId: selectedCompany.id,
              templateId: id,
            },
          });
          redirectToRoute(Routes.Templates);
        } catch (error) {
          const message = error?.response?.data?.message;
          notify({
            title: t('error'),
            body: message,
            type: 'error',
          });
          trackEvent({
            eventName: MixPanelEvents.DELETE_COMPANY_TEMPLATE_SUCCESS,
            additionalContent: {
              companyId: selectedCompany.id,
              templateId: id,
            },
          });
        }
      },
    });
  };

  const onFormError = (sectionIdentifier: string) => {
    changeToggleDirection();
    setAvailableSections((prev: TemplateConfigurationSectionDefinition[]) => {
      const updatedSections = prev.map((section) => {
        if (section.id === sectionIdentifier) {
          return {
            ...section,
            isOpen: true,
          };
        }
        return section;
      });
      return updatedSections;
    });
  };

  const changeToggleDirection = () => {
    setSectionToggleDirection((prev) => Math.abs(prev - 1));
  };

  const toggleAllSections = (direction: ToggleDirection) => {
    setAvailableSections((prev: TemplateConfigurationSectionDefinition[]) => {
      return prev.map((section) => {
        return {
          ...section,
          isOpen: Boolean(direction === ToggleDirection.Expand),
        };
      });
    });
  };

  useEffect(() => {
    toggleAllSections(sectionToggleDirection);
  }, [sectionToggleDirection]);

  return (
    <>
      <Form>
        <div className="ConfigurationsContainer__header--button">
          <Button
            onClick={() => onCancel()}
            classes={(current) => ({
              ...current,
              root: `${current.root} ConfigurationsContainer__delete--button`,
            })}
            type="button"
            styleVariant="action"
          >
            <FormattedMessage id="cancel" />
          </Button>
          <Button
            disabled={isSubmitting || !isFlexAdmin}
            styleVariant="superprimary"
            type="submit"
          >
            <FormattedMessage id="configurations.save" />
          </Button>
        </div>
      </Form>

      <div className="ConfigurationsContainer__headerContainer">
        <Heading level={1} ocrey>
          <FormattedMessage id="configurations.title" />
        </Heading>
        <div
          className="ConfigurationsContainer__headerContainer--collapseIcon"
          onClick={() => changeToggleDirection()}
        >
          {sectionToggleDirection === ToggleDirection.Collapse ? (
            <Icons.ExpandAllIcon />
          ) : (
            <Icons.CollapseAllIcon />
          )}
        </div>
      </div>
      <Form>
        <div className="ConfigurationsContainer__cards">
          {availableSections.map((section) => (
            <TemplateConfigurationSection
              key={section.id}
              sectionIdentifier={section.id}
              fieldsDefinitions={section.fieldsDefinitions}
              sectionTitle={section.title}
              isRequired={section.isRequired}
              isOpen={section.isOpen}
              onFormError={onFormError}
            />
          ))}
        </div>
        <div className="ConfigurationsContainer__footer--button">
          <Button
            onClick={() => onCancelOrDelete()}
            disabled={isSubmitting || !isFlexAdmin}
            classes={(current) => ({
              ...current,
              root: `${current.root} ConfigurationsContainer__delete--button`,
            })}
            type="button"
            styleVariant="action"
          >
            <FormattedMessage id={getCancelOrDeleteButtonCopy()} />
          </Button>
          <Button
            disabled={isSubmitting || !isFlexAdmin}
            styleVariant="superprimary"
            type="submit"
          >
            <FormattedMessage id="configurations.save" />
          </Button>
        </div>
      </Form>
    </>
  );
};
export default TemplateConfigurationForm;
