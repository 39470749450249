import React, { ChangeEvent, useState } from 'react';
import { AdjustableComponent, useClasses } from '../../utils/hooks/useClasses';
import { InputField } from 'plume-ui';
import { FieldInputProps, FormikProps } from 'formik';
import { InputFieldStyles } from 'plume-ui/dist/components/InputField/InputField';
import { Icons } from 'plume-ui';
import cx from 'classnames';

type FormikInputSearchFieldProps = {
  field: FieldInputProps<string>;
  form: FormikProps<string>;
  disabled?: boolean;
  noClearIcon?: boolean;
  rounded?: boolean;
  label?: string;
} & JSX.IntrinsicElements['input'];

export type SearchInputFieldProps = {
  root: string;
};

const FormikInputSearchField: AdjustableComponent<
  FormikInputSearchFieldProps,
  SearchInputFieldProps
> = ({
  field,
  form,
  disabled,
  label,
  classes,
  placeholder,
  noClearIcon = false,
  rounded = true,
  ...props
}) => {
  const [focus, setFocus] = useState(false);
  const styles = useClasses(
    {
      root: cx('FormikSearchInputField', {
        [`FormikSearchInputField--focus`]: Boolean(focus),
        [`FormikSearchInputField--rounded`]: Boolean(rounded),
      }),
    },
    classes,
  );

  const onInput = async (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    form.setFieldValue(field.name, value);
    if (value === undefined) {
      await form.submitForm();
    }
    props.onInput && props.onInput(e);
  };

  return (
    <InputField
      name={field.name}
      label={label}
      value={field.value}
      noClearIcon={noClearIcon}
      disabled={disabled}
      {...props}
      classes={(current: InputFieldStyles) => ({
        ...current,
        root: `${current.root} ${styles.root}`,
      })}
      icon={<Icons.SearchIcon />}
      placeholder={placeholder}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
      onInput={onInput}
    />
  );
};

export default FormikInputSearchField;
