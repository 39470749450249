import { FormFieldDefinition } from '../../types';
import FormattedMessage from '../../utils/components/FormattedMessage';
import { Button, Icons } from 'plume-ui';
import { ErrorMessage, Field, FieldArray, useFormikContext } from 'formik';
import FormikInputField from '../../components/FormikInputField/FormikInputField';
import React from 'react';
import {
  AdjustableComponentType,
  useClasses,
} from '../../utils/hooks/useClasses';
import { InputFieldStyles } from 'plume-ui/dist/components/InputField/InputField';
import { prepareAllMessagesForInput } from '../../utils/prepareErrorMessageForInput';
import FormikDHCPOptionsPicker from './components/FormikDHCPOptionsPicker/FormikDHCPOptionsPicker';
import FormikInputToggle from '../../components/FormikInputToggle/FormikInputToggle';
import { useTranslation } from 'react-i18next';
import { getObjectElement } from '../../helpers/objects';
import DropdownInput from '../../components/DropdownInput/DropdownInput';
import FormikTextToggler from '../../components/FormikTextToggler/FormikTextToggler';
import FormikInputCheckbox from '../../components/FormikInputCheckbox/FormikInputCheckbox';
import FormikInputCheckboxGroup from '../../components/FormikInputCheckboxGroup/FormikInputCheckboxGroup';

export type FormFieldRenderProps<T extends string> = {
  fieldDefinition: FormFieldDefinition<T>;
  labelsAsPlaceholders?: boolean;
  placeholder?: string;
  readonly?: boolean;
};

export type FormFieldRenderStyles = {
  root: string;
  wrapper: string;
  key: string;
  heading: string;
  value: string;
  field: string;
  options: string;
  optionIcon: string;
  optionButton: string;
  optionAddButton: string;
  message: string;
  messages: string;
};

const addStylesForInputField = (newClassName: string) => (
  current: InputFieldStyles,
) => ({
  ...current,
  root: `${current.root} ${newClassName}`,
});

export default function FormFieldRenderer<T extends string>({
  fieldDefinition,
  labelsAsPlaceholders = false,
  classes,
  readonly = false,
}: AdjustableComponentType<FormFieldRenderProps<T>, FormFieldRenderStyles>) {
  const { t } = useTranslation();
  const { values, errors } = useFormikContext();
  const styles = useClasses(
    {
      root: `FormFieldRenderer__row`,
      wrapper: `FormFieldRenderer__wrapper`,
      heading: `FormFieldRenderer__heading`,
      field: `FormFieldRenderer__field`,
      key: `FormFieldRenderer__key`,
      value: `FormFieldRenderer__value`,
      options: `FormFieldRenderer__options`,
      optionIcon: `FormFieldRenderer__option-icon`,
      optionButton: `FormFieldRenderer__option-button`,
      optionAddButton: `FormFieldRenderer__option-add-button`,
      messages: `FormFieldRenderer__message FormFieldRenderer__messages`,
      message: `FormFieldRenderer__message FormFieldRenderer__message--error`,
    },
    classes,
  );

  if (!fieldDefinition.isVisible) {
    return null;
  }

  if (fieldDefinition.type === 'spacer') {
    return <div className={styles.root} />;
  }
  const value = getObjectElement(fieldDefinition.name, values) || '';

  const renderErrors = (messages: string | string[]) => {
    const errorsArray = Array.isArray(messages)
      ? errors[fieldDefinition.name]
      : typeof errors[fieldDefinition.name] === 'string'
      ? [errors[fieldDefinition.name]]
      : [null];
    const errorsForField = [...new Set(errorsArray as string[])];
    if (!errorsForField) {
      return null;
    }

    return (
      <div className={styles.messages}>
        {errorsForField.map((error: string, index: number) => (
          <span
            key={`${fieldDefinition.name}-${index}`}
            className={styles.message}
          >
            {error}
          </span>
        ))}
      </div>
    );
  };
  const requiredFieldMessage =
    labelsAsPlaceholders && fieldDefinition.required ? (
      <>
        <span className="color-status-error">*</span>{' '}
        <FormattedMessage id="requiredField" />
      </>
    ) : (
      <>&nbsp;</>
    );
  const messages = prepareAllMessagesForInput(
    fieldDefinition.name,
    requiredFieldMessage,
    errors,
  );
  const fieldsCommonProps = {
    classes: addStylesForInputField(styles.field),
    placeholder: t(fieldDefinition.placeholder || ''),
    label:
      labelsAsPlaceholders && fieldDefinition.type !== 'text-toggler'
        ? t(fieldDefinition.labelId)
        : undefined,
    name: fieldDefinition.name,
    messages,
    value,
  };

  return (
    <div className={styles.root}>
      {fieldDefinition?.textBefore && (
        <h3 className={styles.heading}>
          <FormattedMessage id={fieldDefinition.textBefore} />
        </h3>
      )}
      <div className={styles.wrapper}>
        {!labelsAsPlaceholders && fieldDefinition.type !== 'checkbox' && (
          <div className={styles.key}>
            {fieldDefinition.type !== 'text-toggler' && (
              <>
                <FormattedMessage id={fieldDefinition.labelId} />
                {fieldDefinition.required && (
                  <span className="color-status-error">&nbsp;*</span>
                )}
              </>
            )}
          </div>
        )}
        <div className={styles.value}>
          {['text', 'password'].includes(fieldDefinition.type) && (
            <Field
              component={FormikInputField}
              type={fieldDefinition.type}
              {...fieldsCommonProps}
              readOnly={readonly}
            />
          )}
          {fieldDefinition.type === 'checkbox' && (
            <Field
              component={FormikInputCheckbox}
              {...fieldsCommonProps}
              label={t(fieldDefinition.labelId)}
            />
          )}
          {fieldDefinition.type === 'checkbox-group' && (
            <Field
              component={FormikInputCheckboxGroup}
              {...fieldsCommonProps}
              label={t(fieldDefinition.labelId)}
              values={fieldDefinition.values}
            />
          )}
          {fieldDefinition.type === 'select' && (
            <Field
              component={DropdownInput}
              defaultLabel={fieldDefinition.placeholder}
              onSelect={fieldDefinition.onSelect}
              options={fieldDefinition.options?.map(({ labelId, value }) => ({
                value,
                label: t(labelId),
              }))}
              readonly={fieldDefinition.isReadOnly}
              {...fieldsCommonProps}
            />
          )}
          {fieldDefinition.type === 'dhcp-options-picker' && (
            <Field component={FormikDHCPOptionsPicker} {...fieldsCommonProps} />
          )}
          {fieldDefinition.type === 'toggler' && (
            <Field
              component={FormikInputToggle}
              disabled={fieldDefinition.disabled}
              values={fieldDefinition.options?.map(({ labelId, value }) => ({
                value,
                label: t(labelId),
              }))}
              {...fieldsCommonProps}
            />
          )}
          {fieldDefinition.type === 'text-toggler' && (
            <Field
              component={FormikTextToggler}
              labelSelected={fieldDefinition.labelSelected}
              labelNotSelected={fieldDefinition.labelNotSelected}
              {...fieldsCommonProps}
            />
          )}
          {fieldDefinition.type === 'text-group' && (
            <FieldArray
              name={fieldDefinition.name}
              render={(fieldsOfField) => (
                <div>
                  {values[fieldDefinition.name]?.map(
                    (v: string, index: number) => (
                      <div className={styles.options} key={index}>
                        <Field
                          name={`${fieldDefinition.name}[${index}]`}
                          value={v}
                          classes={addStylesForInputField(styles.field)}
                          component={FormikInputField}
                          noClearIcon
                          placeholder={fieldDefinition.placeholder}
                        />
                        {values[fieldDefinition.name] &&
                          values[fieldDefinition.name].length >= 1 && (
                            <div className={styles.optionIcon}>
                              <Button
                                styleVariant="small-pill"
                                onClick={() => fieldsOfField.remove(index)}
                                classes={(current) => ({
                                  ...current,
                                  root: `${current.root} ${styles.optionButton}`,
                                })}
                              >
                                <Icons.TrashIcon width={14} />
                              </Button>
                            </div>
                          )}
                      </div>
                    ),
                  )}
                  <ErrorMessage
                    name={fieldDefinition.name}
                    render={renderErrors}
                  />
                  <Button
                    type="button"
                    classes={(current) => ({
                      ...current,
                      root: `${current.root} ${styles.optionAddButton}`,
                    })}
                    onClick={() => fieldsOfField.push('')}
                  >
                    <FormattedMessage id="add" />
                  </Button>
                </div>
              )}
            />
          )}
        </div>
      </div>
    </div>
  );
}
