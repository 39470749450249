import { atom, selector } from 'recoil';
import { UserClaims } from '@okta/okta-auth-js';
import { getAtom } from './persistent-state/persistent-state';
import { Company } from './features/companies/types';
import { FlexRole } from './features/login/types';
import { Maybe, Nullable } from './types';
import { StatisticsUptimeResponseItem } from './features/status/types';

export const activeAccountAtom = atom<Maybe<UserClaims>>({
  key: 'activeAccountAtom',
  default: undefined,
});

export const companiesAtom = atom<Company[]>({
  key: 'companiesAtom',
  default: [],
});

export const companyAtom = atom<Maybe<Company>>({
  key: 'companyAtom',
  default: undefined,
});

export type CompanyTable = {
  id: string;
  legalName: string;
  businessName: string;
  taxIdentificationNumber: string;
  country: string;
};

export type Template = {
  id: string;
  name: string;
  individualPolicies: number;
};

export type Introspect = {
  role: string;
  partnerId?: string;
  accessibleLeafPartnerIds?: string[];
  corporationId?: string; // flexAdmin role only
};

export const introspectAtom = atom<Maybe<Introspect>>({
  key: 'introspectAtom',
  default: undefined,
});

export type Partner = {
  id: string;
  name: string;
};

export const partnersAtom = atom<Partner[]>({
  key: 'partnersAtom',
  default: [],
});

export const selectedPartnerSelector = selector<Maybe<Partner>>({
  key: 'selectedPartnerSelector',
  get: ({ get }) => {
    const partners = get(partnersAtom);
    const selectedPartnerId = get(getAtom('selectedPartnerId'));
    return partners.find((p) => p.id === selectedPartnerId);
  },
});

export type User = {
  profile?: {
    email: string;
    firstName: string;
    lastName: string;
    role: string;
  };
  status?: string;
};

export const usersAtom = atom<User[]>({
  key: 'usersAtom',
  default: [],
});

export type UpdateUser = {
  profile: {
    email: string;
    firstName: string;
    lastName: string;
    role: string;
  };
};
export type UserTable = {
  email: string;
  name: string;
  role: string;
};
export type DeleteUser = {
  email: string;
};

export const selectedUserToEditAtom = atom<Nullable<UpdateUser>>({
  key: 'selectedUserToEditAtom',
  default: null,
});

export const selectedUserSelector = selector<Nullable<User>>({
  key: 'selectedUserSelector',
  get: ({ get }) => {
    const selectedUser = get(selectedUserToEditAtom);
    if (!selectedUser) {
      return null;
    }
    const usersList = get(usersAtom);
    return (
      usersList.find(
        (user) => user.profile?.email === selectedUser.profile.email,
      ) || null
    );
  },
});

export const selectedCompanySelector = selector<Maybe<Company>>({
  key: 'selectedCompanySelector',
  get: ({ get }) => {
    const allCompanies = get(companiesAtom);
    const introspect = get(introspectAtom);
    if (introspect?.role === FlexRole.FlexAdmin && introspect.corporationId) {
      // return a fake Company object
      return {
        id: introspect.corporationId,
        partnerId: '',
        legalName: '',
        businessName: '',
        taxIdentificationNumber: '',
        legalAddress: '',
        country: '',
        primaryEmail: '',
        primaryPhone: '',
        website: '',
        oktaProvisioningComplete: true,
      };
    }
    const selectedCompanyId = get(getAtom('selectedCompanyId'));

    if (!selectedCompanyId || !allCompanies?.length) {
      return;
    }
    return allCompanies.find(
      (company: Company) => company.id === selectedCompanyId,
    );
  },
});

export type TemplateForOperations = {
  id?: string;
  name: string;
};

export const templateForOperationsAtom = atom<Maybe<TemplateForOperations>>({
  key: 'templateForOperationsAtom',
  default: undefined,
});

export type CompanyForOperations = {
  companyId: string | null;
};

export const companyForOperationsAtom = atom<Maybe<CompanyForOperations>>({
  key: 'companyForOperationsAtom',
  default: undefined,
});

export type ConfirmationModalAtom = {
  isOpen: boolean;
  title?: string;
  body?: string;
  confirmButtonLabel?: string;
  cancelButtonLabel?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
};

export const confirmationModalAtom = atom<ConfirmationModalAtom>({
  key: 'confirmationModalAtom',
  default: {
    isOpen: false,
  },
});

export enum ToggleDirection {
  Collapse = 0,
  Expand = 1,
}

export const sectionToggleDirectionAtom = atom<ToggleDirection>({
  key: 'sectionToggleDirectionAtom',
  default: ToggleDirection.Collapse,
});

export type TimeLine = {
  label: string;
  value: number;
};
export const timelineAtom = atom<Maybe<TimeLine>>({
  key: 'timelineAtom',
  default: { label: 'Calendar month', value: 3 },
});
export const statisticsUptimeAtom = atom<Maybe<StatisticsUptimeResponseItem>>({
  key: 'statisticsUptimeAtom',
  default: undefined,
});
