import {
  StatisticsUptimeResponseItem,
  IpsecItem,
  RadiusItem,
  ImpactedEmployeesItem,
} from '../types';
import { AxiosResponse } from 'axios';
import { FlexApiUrls } from '../../../urls';
import { FlexApiStatResponse, FlexApiSingleResponse } from '../../../types';
import { HttpClient } from '../../../httpClient';
import {
  EmployeeStatusItem,
  FetchStatType,
  StatPeriod,
  EmployeeOnboardingItem,
} from '../types';

export default class StatusClient extends HttpClient {
  async fetchEmployeeStatusData(
    companyId: string,
    period: StatPeriod,
    type: FetchStatType,
  ): Promise<AxiosResponse<FlexApiStatResponse<EmployeeStatusItem>>> {
    const response = await this.get<
      AxiosResponse<FlexApiStatResponse<EmployeeStatusItem>>
    >(FlexApiUrls.getEmployeeStatusUrl(companyId, period, type));
    return response;
  }

  async fetchEmployeeOnboardingData(
    companyId: string,
    period: StatPeriod,
    type: FetchStatType,
  ): Promise<AxiosResponse<FlexApiStatResponse<EmployeeOnboardingItem>>> {
    const response = await this.get<
      AxiosResponse<FlexApiStatResponse<EmployeeOnboardingItem>>
    >(FlexApiUrls.getEmployeeOnboardingUrl(companyId, period, type));
    return response;
  }

  async fetchStatUptime(
    companyId: string,
  ): Promise<
    AxiosResponse<FlexApiSingleResponse<StatisticsUptimeResponseItem>>
  > {
    const response = await this.get<
      AxiosResponse<FlexApiSingleResponse<StatisticsUptimeResponseItem>>
    >(FlexApiUrls.getStatUptime(companyId));
    return response;
  }

  async fetchIpsecData(
    companyId: string,
    period: StatPeriod,
    type: FetchStatType,
  ): Promise<AxiosResponse<FlexApiStatResponse<IpsecItem>>> {
    const response = await this.get<
      AxiosResponse<FlexApiStatResponse<IpsecItem>>
    >(FlexApiUrls.getIpsecUrl(companyId, period, type));
    return response;
  }

  async fetchRadiusData(
    companyId: string,
    period: StatPeriod,
    type: FetchStatType,
  ): Promise<AxiosResponse<FlexApiStatResponse<RadiusItem>>> {
    const response = await this.get<
      AxiosResponse<FlexApiStatResponse<RadiusItem>>
    >(FlexApiUrls.getRadiusUrl(companyId, period, type));
    return response;
  }

  async fetchImpactedEmployeesData(
    companyId: string,
    period: StatPeriod,
    type: FetchStatType,
  ): Promise<AxiosResponse<FlexApiStatResponse<ImpactedEmployeesItem>>> {
    const response = await this.get<
      AxiosResponse<FlexApiStatResponse<ImpactedEmployeesItem>>
    >(FlexApiUrls.getImpactedEmployeesUrl(companyId, period, type));
    return response;
  }
}
