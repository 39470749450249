import React, { FunctionComponent } from 'react';
import './root.scss';
import { Switch, Route, useLocation, Redirect } from 'react-router-dom';
import { LoginCallback, SecureRoute, Security } from '@okta/okta-react';
import { LOGIN_SCREEN_ROUTE, Routes } from './config';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { LoginUrls } from './urls';
import LoginContainer from './features/login/containers/LoginContainer';
import ForAuthenticated from './ForAuthenticated';
import { useRedirectToRoute } from './utils/hooks/useRedirectToRoute';
import { DependencyContainer } from './DependencyContainer';
import LoginCallbackError from './features/login/components/LoginCallbackError';
import AppLayout from './layouts/AppLayout';
import TemplatesContainer from './features/templates/containers/TemplatesContainer';
import UsersContainer from './features/users/containers/UsersContainer';
import IndividualPoliciesContainer from './features/individualPolicies/containers/IndividualPoliciesContainer';
import ConfigurationContainer from './features/configurations/containers/configurationContainer';
import CompaniesContainer from './features/companies/containers/CompaniesContainer';
import StatusContainer from './features/status/containers/StatusContainer';
import { getOktaIssuer, getOktaClientId } from './environment';
import ForRole from './features/login/components/ForRole';
import { FlexRole } from './features/login/types';
import CustomerSupportContainer from './features/customerSupport/containers/CustomerSupportContainer';

const redirectUri = LoginUrls.getRedirectUriForOkta();
const oktaAuth = new OktaAuth({
  issuer: getOktaIssuer(),
  clientId: getOktaClientId(),
  redirectUri,
  postLogoutRedirectUri: redirectUri,
  scopes: ['openid', 'email', 'plume'],
  responseType: 'id_token',
  tokenManager: {
    autoRenew: true,
  },
});
const { loginService } = new DependencyContainer();
const restoreOriginalUri = async (oktaAuth: OktaAuth, originalUri: string) => {
  const targetRoute = loginService.getTargetRoute();
  if (targetRoute) {
    window.location.href = targetRoute;
    loginService.removeTargetRoute();
    return;
  }
  const url = originalUri
    ? toRelativeUrl(originalUri, window.location.origin)
    : Routes.Index;
  window.location.href = url;
};

const AppContainer: FunctionComponent = () => {
  const { pathname } = useLocation();
  const redirectToRoute = useRedirectToRoute();
  const onAuthRequired = () => {
    if (pathname === LOGIN_SCREEN_ROUTE) {
      return;
    }
    loginService.setTargetRoute(pathname);
    redirectToRoute(LOGIN_SCREEN_ROUTE);
  };

  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={onAuthRequired}
      restoreOriginalUri={restoreOriginalUri}
    >
      <div className="AppContainer">
        <Switch>
          <Route path={Routes.Login} component={LoginContainer} />
          <Route path={Routes.LoginCallback}>
            <LoginCallback errorComponent={LoginCallbackError} />
          </Route>
          <SecureRoute exact path={Routes.Configurations}>
            <ForAuthenticated>
              <AppLayout>
                <ConfigurationContainer />
              </AppLayout>
            </ForAuthenticated>
          </SecureRoute>
          <SecureRoute exact path={Routes.CreateTemplate}>
            <ForAuthenticated>
              <AppLayout>
                <ConfigurationContainer />
              </AppLayout>
            </ForAuthenticated>
          </SecureRoute>
          <SecureRoute exact path={Routes.Template}>
            <ForAuthenticated>
              <AppLayout>
                <ConfigurationContainer />
              </AppLayout>
            </ForAuthenticated>
          </SecureRoute>
          <SecureRoute exact path={Routes.Templates}>
            <ForAuthenticated>
              <AppLayout>
                <TemplatesContainer />
              </AppLayout>
            </ForAuthenticated>
          </SecureRoute>
          <SecureRoute exact path={Routes.Users}>
            <ForAuthenticated>
              <AppLayout>
                <UsersContainer />
              </AppLayout>
            </ForAuthenticated>
          </SecureRoute>
          <SecureRoute path={Routes.Companies}>
            <ForAuthenticated>
              <ForRole differentThanRole={FlexRole.FlexAdmin}>
                <AppLayout>
                  <CompaniesContainer />
                </AppLayout>
              </ForRole>
              <ForRole role={FlexRole.FlexAdmin}>
                <Route>
                  <Redirect to={Routes.Index} />
                </Route>
              </ForRole>
            </ForAuthenticated>
          </SecureRoute>
          <SecureRoute path={Routes.IndividualPolicies}>
            <ForAuthenticated>
              <AppLayout>
                <IndividualPoliciesContainer />
              </AppLayout>
            </ForAuthenticated>
          </SecureRoute>
          <SecureRoute path={Routes.CustomerSupport}>
            <ForAuthenticated>
              <ForRole role={FlexRole.FlexAdmin}>
                <AppLayout>
                  <CustomerSupportContainer />
                </AppLayout>
              </ForRole>
              <ForRole differentThanRole={FlexRole.FlexAdmin}>
                <Route>
                  <Redirect to={Routes.Index} />
                </Route>
              </ForRole>
            </ForAuthenticated>
          </SecureRoute>
          <SecureRoute path={[Routes.Index, Routes.Status]}>
            <ForAuthenticated>
              <AppLayout>
                <StatusContainer />
              </AppLayout>
            </ForAuthenticated>
          </SecureRoute>
        </Switch>
      </div>
    </Security>
  );
};

export default AppContainer;
