import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Grid, GridItem, Heading } from 'plume-ui';
import FlexHeroStat from '../../../components/FlexHeroStat/FlexHeroStat';
import { useTrackEvent } from '../../trackingAnalytics/hooks/useTrackEvent';
import { MixPanelEvents } from '../../../mixPanelEvents';
import { AvailableScreens } from '../../trackingAnalytics/types';
import { DependencyContainer } from '../../../DependencyContainer';
import { useRecoilValue, useRecoilState } from 'recoil';
import { selectedCompanySelector, statisticsUptimeAtom } from '../../../state';
import { StatisticsUptimeResponseItem } from '../types';
import moment from 'moment';
import EmployeeOnboardingChartContainer from '../components/charts/EmployeeOnboarding/EmployeeOnboardingChartContainer';
import EmployeeStatusChartContainer from '../components/charts/EmployeeStatus/EmployeeStatusChartContainer';
import IpsecTunnelChartContainer from '../components/charts/IpsecTunnel/IpsecTunnelChartContainer';
import ImpactedEmployeesChartContainer from '../components/charts/ImpactedEmployees/ImpactedEmployeesChartContainer';
import RadiusServerChartContainer from '../components/charts/RadiusServer/RadiusServerChartContainer';
import FormattedMessage from '../../../utils/components/FormattedMessage';

const availableCharts = [
  EmployeeOnboardingChartContainer,
  EmployeeStatusChartContainer,
  IpsecTunnelChartContainer,
  RadiusServerChartContainer,
  ImpactedEmployeesChartContainer,
];

type HeroStatItem = {
  heroStatLabel: string;
  stat?: number;
  time: string;
  informationText: string;
};

const { statusService } = new DependencyContainer();
const StatusContainer: FunctionComponent = () => {
  const [statisticsUptime, setStatisticsUptime] = useRecoilState(
    statisticsUptimeAtom,
  );
  const { t } = useTranslation();
  const selectedCompany = useRecoilValue(selectedCompanySelector);
  const trackEvent = useTrackEvent();
  const [statTimeStamp, setStatTimeStamp] = useState<string>();

  useEffect(() => {
    trackEvent({
      eventName: MixPanelEvents.SCREEN,
      additionalContent: {
        SCREEN: AvailableScreens.Status,
      },
    });
  }, []);

  useEffect(() => {
    getStatisticsUptime();
  }, [selectedCompany]);

  const getStatisticsUptime = () => {
    if (!selectedCompany) {
      return;
    }
    statusService
      .getStatisticsUptime(selectedCompany?.id)
      .then((statusUptime: StatisticsUptimeResponseItem) => {
        setStatisticsUptime(statusUptime);
        const time = moment(statusUptime.data?.end_time).format('LT');
        setStatTimeStamp(time);
      })
      .catch((error: any) => console.error('error getting statistics uptime'));
  };
  const HeroStat: HeroStatItem[] = [
    {
      heroStatLabel: t('FlexHeroStat.ipsec.title'),
      stat: statisticsUptime?.data?.ipsec_percent
        ? statisticsUptime?.data?.ipsec_percent * 100
        : undefined,
      time: `${statTimeStamp}`,
      informationText: t('FlexHeroStat.ipsec.info'),
    },
    {
      heroStatLabel: t('FlexHeroStat.radius.title'),
      stat: statisticsUptime?.data?.radius_percent
        ? statisticsUptime?.data?.radius_percent * 100
        : undefined,
      time: `${statTimeStamp}`,
      informationText: t('FlexHeroStat.radius.info'),
    },

    {
      heroStatLabel: t('FlexHeroStat.flex.title'),
      stat: statisticsUptime?.data?.offline_percent
        ? statisticsUptime?.data?.offline_percent * 100
        : undefined,
      time: `${statTimeStamp}`,
      informationText: t('FlexHeroStat.flex.info'),
    },
  ];

  return (
    <>
      <Helmet>
        <title> {t('status.title')} </title>
      </Helmet>
      <div className="StatusContainer p-xl">
        <Grid>
          <GridItem colSpan="12" tabletColSpan="6">
            <div className="StatusContainer__headerContainer">
              <Heading level={1} ocrey>
                <FormattedMessage id="status.title" />
              </Heading>
            </div>
          </GridItem>
          {HeroStat?.map((item: HeroStatItem) => (
            <GridItem colSpan="4" key={item.heroStatLabel}>
              <FlexHeroStat
                id={`HeroStat--${item.heroStatLabel}`}
                key={item.heroStatLabel}
                statLabel={item.heroStatLabel}
                stat={item.stat || 0}
                statUnit="%"
                informationText={item.informationText}
                updatedTime={item.time}
              />
            </GridItem>
          ))}

          {availableCharts.map((ChartComponent) => (
            <GridItem colSpan="6" tabletColSpan="3">
              <ChartComponent key={ChartComponent.name} />
            </GridItem>
          ))}
        </Grid>
      </div>
    </>
  );
};
export default StatusContainer;
