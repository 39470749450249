import { DependencyContainer } from '../../../DependencyContainer';
import { CreateCustomerSupportDto } from '../types';
import { Company } from '../../companies/types';

export default class CustomerSupportService {
  constructor(private readonly factory: DependencyContainer) {}

  async createCustomerSupportDetail(
    dto: CreateCustomerSupportDto,
    companyId: string,
  ): Promise<CreateCustomerSupportDto> {
    //@ts-ignore
    const response = await this.factory.customerSupportClient.createCustomerSupportDetail(
      dto,
      companyId,
    );
    return response.data;
  }

  async getCustomerSupportDetail(
    companyId: string,
  ): Promise<CreateCustomerSupportDto> {
    const response = await this.factory.customerSupportClient.fetchCustomerSupportDetails(
      companyId,
    );
    return response.data;
  }

  async getCompanyDetailsFlexAdminOnly(companyId: string): Promise<Company> {
    const response = await this.factory.customerSupportClient.fetchCompanyDetails(
      companyId,
    );
    return response.data;
  }
}
