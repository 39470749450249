import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Modal, ModalBody, ModalHeader, notify, Spinner } from 'plume-ui';
import { Accept, useDropzone } from 'react-dropzone';
import cx from 'classnames';
import { DependencyContainer } from '../../DependencyContainer';
import { useRecoilValue } from 'recoil';
import { selectedCompanySelector } from '../../state';
import { useFetchData } from '../../hooks/useFetchData';
import { BulkUploadsItem } from '../../features/individualPolicies/types';
import { useTranslation } from 'react-i18next';
import { ModalStyles } from 'plume-ui/dist/components/Modal/Modal';
import { UploadIcon } from '../Icons';
import UploadModalRow from './UploadModalRow';
import UploadDetailsModal from './UploadDetailsModal';
import FormattedMessage from '../../utils/components/FormattedMessage';
import { AxiosError } from 'axios';

export type UploadModalProps = {
  isOpen: boolean;
  onRequestClose: () => void;
  allowedImageTypes?: Accept;
};

const { individualPoliciesService } = new DependencyContainer();

let fetchInterval: any;
const fetchIntervalInMilliseconds = 2000;

const UploadModal: FunctionComponent<UploadModalProps> = (props) => {
  const { t } = useTranslation();
  const selectedCompany = useRecoilValue(selectedCompanySelector);
  const [uploadComplete, setUploadComplete] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [selectedUploadId, setSelectedUploadId] = useState<string>();
  const allowedImagesTypes = props.allowedImageTypes || {
    'text/plain': ['.csv'],
  };
  const inputRef = useRef<HTMLInputElement>(null);

  const [, recentUploads, , runFetchRecentUploads] = useFetchData<
    BulkUploadsItem[],
    any
  >(
    async () => {
      if (!selectedCompany) {
        return;
      }
      return await individualPoliciesService.getUploads(selectedCompany.id);
    },
    undefined,
    [selectedCompany],
    () => selectedCompany === undefined,
  );

  useEffect(() => {
    if (!selectedCompany) {
      return;
    }

    return () => {
      clearInterval(fetchInterval);
    };
  }, [selectedCompany]);

  useEffect(() => {
    if (!uploadComplete) {
      return;
    }
    runFetchRecentUploads();
  }, [uploadComplete]);

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (!selectedCompany) {
        return;
      }
      const formData = new FormData();
      const [file] = acceptedFiles;
      formData.append('file', file);
      setIsUploading(true);
      try {
        await individualPoliciesService.uploadFile(selectedCompany.id, file);
        setUploadComplete(true);
        notify({
          title: t('success'),
          body: 'File has been uploaded',
          type: 'success',
        });
      } catch (error) {
        const mappedError = error as AxiosError;
        switch (mappedError.response?.status) {
          case 403:
            notify({
              title: t('error'),
              body: t('onlyFlexAdmin'),
              type: 'error',
            });
            break;
          case 429:
            notify({
              title: t('error'),
              body: t('tooManyRequests'),
              type: 'error',
            });
            break;
          default:
            notify({
              title: t('error'),
              body: t('somethingWentWrong'),
              type: 'error',
            });
        }
      } finally {
        setIsUploading(false);
      }
    },
    [selectedCompany],
  );

  const dropzone = useDropzone({
    accept: allowedImagesTypes,
    minSize: 1,
    preventDropOnDocument: true,
    noClick: false,
    onDrop,
  });
  const onClose = () => {
    clearInterval(fetchInterval);
    props.onRequestClose();
  };

  const onAfterOpen = async () => {
    if (!selectedCompany) {
      return;
    }
    await runFetchRecentUploads();
    fetchInterval = setInterval(async () => {
      await runFetchRecentUploads();
    }, fetchIntervalInMilliseconds);
  };

  const onDropAreaClick = () => {
    inputRef.current!.click();
  };

  const dropAreaClassNames = cx('UploadModal__drop-area dropzone', {
    'UploadModal__drop-area--accepted': dropzone.isDragAccept,
    'UploadModal__drop-area--active': dropzone.isDragActive,
  });

  return (
    <>
      <Modal
        isOpen={props.isOpen}
        onAfterOpen={onAfterOpen}
        onRequestClose={onClose}
        classes={(current: ModalStyles) => ({
          ...current,
          root: `${current.root} UploadModal`,
        })}
      >
        <ModalHeader
          displayClose
          classes={(current) => ({
            ...current,
            root: `${current.root} UploadModal__header`,
            textWrapper: `${current.root} UploadModal__header-text`,
          })}
        >
          <div className="UploadModal__csv-template">
            <FormattedMessage id="uploadModal.testCSVTemplateLeft" />

            <a href="/CSVTemplate.csv" download>
              <FormattedMessage id="uploadModal.testCSVTemplateButton" />
            </a>

            <FormattedMessage id="uploadModal.testCSVTemplateRight" />
          </div>
        </ModalHeader>
        <ModalBody>
          <div
            {...dropzone.getRootProps()}
            className={dropAreaClassNames}
            onClick={() => onDropAreaClick()}
          >
            <input {...dropzone.getInputProps()} ref={inputRef} />
            {isUploading ? (
              <>
                <Spinner visible />
                <p>
                  <FormattedMessage id="uploadModal.uploading" />
                </p>
              </>
            ) : (
              <>
                <UploadIcon />
                <p>
                  <FormattedMessage id="uploadModal.dragAndDrop" />
                </p>
              </>
            )}
          </div>

          {isUploading && (
            <p className="UploadModal__info">
              <small>
                <FormattedMessage id="uploadModal.disabledInfo" />
              </small>
            </p>
          )}

          <h3 className="UploadModal__recent-uploads-title">Recent uploads</h3>
          {(!recentUploads || recentUploads.length === 0) && (
            <p>
              <FormattedMessage id="uploadModal.noUploads" />
            </p>
          )}
          <div className="UploadModal__recent-uploads">
            {recentUploads?.map((item) => (
              <UploadModalRow
                key={item.id}
                item={item}
                onItemDetailsClick={(item: BulkUploadsItem) => {
                  setSelectedUploadId(item.id);
                }}
              />
            ))}
          </div>
        </ModalBody>
      </Modal>
      <UploadDetailsModal
        uploadId={selectedUploadId}
        isOpen={Boolean(selectedUploadId)}
        onRequestClose={() => setSelectedUploadId(undefined)}
      />
    </>
  );
};

export default UploadModal;
