import { AxiosResponse } from 'axios';
import { FlexApiUrls } from '../../../urls';
import {
  DeepPartial,
  FlexApiResponse,
  FlexApiSingleResponse,
} from '../../../types';
import { HttpClient } from '../../../httpClient';
import { Template } from '../../../state';
import { TemplateWithoutId, UpdateTemplateDto } from '../types';
import { DEFAULT_LIMIT_OF_RECORDS } from '../../../config';

export default class TemplatesClient extends HttpClient {
  async fetchTemplates(
    companyId: string,
    page: number,
    limit: number = DEFAULT_LIMIT_OF_RECORDS,
    phrase?: string,
  ): Promise<AxiosResponse<FlexApiResponse<Template[]>>> {
    const response = await this.get<AxiosResponse<FlexApiResponse<Template[]>>>(
      FlexApiUrls.getPaginatedTemplatesUrl(companyId, page, limit, phrase),
    );
    return response;
  }

  async fetchTemplate(
    id: string,
    companyId: string,
  ): Promise<AxiosResponse<FlexApiSingleResponse<TemplateWithoutId>>> {
    const response = await this.get<
      AxiosResponse<FlexApiSingleResponse<TemplateWithoutId>>
    >(FlexApiUrls.getTemplateUrl(id, companyId));
    return response;
  }

  async updateTemplate(
    id: string,
    companyId: string,
    updateTemplateDto: DeepPartial<UpdateTemplateDto>,
  ): Promise<AxiosResponse<FlexApiResponse<any>>> {
    const response = await this.put<AxiosResponse<FlexApiResponse<any>>>(
      FlexApiUrls.getTemplateUrl(id, companyId),
      updateTemplateDto,
    );
    return response;
  }

  async createTemplate(
    companyId: string,
    updateTemplateDto: DeepPartial<UpdateTemplateDto>,
  ): Promise<AxiosResponse<FlexApiResponse<any>>> {
    const response = await this.post<AxiosResponse<FlexApiResponse<any>>>(
      FlexApiUrls.getTemplatesUrl(companyId),
      updateTemplateDto,
    );
    return response;
  }

  async deleteTemplate(
    companyId: string,
    templateId: string,
  ): Promise<AxiosResponse<FlexApiResponse<any>>> {
    const response = await this.delete<AxiosResponse<FlexApiResponse<any>>>(
      FlexApiUrls.getTemplateUrl(templateId, companyId),
    );
    return response;
  }
}
