import React, { FunctionComponent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dropdown,
  DropdownItem,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Icons,
  notify,
  Pagination,
  PendingContent,
  Spinner,
  Tooltip,
} from 'plume-ui';
import { useRedirectToRoute } from '../../../utils/hooks/useRedirectToRoute';
import {
  DEFAULT_LIMIT_OF_RECORDS,
  Routes,
  templateNameMaxCharacters,
} from '../../../config';
import { useFetchData } from '../../../hooks/useFetchData';
import {
  selectedCompanySelector,
  Template,
  TemplateForOperations,
  templateForOperationsAtom,
} from '../../../state';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { DependencyContainer } from '../../../DependencyContainer';
import FormattedMessage from '../../../utils/components/FormattedMessage';
import CreateTemplateModal from '../components/CreateTemplateModal';
import { FlexApiResponse } from '../../../types';
import useWithConfirmation from '../../../hooks/useWithConfirmation';
import { useTrackEvent } from '../../trackingAnalytics/hooks/useTrackEvent';
import { MixPanelEvents } from '../../../mixPanelEvents';
import { AvailableScreens } from '../../trackingAnalytics/types';
import { Field, Form, Formik } from 'formik';
import FormikInputSearchField from '../../../components/FormikInputSearchField/FormikInputSearchField';
import { makeTextExcerpt } from '../../../helpers/text';
import { useRole } from '../../login/hooks/useRole';
import { FlexRole } from '../../login/types';
import ForRole from '../../login/components/ForRole';
import useCopyTemplateId from '../../../hooks/useCopyTemplateId';

const { templatesService } = new DependencyContainer();
const TemplatesContainer: FunctionComponent = () => {
  const { t } = useTranslation();
  const [createTemplateModalOpen, setCreateTemplateModalOpen] = useState(false);
  const [duplicateTemplateModalOpen, setDuplicateTemplateModalOpen] = useState(
    false,
  );
  const redirectToRoute = useRedirectToRoute();
  const [page, setPage] = useState(1);
  const selectedCompany = useRecoilValue(selectedCompanySelector);
  const withConfirmation = useWithConfirmation();
  const setTemplateForOperations = useSetRecoilState(templateForOperationsAtom);
  const trackEvent = useTrackEvent();
  const [phrase, setPhrase] = useState();
  const { hasRole, runForRole } = useRole();
  const isFlexAdmin = hasRole(FlexRole.FlexAdmin);
  const onCopyTemplateId = useCopyTemplateId<any>();

  useEffect(() => {
    trackEvent({
      eventName: MixPanelEvents.SCREEN,
      additionalContent: {
        SCREEN: AvailableScreens.TemplatePolicyList,
      },
    });
  }, []);
  const [
    loading,
    templatesData,
    error,
    refetch,
    isFetchComplete,
  ] = useFetchData<FlexApiResponse<Template[]>, any>(
    async () => {
      if (!selectedCompany) {
        return;
      }
      return await templatesService.getTemplates(
        selectedCompany.id,
        page,
        DEFAULT_LIMIT_OF_RECORDS,
        phrase,
      );
    },
    undefined,
    [selectedCompany, page, phrase],
    () => selectedCompany === undefined,
  );

  const onConfigure = (template: Template) => {
    setTemplateForOperations(undefined);
    redirectToRoute(Routes.Template, {
      id: template.id,
    });
  };

  const onCloseCreateTemplateModal = () => {
    setCreateTemplateModalOpen(false);
  };

  const onCloseDuplicateTemplateModal = () => {
    setDuplicateTemplateModalOpen(false);
  };

  const onCreateTemplate = (templateForOperations: TemplateForOperations) => {
    redirectToRoute(Routes.CreateTemplate);
  };

  const onDeleteTemplate = async (id: string) => {
    if (!selectedCompany || !id) {
      return;
    }
    withConfirmation({
      title: t('templates.deleteTemplateModal.title'),
      body: t('templates.deleteTemplateModal.description'),
      confirmButtonLabel: t('delete'),
      cancelButtonLabel: t('cancel'),
      onConfirm: async () => {
        runForRole(FlexRole.FlexAdmin, async () => {
          try {
            await templatesService.deleteTemplate(selectedCompany.id, id);
            notify({
              title: t('success'),
              body: t('configurations.templateDeleted'),
              type: 'success',
            });
            await refetch();
            handleLastPagePagination();
          } catch (error) {
            const message = error?.response?.data?.message;
            notify({
              title: t('error'),
              body: message,
              type: 'error',
            });
          }
        });
      },
    });
  };

  const handleLastPagePagination = () => {
    if (templatesData?.items.length === 1) {
      setPage(templatesData.meta.currentPage - 1);
    }
  };
  const onDuplicateActionClick = (template: Template) => {
    runForRole(FlexRole.FlexAdmin, () => {
      setTemplateForOperations({
        id: template.id,
        name: `${template.name} copy`,
      });
      setDuplicateTemplateModalOpen(true);
    });
  };

  const onDuplicateTemplate = async (
    templateForOperations: TemplateForOperations,
  ) => {
    if (
      !templateForOperations ||
      !selectedCompany ||
      !templateForOperations.id
    ) {
      return;
    }
    runForRole(FlexRole.FlexAdmin, async () => {
      try {
        await templatesService.duplicateTemplate(
          selectedCompany.id,
          templateForOperations?.id || '',
          templateForOperations.name,
        );
        notify({
          title: t('success'),
          body: t('templates.duplicateTemplateModal.templateDuplicated'),
          type: 'success',
        });
        await refetch();
        setDuplicateTemplateModalOpen(false);
        setTemplateForOperations(undefined);
      } catch (error) {
        notify({
          title: t('error'),
          body: t('somethingWentWrong'),
          type: 'error',
        });
      }
    });
  };
  const onSearch = (values: any) => {
    setPage(1);
    setPhrase(values.phrase);
  };

  const onCopyActionClick = async (template: Template) => {
    if (!template) return;
    try {
      await navigator.clipboard.writeText(template.id);
      notify({
        title: t('success'),
        body: t('templateIdCopied'),
        type: 'success',
      });
    } catch (error) {}
  };

  const handleCurrentPage = () => {
    if (!templatesData?.meta.currentPage) {
      return;
    }

    if (templatesData?.meta.currentPage < 1) {
      throw new Error('Page must be greater than zero');
    }
    return (templatesData?.meta.currentPage || 1) - 1;
  };
  return (
    <>
      <Helmet>
        <title>{t('templates.title')}</title>
      </Helmet>
      <div className="TemplatesContainer p-xl">
        <Grid>
          <GridItem colSpan="12" tabletColSpan="6">
            <div className="TemplatesContainer__headerContainer">
              <Heading level={1} ocrey>
                <FormattedMessage id="templates.title" />
              </Heading>
            </div>
            <div className="TemplatesContainer__search">
              <Formik initialValues={{ phrase: '' }} onSubmit={onSearch}>
                {({ submitForm, values }) => (
                  <Form>
                    <Field
                      component={FormikInputSearchField}
                      name="phrase"
                      value={values.phrase}
                      onInput={() => setTimeout(submitForm, 300)}
                      placeholder={t('templates.searchPlaceholder')}
                    />
                  </Form>
                )}
              </Formik>
              <Button
                disabled={!isFlexAdmin}
                styleVariant="superprimary"
                onClick={() => setCreateTemplateModalOpen(true)}
              >
                <FormattedMessage id="templates.addTemplate" />
              </Button>
            </div>
          </GridItem>
        </Grid>
        <div className="TemplatesContainer__main-content">
          <PendingContent
            loading={loading}
            loader={Spinner}
            isError={error}
            transparent
          >
            {isFetchComplete && (
              <>
                {!templatesData ||
                  (templatesData?.items?.length === 0 && (
                    <Grid>
                      <GridItem colSpan="12">
                        <p className="TemplatesContainer__no-data">
                          <FormattedMessage id="templates.noTemplatesFound" />
                        </p>
                      </GridItem>
                    </Grid>
                  ))}
                {templatesData && (
                  <Grid>
                    {templatesData?.items?.map((template: Template) => (
                      <GridItem
                        className="TemplatesContainer__template"
                        colSpan="4"
                        key={template.id}
                      >
                        <div className="TemplatesContainer__block-wrapper">
                          <div className="TemplatesContainer__block-main-content">
                            <div
                              className="TemplatesContainer__block-name"
                              title={template.name}
                            >
                              {makeTextExcerpt(
                                template.name,
                                templateNameMaxCharacters,
                              )}
                              <Tooltip
                                label={t('templates.dropdown.copyTemplateId')}
                                position={'below'}
                                alignment={'center'}
                              >
                                <Icons.CopyIcon
                                  className="TemplatesContainer__block-icon"
                                  width={23}
                                  height={21}
                                  onClick={() => onCopyTemplateId(template)}
                                />
                              </Tooltip>
                            </div>
                            <div className="TemplatesContainer__block-total">
                              <span className="TemplatesContainer__block-total-number">
                                {template.individualPolicies}
                              </span>{' '}
                              <FormattedMessage id="templates.individualPolicies" />
                            </div>
                          </div>

                          <Dropdown
                            classes={(current) => ({
                              ...current,
                              root: `${current.root} TemplatesContainer__block-dropdown 22222`,
                            })}
                            listPosition="right"
                            closeOnItemClick
                            button={
                              <IconButton>
                                <Icons.DotsVerticalIcon />
                              </IconButton>
                            }
                          >
                            <DropdownItem onClick={() => onConfigure(template)}>
                              <FormattedMessage
                                id={
                                  isFlexAdmin
                                    ? 'templates.dropdown.configure'
                                    : 'templates.dropdown.view'
                                }
                              />
                            </DropdownItem>
                            <ForRole role={FlexRole.FlexAdmin}>
                              <DropdownItem
                                onClick={() => onDuplicateActionClick(template)}
                              >
                                <FormattedMessage id="templates.dropdown.duplicate" />
                              </DropdownItem>

                              {template.individualPolicies === 0 && (
                                <DropdownItem
                                  onClick={() => onDeleteTemplate(template.id)}
                                >
                                  <FormattedMessage id="templates.dropdown.delete" />
                                </DropdownItem>
                              )}
                            </ForRole>
                            <DropdownItem
                              onClick={() => onCopyActionClick(template)}
                            >
                              <FormattedMessage id="templates.dropdown.copyTemplateId" />
                            </DropdownItem>
                          </Dropdown>
                        </div>
                      </GridItem>
                    ))}
                  </Grid>
                )}
              </>
            )}
          </PendingContent>
        </div>
        {(!templatesData?.meta || templatesData.meta.totalPages > 1) && (
          <Pagination
            expandDirection="top"
            classes={(current) => ({
              ...current,
              root: `${current.root} TemplatesContainer__pagination`,
            })}
            totalPageCount={templatesData?.meta?.totalPages || 1}
            onPageSelect={(page: number) => setPage(page + 1)}
            currentPage={handleCurrentPage()}
          />
        )}

        <CreateTemplateModal
          isOpen={createTemplateModalOpen}
          onSubmit={onCreateTemplate}
          onRequestClose={() => onCloseCreateTemplateModal()}
        />
        <CreateTemplateModal
          isOpen={duplicateTemplateModalOpen}
          onSubmit={onDuplicateTemplate}
          onRequestClose={() => onCloseDuplicateTemplateModal()}
        />
      </div>
    </>
  );
};
export default TemplatesContainer;
