import { DependencyContainer } from '../../../DependencyContainer';
import {
  CreateCompanyResponse,
  CreateCompanyDto,
  Company,
  UpdateCompanyDto,
} from '../types';
import { DEFAULT_LIMIT_OF_RECORDS } from '../../../config';
import { FlexApiResponse, Maybe } from '../../../types';

export default class CompanyService {
  constructor(private readonly factory: DependencyContainer) {}

  async getCompanies(
    partnerId: string,
    page: number = 1,
    limit = DEFAULT_LIMIT_OF_RECORDS,
  ): Promise<FlexApiResponse<Company[]>> {
    if (page < 1) {
      throw new Error('Page must be greater than zero');
    }
    const response = await this.factory.companyClient.fetchCompanies(
      partnerId,
      page,
      limit,
    );
    return response.data;
  }

  async getCompany(partnerId: string, companyId: string): Promise<Company> {
    const response = await this.factory.companyClient.fetchCompany(
      partnerId,
      companyId,
    );
    return response.data;
  }

  async createCompany(
    dto: CreateCompanyDto,
    partnerId: string,
  ): Promise<CreateCompanyResponse> {
    //@ts-ignore
    const response = await this.factory.companyClient.createCompany(
      dto,
      partnerId,
    );
    return response.data;
  }

  async updateCompany(
    partnerId: string,
    companyId: string,
    updateCompanyDto: UpdateCompanyDto,
  ): Promise<Company> {
    const response = await this.factory.companyClient.updateCompany(
      partnerId,
      companyId,
      updateCompanyDto,
    );
    return response.data.items;
  }

  async deleteCompany(
    partnerId: Maybe<string>,
    companyId: string,
  ): Promise<void> {
    await this.factory.companyClient.deleteCompany(partnerId, companyId);
  }
}
