import React from 'react';
import { AdjustableComponent } from 'plume-ui/dist/hooks/useClasses';
import { useClasses } from '../../utils/hooks/useClasses';
import { Tooltip, IconButton, Icons } from 'plume-ui';
import FormattedMessage from '../../utils/components/FormattedMessage';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

type FlexHeroStatPossibleProps = {
  statLabel: string;
  stat: number;
  statUnit?: string;
  informationText?: string;
  updatedTime: string;
};
export type FlexHeroStatProps = FlexHeroStatPossibleProps &
  JSX.IntrinsicElements['div'];
export type FlexHeroStatStyles = {
  root: string;
  infoContainer: string;
  labelContainer: string;
  statLabel: string;
  time: string;
  icon: string;
};
const FlexHeroStat: AdjustableComponent<
  FlexHeroStatProps,
  FlexHeroStatStyles
> = ({
  statLabel,
  stat,
  statUnit,
  informationText,
  updatedTime,
  classes,
  ...props
}) => {
  const { t } = useTranslation();
  const styles = useClasses(
    {
      root: 'FlexHeroStat',
      infoContainer: 'FlexHeroStat__infoContainer',
      labelContainer: 'FlexHeroStat__labelContainer',
      statLabel: 'FlexHeroStat__label',
      time: 'FlexHeroStat__time',
      icon: 'FlexHeroStat__icon',
    },
    classes,
  );
  const statClassNames = cx('FlexHeroStat__stat', {
    'FlexHeroStat__stat--offline':
      statLabel === `${t('FlexHeroStat.flex.title')}`,
    'FlexHeroStat__stat--empty': !stat,
  });
  const prepareStat = () => {
    if (stat) {
      const decimalFormat = stat.toFixed(2);
      const commaSeparated = parseFloat(decimalFormat).toLocaleString('en-US');

      return statUnit ? `${commaSeparated} ${statUnit}` : commaSeparated;
    }

    return `0`;
  };
  return (
    <div className={styles.root} {...props}>
      <div className={styles.infoContainer}>
        <div className={styles.labelContainer}>
          <div className={styles.statLabel}> {statLabel}</div>
          {informationText && (
            <Tooltip
              heading={statLabel}
              label={informationText!}
              position={'above'}
              alignment={'center'}
              overrideMaxCharacters
            >
              <IconButton small={true}>
                <Icons.InfoIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>

        <div className={statClassNames}>{prepareStat()}</div>
        <div className={styles.time}>
          <FormattedMessage id="FlexHeroStat.updatedTime" />
          {updatedTime}
        </div>
      </div>
    </div>
  );
};
export default FlexHeroStat;
