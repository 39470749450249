import { AxiosResponse } from 'axios';
import { FlexApiResponse } from '../../types';

export const createApiAxiosResponse = (
  data?: any,
  override?: Partial<AxiosResponse>,
): AxiosResponse => ({
  data,
  status: 200,
  statusText: 'ok',
  headers: {},
  config: {},
  ...override,
});

export function createFlexApiResponse<T>(
  items?: T,
  override?: Partial<FlexApiResponse<T>>,
): FlexApiResponse<T> {
  const length =
    items === undefined ? 0 : Array.isArray(items) ? items.length : 1;
  const meta = {
    currentPage: 1,
    itemCount: length,
    itemsPerPage: length,
    totalItems: length,
    totalPages: 1,
  };
  if (items === undefined) {
    return {
      items: ([] as unknown) as T,
      meta,
    };
  }
  return {
    items,
    meta,
    ...override,
  };
}
