import React, { ChangeEvent } from 'react';
import { AdjustableComponent } from '../../utils/hooks/useClasses';
import { InputField } from 'plume-ui';
import { FieldInputProps, FormikProps } from 'formik';
import { InputFieldStyles } from 'plume-ui/dist/components/InputField/InputField';

type FormikInputFieldMessage = {
  status: 'error' | 'success' | 'hint';
  message: string;
};

type FormikInputFieldProps = {
  messages: FormikInputFieldMessage[];
  field: FieldInputProps<string>;
  form: FormikProps<string>;
  disabled?: boolean;
  noClearIcon?: boolean;
  label?: string;
} & JSX.IntrinsicElements['input'];

const FormikInputField: AdjustableComponent<
  FormikInputFieldProps,
  InputFieldStyles
> = ({
  messages,
  field,
  form,
  disabled,
  label,
  noClearIcon = false,
  ...props
}) => {
  const onInput = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    form.setFieldValue(field.name, value);
  };

  return (
    <InputField
      messages={messages}
      name={field.name}
      label={label}
      value={field.value}
      noClearIcon={noClearIcon}
      disabled={disabled}
      {...props}
      onInput={onInput}
    />
  );
};

export default FormikInputField;
