import React, { FunctionComponent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dropdown,
  DropdownItem,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Icons,
  notify,
  Pagination,
  PendingContent,
  Spinner,
  Status,
  Table,
} from 'plume-ui';
import { useRedirectToRoute } from '../../../utils/hooks/useRedirectToRoute';
import { Routes, DEFAULT_LIMIT_OF_RECORDS } from '../../../config';
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import { selectedCompanySelector } from '../../../state';
import { useFetchData } from '../../../hooks/useFetchData';
import { DependencyContainer } from '../../../DependencyContainer';
import { FlexApiResponse } from '../../../types';
import {
  IndividualPoliciesTableRecordItem,
  IndividualPolicyResponseItem,
  IndividualPolicyStatus,
} from '../types';
import { createFlexApiResponse } from '../../../utils/fixtures/Api.fixture';
import FormattedMessage from '../../../utils/components/FormattedMessage';
import usePolicyStatusMappings from '../hooks/usePolicyStatusMappings';
import useWithConfirmation from '../../../hooks/useWithConfirmation';
import CreateIndividualPolicyModal from '../components/CreateIndividualPolicyModal';
import { MixPanelEvents } from '../../../mixPanelEvents';
import { AvailableScreens } from '../../trackingAnalytics/types';
import { useTrackEvent } from '../../trackingAnalytics/hooks/useTrackEvent';
import { Field, Form, Formik } from 'formik';
import { getFrontlineUrl } from '../../../environment';
import { FrontlineUrls } from '../../../urls';
import FormikInputSearchField from '../../../components/FormikInputSearchField/FormikInputSearchField';
import UploadModal from '../../../components/UploadModal/UploadModal';
import { useRole } from '../../login/hooks/useRole';
import { FlexRole } from '../../login/types';
import ForRole from '../../login/components/ForRole';
import {
  individualPolicyAtom,
  individualPolicyForOperationsAtom,
  policySortAtom,
  policySelectedPageAtom,
  policiesTableSelector,
  policiesSearchAtom,
  currentPoliciesPage,
  filteredPoliciesSelector,
} from '../individualPoliciesState';

const { individualPoliciesService } = new DependencyContainer();

const IndividualPoliciesContainer: FunctionComponent = () => {
  const { t } = useTranslation();
  const redirectToRoute = useRedirectToRoute();
  const withConfirmation = useWithConfirmation();
  const [open, setOpen] = useState(false);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const { hasRole, runForRole } = useRole();
  const selectedCompany = useRecoilValue(selectedCompanySelector);
  const { renderStatusColor, renderStatus } = usePolicyStatusMappings();
  const setIndividualPolicyForOperations = useSetRecoilState(
    individualPolicyForOperationsAtom,
  );
  const setPolicy = useSetRecoilState(individualPolicyAtom);
  const setPolicySort = useSetRecoilState(policySortAtom);
  const trackEvent = useTrackEvent();
  const query = new URLSearchParams(window.location.search).get('query');
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [selectedPage, setSelectedPage] = useRecoilState(
    policySelectedPageAtom,
  );
  const policiesInStore = useRecoilValue(policiesTableSelector);
  const setPoliciesSearch = useSetRecoilState(policiesSearchAtom);
  const [totalItems, setTotalItems] = useState();
  const policies = useRecoilValue(currentPoliciesPage);
  const filteredPolicies = useRecoilValue(filteredPoliciesSelector);

  useEffect(() => {
    if (policiesInStore?.length) {
      setTotalItems(policiesInStore.length);
    }
  }, [policiesInStore?.length]);

  useEffect(() => {
    trackEvent({
      eventName: MixPanelEvents.SCREEN,
      additionalContent: {
        SCREEN: AvailableScreens.IndividualPolicyList,
      },
    });
    if (query) {
      setPoliciesSearch(query);
    }
  }, []);

  useEffect(() => {
    if (filteredPolicies.length !== totalItems) {
      setSelectedPage(0);
      setTotalItems(filteredPolicies.length);
    }
  }, [filteredPolicies]);

  const [
    loading,
    individualPolicies,
    error,
    refetchPolicies,
    isFetchComplete,
  ] = useFetchData<FlexApiResponse<IndividualPolicyResponseItem[]>, any>(
    async () => {
      if (!selectedCompany) {
        return createFlexApiResponse([]);
      }
      return await individualPoliciesService.getIndividualPolicies(
        selectedCompany.id,
        1,
      );
    },
    (policy) => setPolicy(policy.items),
    [selectedCompany],
    () => selectedCompany === undefined,
  );

  const onEdit = (policy: IndividualPoliciesTableRecordItem) => {
    runForRole(FlexRole.FlexAdmin, () => {
      setIsReadOnly(false);
      setIndividualPolicyForOperations({
        id: policy.id,
        templateId: policy.templateId,
      });
      setOpen(true);
    });
  };

  const onView = (policy: IndividualPoliciesTableRecordItem) => {
    setIsReadOnly(true);
    setIndividualPolicyForOperations({
      id: policy.id,
      templateId: policy.templateId,
    });
    setOpen(true);
  };

  const onDelete = (policy: IndividualPoliciesTableRecordItem) => {
    withConfirmation({
      onConfirm: async () => {
        if (!selectedCompany) {
          return;
        }
        runForRole(FlexRole.FlexAdmin, async () => {
          try {
            await individualPoliciesService.deleteIndividualPolicy(
              selectedCompany.id,
              policy.templateId,
              policy.id,
            );
            await refetchPolicies();
            redirectToRoute(Routes.IndividualPolicies);
            notify({
              title: t('success'),
              body: t('individualPolicies.policyDeletedMessage'),
              type: 'success',
            });
            trackEvent({
              eventName: MixPanelEvents.DELETE_INDIVIDUAL_POLICY_SUCCESS,
              additionalContent: {
                companyId: selectedCompany.id,
                templateId: policy.templateId,
                policyId: policy.id,
              },
            });
          } catch (error) {
            notify({
              title: t('error'),
              body: t('somethingWentWrong'),
              type: 'error',
            });
            trackEvent({
              eventName: MixPanelEvents.DELETE_INDIVIDUAL_POLICY_FAILURE,
              additionalContent: {
                companyId: selectedCompany.id,
                templateId: policy.templateId,
                policyId: policy.id,
              },
            });
          } finally {
          }
        });
      },
    });
  };

  const onGoToTemplate = (policy: IndividualPoliciesTableRecordItem) => {
    redirectToRoute(Routes.Template, {
      id: policy.templateId,
    });
  };

  const onFrontlineAction = (policy: IndividualPoliciesTableRecordItem) => {
    window.open(
      getFrontlineUrl() +
        FrontlineUrls.getFrontlineLocationUrl(policy.locationId),
      '_blank',
    );
  };

  const headerRow = [
    {
      name: t('individualPolicies.table.employeeId'),
      fieldName: 'employeeId',
      sortable: true,
    },
    {
      name: t('individualPolicies.table.externalId'),
      fieldName: 'externalId',
      sortable: true,
    },
    {
      name: t('individualPolicies.table.template'),
      fieldName: 'template',
      sortable: true,
    },
    {
      name: t('individualPolicies.table.status'),
      fieldName: 'status',
      render: (record: IndividualPoliciesTableRecordItem) => (
        <Status
          color={renderStatusColor(record)}
          label={renderStatus(record)}
        />
      ),
      sortable: true,
    },
    {
      render: (policy: IndividualPoliciesTableRecordItem) => (
        <Dropdown
          listPosition="right"
          closeOnItemClick
          button={
            <IconButton>
              <Icons.DotsVerticalIcon />
            </IconButton>
          }
        >
          <DropdownItem onClick={() => onGoToTemplate(policy)}>
            <FormattedMessage id="individualPolicies.dropdown.goToTemplate" />
          </DropdownItem>
          <ForRole differentThanRole={FlexRole.FlexAdmin}>
            <DropdownItem onClick={() => onView(policy)}>
              <FormattedMessage id="view" />
            </DropdownItem>
          </ForRole>
          <ForRole role={FlexRole.FlexAdmin}>
            <DropdownItem onClick={() => onEdit(policy)}>
              <FormattedMessage id="edit" />
            </DropdownItem>
            <DropdownItem onClick={() => onDelete(policy)}>
              <FormattedMessage id="delete" />
            </DropdownItem>
          </ForRole>
          {policy?.status === IndividualPolicyStatus.Active && (
            <DropdownItem onClick={() => onFrontlineAction(policy)}>
              <FormattedMessage id="individualPolicies.dropdown.viewInFrontline" />
            </DropdownItem>
          )}
        </Dropdown>
      ),
    },
  ];

  const onSearch = (values: any) => {
    setPoliciesSearch(values.phrase);
    setSelectedPage(0);
  };

  const totalPageCount = Math.ceil(
    totalItems ? totalItems / DEFAULT_LIMIT_OF_RECORDS : 1,
  );

  return (
    <>
      <Helmet>
        <title>{t('individualPolicies.title')}</title>
      </Helmet>
      <div className="IndividualPoliciesContainer p-xl">
        <Grid>
          <GridItem colSpan="12" tabletColSpan="6">
            <div className="IndividualPoliciesContainer__headerContainer">
              <Heading level={1} ocrey>
                <FormattedMessage id="individualPolicies.title" />
              </Heading>
            </div>
            <div className="IndividualPoliciesContainer__search">
              <Formik
                initialValues={{ phrase: query || '' }}
                onSubmit={onSearch}
                enableReinitialize
              >
                {({ submitForm, values }) => (
                  <Form>
                    <Field
                      component={FormikInputSearchField}
                      name="phrase"
                      value={values.phrase}
                      onInput={() => setTimeout(submitForm, 300)}
                      placeholder={t('individualPolicies.searchPlaceholder')}
                    />
                  </Form>
                )}
              </Formik>
              <div className="IndividualPoliciesContainer__actionButtonContainer">
                <Button
                  styleVariant="action"
                  disabled={!hasRole(FlexRole.FlexAdmin)}
                  onClick={() => setUploadModalOpen(true)}
                >
                  <FormattedMessage id="individualPolicies.upload" />
                </Button>
                <Button
                  styleVariant="superprimary"
                  disabled={
                    selectedCompany === undefined ||
                    !hasRole(FlexRole.FlexAdmin)
                  }
                  onClick={() => setOpen(true)}
                >
                  <FormattedMessage id="individualPolicies.createPolicyButton" />
                </Button>
              </div>
            </div>
          </GridItem>
          <GridItem colSpan="12">
            <PendingContent
              loading={loading}
              loader={Spinner}
              isError={error}
              transparent
            >
              {isFetchComplete && (
                <>
                  {(!individualPolicies?.items ||
                    individualPolicies?.items?.length) === 0 ? (
                    <p className="IndividualPoliciesContainer__none-found">
                      <FormattedMessage id="individualPolicies.noPoliciesFound" />
                    </p>
                  ) : (
                    <div className="IndividualPoliciesContainer__main-content">
                      <Table
                        headerRow={headerRow as any}
                        externalSort
                        dataRows={[...(policies as any)]}
                        truncateCellContent={false}
                        onSortChange={setPolicySort}
                      />
                    </div>
                  )}
                </>
              )}
            </PendingContent>
          </GridItem>
          <GridItem colSpan="12">
            {totalPageCount > 1 && (
              <div className="IndividualPoliciesContainer__pagination">
                <Pagination
                  expandDirection="top"
                  totalPageCount={totalPageCount}
                  onPageSelect={setSelectedPage}
                  currentPage={selectedPage}
                />
              </div>
            )}
            {(filteredPolicies.length === 0 || totalItems === 0) && (
              <p className="IndividualPoliciesContainer__none-found">
                <FormattedMessage id="individualPolicies.noPoliciesFound" />
              </p>
            )}
          </GridItem>
        </Grid>
        <CreateIndividualPolicyModal
          isOpen={open}
          onFinish={refetchPolicies}
          onRequestClose={() => setOpen(false)}
          readonly={isReadOnly}
        />
        {/* @TODO: Return different mime types for csv depending on operation system as on windows csv has a different mime type */}
        <UploadModal
          isOpen={uploadModalOpen}
          onRequestClose={() => setUploadModalOpen(false)}
          allowedImageTypes={{ 'text/plain': ['.csv'] }}
        />
      </div>
    </>
  );
};
export default IndividualPoliciesContainer;
