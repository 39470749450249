import React, { FunctionComponent, useState } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  notify,
  PendingContent,
  Spinner,
  Icons,
} from 'plume-ui';
import FormattedMessage from '../../../utils/components/FormattedMessage';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikProps, FormikValues } from 'formik';
import { DependencyContainer } from '../../../DependencyContainer';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { selectedCompanySelector } from '../../../state';
import { CreateIndividualPolicyDto, IndividualPolicyStatus } from '../types';
import FormFieldRenderer from '../../configurations/FormFieldRenderer';
import useCreateIndividualPolicyFields from '../hooks/useCreateIndividualPolicyFields';
import yup from '../../../utils/yupInstance';
import { useTrackEvent } from '../../trackingAnalytics/hooks/useTrackEvent';
import { MixPanelEvents } from '../../../mixPanelEvents';
import { TunnelTypes } from '../../configurations/types';
import {
  mapTunnelTypeToSubnetMax,
  maxSubnetPrefixValue,
} from '../../../config';
import { RequiredStringSchema } from 'yup/lib/string';
import { ModalStyles } from 'plume-ui/dist/components/Modal/Modal';
import { ModalBodyStyles } from 'plume-ui/dist/components/Modal/ModalBody';
import classNames from 'classnames';
import { useRole } from '../../login/hooks/useRole';
import { FlexRole } from '../../login/types';
import {
  createindividualPolicyAtom,
  individualPolicyForOperationsAtom,
} from '../individualPoliciesState';
import { getFieldKey } from '../../../helpers/fields';

export type CreateIndividualPolicyModalProps = {
  isOpen: boolean;
  onRequestClose: () => void;
  onFinish?: () => void;
  readonly?: boolean;
};

export enum IndividualPolicyFormFields {
  TemplateId = 'template_id',
  EmployeeId = 'employee_id',
  ExternalId = 'external_id',
  Ipv4 = 'ipv4',
  IpsecPSKPrimary = 'ipsec_psk_primary',
  IpsecPSKSecondary = 'ipsec_psk_secondary',
  LocalIdPrimary = 'local_id_primary',
  LocalTunnelIpPrimary = 'local_tunnel_ip_primary',
  RemoteTunnelIpPrimary = 'remote_tunnel_ip_primary',
  LocalIdSecondary = 'local_id_secondary',
  LocalTunnelIpSecondary = 'local_tunnel_ip_secondary',
  RemoteTunnelIpSecondary = 'remote_tunnel_ip_secondary',
  Suspend = 'suspend',
}

export type IndividualPolicyFormValues = Record<
  IndividualPolicyFormFields,
  any
>;

const { individualPoliciesService } = new DependencyContainer();

const CreateIndividualPolicyModal: FunctionComponent<CreateIndividualPolicyModalProps> = ({
  onFinish,
  onRequestClose,
  isOpen,
  readonly,
}) => {
  const { t } = useTranslation();
  const [message, setMessage] = useState<string>('');
  const [messageStyle, setMessageStyle] = useState<string>('');
  const selectedCompany = useRecoilValue(selectedCompanySelector);
  const {
    fields,
    loading,
    error,
    selectedTemplate,
  } = useCreateIndividualPolicyFields();
  const setIndividualPolicyForOperations = useSetRecoilState(
    individualPolicyForOperationsAtom,
  );
  const [individualPolicy, setIndividualPolicy] = useRecoilState(
    createindividualPolicyAtom,
  );
  const trackEvent = useTrackEvent();
  const onClose = () => {
    setIndividualPolicyForOperations(undefined);
    setIndividualPolicy(undefined);
    onRequestClose();
  };
  const { hasRole } = useRole();
  const isFlexAdmin = hasRole(FlexRole.FlexAdmin);

  const prepareIndividualPolicyDto = (
    values: IndividualPolicyFormValues,
  ): CreateIndividualPolicyDto => {
    return {
      ipSecPSKSecondary: values[IndividualPolicyFormFields.IpsecPSKSecondary],
      ipSecPSKPrimary: values[IndividualPolicyFormFields.IpsecPSKPrimary],
      localIdSecondary: values[IndividualPolicyFormFields.LocalIdSecondary],
      localIdPrimary: values[IndividualPolicyFormFields.LocalIdPrimary],
      localTunnelIpPrimary:
        values[IndividualPolicyFormFields.LocalTunnelIpPrimary],
      localTunnelIpSecondary:
        values[IndividualPolicyFormFields.LocalTunnelIpSecondary],
      remoteTunnelIpPrimary:
        values[IndividualPolicyFormFields.RemoteTunnelIpPrimary],
      remoteTunnelIpSecondary:
        values[IndividualPolicyFormFields.RemoteTunnelIpSecondary],
      ipv4LanSubnet: values[IndividualPolicyFormFields.Ipv4],
      externalId: values[IndividualPolicyFormFields.ExternalId],
      employeeId: values[IndividualPolicyFormFields.EmployeeId],
      enable: !Boolean(values[IndividualPolicyFormFields.Suspend]),
    };
  };
  const getValidationRules = () => {
    const subnetMax: number = selectedTemplate
      ? mapTunnelTypeToSubnetMax[selectedTemplate.vpnMode]
      : maxSubnetPrefixValue;
    const maxSubnetMessage = t('addPolicy.validation.maxSubnetPrefix', {
      subnetMax,
    });
    let shape: Record<string, RequiredStringSchema<any>> = {
      [IndividualPolicyFormFields.TemplateId]: yup
        .string()
        .required(t('addPolicy.validation.templateId.required')),
      [IndividualPolicyFormFields.EmployeeId]: yup
        .string()
        .required(t('addPolicy.validation.employeeId.required')),
      [IndividualPolicyFormFields.ExternalId]: yup
        .string()
        .required(t('addPolicy.validation.externalId.required')),
      [IndividualPolicyFormFields.LocalIdPrimary]: yup
        .string()
        .required(t('addPolicy.validation.localIdPrimary.required')),
      [IndividualPolicyFormFields.LocalTunnelIpPrimary]: yup
        .string()
        .ipWithSubnet(
          t('addPolicy.validation.localTunnelIpPrimary.ipWithSubnet'),
        )
        .maxSubnetPrefix(subnetMax, maxSubnetMessage)
        .optional(),
      [IndividualPolicyFormFields.LocalTunnelIpSecondary]: yup
        .string()
        .ipWithSubnet(
          t('addPolicy.validation.localTunnelIpSecondary.ipWithSubnet'),
        )
        .maxSubnetPrefix(subnetMax, maxSubnetMessage)
        .optional(),
      [IndividualPolicyFormFields.RemoteTunnelIpPrimary]: yup
        .string()
        .ip(t('addPolicy.validation.remoteTunnelIpPrimary.ip'))
        .optional(),
      [IndividualPolicyFormFields.RemoteTunnelIpSecondary]: yup
        .string()
        .ip(t('addPolicy.validation.remoteTunnelIpSecondary.ip'))
        .optional(),
      [IndividualPolicyFormFields.EmployeeId]: yup
        .string()
        .required(t('addPolicy.validation.employeeID.required')),
      [IndividualPolicyFormFields.IpsecPSKPrimary]: yup
        .string()
        .required(t('addPolicy.validation.IpsecPSKPrimary.required')),
      [IndividualPolicyFormFields.Ipv4]: yup
        .string()
        .ipWithSubnet(t('addPolicy.validation.ipv4LanSubnet.ipWithSubnet'))
        .maxSubnetPrefix(subnetMax, maxSubnetMessage)
        .optional(),
    };
    if (selectedTemplate && selectedTemplate.vpnMode === TunnelTypes.IPSEC) {
      shape = {
        ...shape,
        [IndividualPolicyFormFields.Ipv4]: yup
          .string()
          .ipWithSubnet(t('addPolicy.validation.ipv4LanSubnet.ipWithSubnet'))
          .maxSubnetPrefix(subnetMax, maxSubnetMessage)
          .required(t('addPolicy.validation.ipv4LanSubnet.required')),
        [IndividualPolicyFormFields.LocalTunnelIpPrimary]: yup
          .string()
          .ipWithSubnet(
            t('addPolicy.validation.localTunnelIpPrimary.ipWithSubnet'),
          )
          .maxSubnetPrefix(subnetMax, maxSubnetMessage)
          .optional(),
        [IndividualPolicyFormFields.LocalTunnelIpSecondary]: yup
          .string()
          .ipWithSubnet(
            t('addPolicy.validation.localTunnelIpSecondary.ipWithSubnet'),
          )
          .maxSubnetPrefix(subnetMax, maxSubnetMessage)
          .optional(),
      };
    }

    if (
      selectedTemplate &&
      [TunnelTypes.IPSECStatic, TunnelTypes.IPSECDynamic].includes(
        selectedTemplate.vpnMode,
      )
    ) {
      shape = {
        ...shape,
        [IndividualPolicyFormFields.LocalTunnelIpPrimary]: yup
          .string()
          .ipWithSubnet(
            t('addPolicy.validation.localTunnelIpPrimary.ipWithSubnet'),
          )
          .maxSubnetPrefix(subnetMax, maxSubnetMessage)
          .optional(),
        [IndividualPolicyFormFields.LocalTunnelIpSecondary]: yup
          .string()
          .ipWithSubnet(
            t('addPolicy.validation.localTunnelIpSecondary.ipWithSubnet'),
          )
          .maxSubnetPrefix(subnetMax, maxSubnetMessage)
          .optional(),
      };
    }

    const hasSelectedTemplateSecondaryTunnel = Boolean(
      selectedTemplate?.secondaryTunnel,
    );
    if (
      hasSelectedTemplateSecondaryTunnel &&
      selectedTemplate &&
      selectedTemplate.vpnMode === TunnelTypes.IPSEC
    ) {
      shape = {
        ...shape,
        [IndividualPolicyFormFields.LocalTunnelIpSecondary]: yup
          .string()
          .ipWithSubnet(
            t('addPolicy.validation.localTunnelIpSecondary.ipWithSubnet'),
          )
          .maxSubnetPrefix(subnetMax, maxSubnetMessage)
          .optional(),
        [IndividualPolicyFormFields.IpsecPSKSecondary]: yup
          .string()
          .required(t('addPolicy.validation.IpsecPSKSecondary.required')),
      };
    }

    return yup.object().shape(shape);
  };

  const getFormValues = (): IndividualPolicyFormValues => {
    if (individualPolicy) {
      return {
        [IndividualPolicyFormFields.EmployeeId]: individualPolicy.employeeId,
        [IndividualPolicyFormFields.ExternalId]: individualPolicy.externalId,
        [IndividualPolicyFormFields.IpsecPSKPrimary]:
          individualPolicy?.primaryTunnel?.ipSec?.psk || '',
        [IndividualPolicyFormFields.Ipv4]:
          individualPolicy?.network?.subnet || '',
        [IndividualPolicyFormFields.IpsecPSKSecondary]:
          individualPolicy?.secondaryTunnel?.ipSec?.psk || '',
        [IndividualPolicyFormFields.LocalIdPrimary]:
          individualPolicy?.primaryTunnel?.ipSec?.localEndpointId || '',
        [IndividualPolicyFormFields.LocalTunnelIpPrimary]:
          individualPolicy?.primaryTunnel?.tunnelIp || '',
        [IndividualPolicyFormFields.RemoteTunnelIpPrimary]:
          individualPolicy?.primaryTunnel?.healthCheck?.remoteIp || '',
        [IndividualPolicyFormFields.LocalIdSecondary]:
          individualPolicy?.secondaryTunnel?.ipSec?.localEndpointId || '',
        [IndividualPolicyFormFields.LocalTunnelIpSecondary]:
          individualPolicy?.secondaryTunnel?.tunnelIp || '',
        [IndividualPolicyFormFields.RemoteTunnelIpSecondary]:
          individualPolicy?.secondaryTunnel?.healthCheck?.remoteIp || '',
        [IndividualPolicyFormFields.Suspend]: !individualPolicy.enable,
        [IndividualPolicyFormFields.TemplateId]:
          individualPolicy?.flexPolicy?.id || '',
      };
    }

    return {
      [IndividualPolicyFormFields.EmployeeId]: '',
      [IndividualPolicyFormFields.ExternalId]: '',
      [IndividualPolicyFormFields.IpsecPSKPrimary]: '',
      [IndividualPolicyFormFields.Ipv4]: '',
      [IndividualPolicyFormFields.IpsecPSKSecondary]: '',
      [IndividualPolicyFormFields.LocalIdPrimary]: '',
      [IndividualPolicyFormFields.LocalTunnelIpPrimary]: '',
      [IndividualPolicyFormFields.RemoteTunnelIpPrimary]: '',
      [IndividualPolicyFormFields.LocalIdSecondary]: '',
      [IndividualPolicyFormFields.LocalTunnelIpSecondary]: '',
      [IndividualPolicyFormFields.RemoteTunnelIpSecondary]: '',
      [IndividualPolicyFormFields.Suspend]: false,
      [IndividualPolicyFormFields.TemplateId]: '',
    };
  };

  const onSubmit = async (values: IndividualPolicyFormValues) => {
    if (individualPolicy) {
      await onEdit(values);
    } else {
      await onCreate(values);
    }
  };

  const displayErrorMessage = (error: any) => {
    notify({
      title: t('error'),
      body: error?.response?.data?.message,
      type: 'error',
    });
  };
  const renderStatusMessage = () => {
    if (
      !individualPolicy ||
      (individualPolicy?.enable &&
        individualPolicy?.status !== IndividualPolicyStatus.Pending)
    ) {
      return null;
    }
    if (individualPolicy?.enable === false) {
      setMessage(t('addPolicy.policySuspend'));
      setMessageStyle(t('addPolicy.suspend'));
    } else if (individualPolicy?.status === IndividualPolicyStatus.Pending) {
      setMessage(t('addPolicy.policyPending'));
      setMessageStyle(t('addPolicy.warning'));
    } else {
      setMessage('');
      setMessageStyle('');
    }

    return (
      <div className="IndividualPoliciesContainer__modal--message-wrapper">
        <div
          className={classNames(
            'IndividualPoliciesContainer__modal--message',
            'large',
            {
              'IndividualPoliciesContainer__modal--message-suspend':
                messageStyle === t('addPolicy.suspend'),
              'IndividualPoliciesContainer__modal--message-warning':
                messageStyle === t('addPolicy.warning'),
            },
          )}
        >
          {message && <Icons.InfoIcon width={16} />}
          <div>{message}</div>
        </div>
      </div>
    );
  };

  const onCreate = async (values: IndividualPolicyFormValues) => {
    if (!selectedCompany) {
      return;
    }
    try {
      const dto = prepareIndividualPolicyDto(values);
      await individualPoliciesService.createIndividualPolicy(
        selectedCompany.id,
        values[IndividualPolicyFormFields.TemplateId],
        dto,
        selectedTemplate,
      );
      onFinish?.();
      onClose();
      notify({
        title: t('success'),
        body: t('addPolicy.policyAdded'),
        type: 'success',
      });
      trackEvent({
        eventName: MixPanelEvents.ADD_INDIVIDUAL_POLICY_SUCCESS,
        additionalContent: {
          companyId: selectedCompany.id,
          templateId: values[IndividualPolicyFormFields.TemplateId],
        },
      });
    } catch (error) {
      trackEvent({
        eventName: MixPanelEvents.ADD_INDIVIDUAL_POLICY_FAILURE,
        additionalContent: {
          companyId: selectedCompany.id,
          templateId: values[IndividualPolicyFormFields.TemplateId],
        },
      });
      displayErrorMessage(error);
    }
  };

  const onEdit = async (values: IndividualPolicyFormValues) => {
    if (!selectedCompany || !individualPolicy) {
      return;
    }
    try {
      const dto = prepareIndividualPolicyDto(values);
      await individualPoliciesService.updateIndividualPolicy(
        individualPolicy.id,
        individualPolicy.flexPolicy.id,
        selectedCompany.id,
        dto,
        selectedTemplate,
      );
      onFinish?.();
      onClose();
      notify({
        title: t('success'),
        body: t('policyUpdated'),
        type: 'success',
      });
      trackEvent({
        eventName: MixPanelEvents.EDIT_INDIVIDUAL_POLICY_SUCCESS,
        additionalContent: {
          companyId: selectedCompany.id,
          templateId: individualPolicy.flexPolicy.id,
          policyId: individualPolicy.id,
        },
      });
    } catch (error) {
      trackEvent({
        eventName: MixPanelEvents.EDIT_INDIVIDUAL_POLICY_FAILURE,
        additionalContent: {
          companyId: selectedCompany.id,
          templateId: individualPolicy.flexPolicy.id,
          policyId: individualPolicy.id,
        },
      });
      displayErrorMessage(error);
    }
  };

  const isSubmitDisabled = (formik: FormikProps<FormikValues>) => {
    let countFilledRequiredFields = 0;
    const requiredFields = fields.filter(
      (f) => f.type !== 'spacer' && f.required && f.isVisible,
    );
    for (let field of requiredFields) {
      if (field.type !== 'spacer' && Boolean(formik.values[field.name])) {
        ++countFilledRequiredFields;
      }
    }
    const hasAllRequiredFieldFilled =
      requiredFields.length <= countFilledRequiredFields;
    return (
      !selectedTemplate || !hasAllRequiredFieldFilled || formik.isSubmitting
    );
  };

  return (
    <Modal
      solidBackground
      classes={(current: ModalStyles) => ({
        ...current,
        root: `${current.root} IndividualPoliciesContainer__modal`,
      })}
      isOpen={isOpen}
      frame
      onRequestClose={onClose}
    >
      <PendingContent
        loading={loading}
        isError={Boolean(error)}
        loader={Spinner}
      >
        <Formik
          initialValues={getFormValues()}
          validationSchema={getValidationRules()}
          enableReinitialize
          isInitialValid={Boolean(individualPolicy)}
          validateOnChange={false}
          onSubmit={onSubmit}
        >
          {(formik: FormikProps<FormikValues>) => (
            <Form>
              <ModalHeader
                classes={(current) => ({
                  ...current,
                  root: `${current.root} IndividualPoliciesContainer__modal-header`,
                  title: `${current.title} IndividualPoliciesContainer__header-text`,
                })}
                title={t('addPolicy.title')}
              />
              <ModalBody
                classes={(current: ModalBodyStyles) => ({
                  ...current,
                  root: `${current.root} IndividualPoliciesContainer__modal-body`,
                })}
              >
                {renderStatusMessage()}
                <div className="IndividualPoliciesContainer__modal-body">
                  <div className="IndividualPoliciesContainer__form">
                    {fields.map((field, index) => (
                      <FormFieldRenderer
                        key={getFieldKey(field, index)}
                        fieldDefinition={field}
                        labelsAsPlaceholders={false}
                        classes={(current) => ({
                          ...current,
                          root: `${
                            current.root
                          } IndividualPoliciesContainer__modal-row ${
                            field.type !== 'spacer' ? field.name : 'spacer'
                          }`,
                          value: `${current.value} IndividualPoliciesContainer__modal-field`,
                        })}
                        readonly={readonly}
                      />
                    ))}
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  classes={(current) => ({
                    ...current,
                    root: `${current.root} IndividualPoliciesContainer__cancel-button`,
                  })}
                  onClick={onClose}
                  type="button"
                  styleVariant="tertiary"
                >
                  <FormattedMessage id="cancel" />
                </Button>
                <Button
                  type="submit"
                  styleVariant="superprimary"
                  disabled={isSubmitDisabled(formik) || !isFlexAdmin}
                >
                  <FormattedMessage id="save" />
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </PendingContent>
    </Modal>
  );
};

export default CreateIndividualPolicyModal;
