import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownItem, Heading } from 'plume-ui';
import { FieldInputProps, FormikProps } from 'formik';
import { AdjustableComponent, useClasses } from '../../utils/hooks/useClasses';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

export type DropdownInputOption = {
  value: string;
  label: string;
};

export type DropdownInputError = {
  status: string;
  message: string;
};

export type DropdownInputProps = {
  field: FieldInputProps<string>;
  form: FormikProps<string>;
  options: DropdownInputOption[];
  defaultLabel: string;
  messages?: DropdownInputError[];
  expandDirection?: 'top' | 'bottom';
  openInPortal?: boolean;
  onSelect?: (value: any) => void;
  noItemsWarning?: string;
  readonly?: boolean;
};

export type DropdownInputStyles = {
  root: string;
  dropdown: string;
};

const DropdownInput: AdjustableComponent<
  DropdownInputProps,
  DropdownInputStyles
> = ({
  options,
  field,
  form,
  classes,
  defaultLabel,
  messages,
  noItemsWarning,
  expandDirection = 'bottom',
  readonly = false,
  openInPortal = false,
  onSelect,
}) => {
  const { t } = useTranslation();
  const [currentValue, setCurrentValue] = useState(field.value);
  const onChange = (option: DropdownInputOption) => {
    setCurrentValue(option.value);
    form.setFieldValue(field.name, option.value);
    onSelect?.(option.value);
  };

  useEffect(() => {
    setCurrentValue(field.value);
  }, [field.value]);

  const styles = useClasses(
    {
      root: 'DropdownInput',
      dropdown: 'DropdownInput__dropdown',
    },
    classes,
  );

  const label = currentValue
    ? options.find((option) => option.value === currentValue)?.label
    : defaultLabel;

  return (
    <div
      className={cx('DropdownInput', {
        'DropdownInput--error': messages?.length,
      })}
    >
      {readonly ? (
        <Heading level={2} size="xl">
          {label}
        </Heading>
      ) : (
        <Dropdown
          label={label}
          closeOnItemClick
          listPosition="left"
          noSearchMatchMessage={t('dropdownInput.dropdownInput')}
          searchPlaceholder={t('dropdownInput.searchPlaceholder')}
          searchBar
          expandDirection={expandDirection}
          openInPortal={openInPortal}
          disabled={false}
          classes={(current) => ({
            ...current,
            root: `${current.root} ${styles.dropdown}`,
            list: `${current.list} DropdownInput__list`,
            button: `${current.button} DropdownInput__button`,
            icon: `${current.icon} DropdownInput__icon`,
          })}
          noItemsWarning={noItemsWarning}
        >
          {options.map((option) => {
            return (
              <DropdownItem onClick={() => onChange(option)} key={option.value}>
                {option.label}
              </DropdownItem>
            );
          })}
        </Dropdown>
      )}
      {messages?.map((error: DropdownInputError) => (
        <span key={error.message} className="DropdownInput__message">
          {error.message}
        </span>
      ))}
    </div>
  );
};
export default DropdownInput;
