import axios from 'axios';
import { getBackendUrl } from './environment';

export default axios.create({
  baseURL: getBackendUrl(),
  timeout: 5000,
  headers: { Accept: 'application/json' },
  transitional: {
    silentJSONParsing: false,
    forcedJSONParsing: true,
    clarifyTimeoutError: true,
  },
});
