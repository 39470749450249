import { useTranslation } from 'react-i18next';
import {
  IndividualPoliciesTableRecordItem,
  IndividualPolicyStatus,
} from '../types';

const mapStatusToMessageId = {
  [IndividualPolicyStatus.Active]: 'individualPolicies.status.onboarded',
  [IndividualPolicyStatus.Pending]: 'individualPolicies.status.pending',
  [IndividualPolicyStatus.Suspended]: 'individualPolicies.status.suspended',
  [IndividualPolicyStatus.Unassigned]: 'individualPolicies.status.unassigned',
};
const mapStatusToColor: Record<IndividualPolicyStatus, string> = {
  [IndividualPolicyStatus.Active]: 'ok',
  [IndividualPolicyStatus.Unassigned]: 'unknown',
  [IndividualPolicyStatus.Pending]: 'warning',
  [IndividualPolicyStatus.Suspended]: 'error',
};

export type UseIPMappingsValues = {
  renderStatus: (record: IndividualPoliciesTableRecordItem) => string;
  renderStatusColor: (record: IndividualPoliciesTableRecordItem) => any;
};

const usePolicyStatusMap = (): UseIPMappingsValues => {
  const { t } = useTranslation();
  const renderStatus = (record: IndividualPoliciesTableRecordItem) => {
    return t(mapStatusToMessageId[record.status]);
  };
  const renderStatusColor = (record: IndividualPoliciesTableRecordItem) => {
    return mapStatusToColor[record.status];
  };

  return {
    renderStatus,
    renderStatusColor,
  };
};

export default usePolicyStatusMap;
