import React, { FunctionComponent } from 'react';

type IconProps = JSX.IntrinsicElements['svg'];

export const UploadIcon: FunctionComponent<IconProps> = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 21L20 21L20 13.5L21 13.5L21 22L3 22L3 13.5L4 13.5L4 21ZM15.6465 7.85352L12.5 4.70703L12.5 16.5L11.5 16.5L11.5 4.70711L8.35359 7.85352L7.64648 7.14641L12 2.79285L16.3536 7.14641L15.6465 7.85352Z"
        fill="#81BB85"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 21L20 21L20 13.5L21 13.5L21 22L3 22L3 13.5L4 13.5L4 21ZM15.6465 7.85352L12.5 4.70703L12.5 16.5L11.5 16.5L11.5 4.70711L8.35359 7.85352L7.64648 7.14641L12 2.79285L16.3536 7.14641L15.6465 7.85352Z"
        fill="#17E3AE"
      />
    </svg>
  );
};
