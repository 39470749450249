import React from 'react';
import { AdjustableComponent } from '../../utils/hooks/useClasses';
import { TextToggler } from 'plume-ui';
import { FieldInputProps, FormikProps } from 'formik';

type FormikTextTogglerProps = {
  labelSelected: string;
  labelNotSelected: string;
  field: FieldInputProps<boolean>;
  form: FormikProps<boolean>;
} & Omit<JSX.IntrinsicElements['input'], 'value' | 'type'>;

export type FormikTextTogglerStyles = {
  root: string;
};

const FormikTextToggler: AdjustableComponent<
  FormikTextTogglerProps,
  FormikTextTogglerStyles
> = ({ labelSelected, labelNotSelected, field, form, ...props }) => {
  const title = field.value ? labelSelected : labelNotSelected;

  const onToggle = () => {
    form.setFieldValue(field.name, !field.value);
  };

  return (
    <TextToggler title={title} selected={field.value} onToggle={onToggle} />
  );
};

export default FormikTextToggler;
