import { DependencyContainer } from '../../../DependencyContainer';
import { Partner } from '../../../state';

export default class PartnersService {
  constructor(private readonly factory: DependencyContainer) {}

  async getPartners(): Promise<Partner[]> {
    return await this.factory.partnersClient.fetchPartners();
  }
}
