import { FormikErrors } from 'formik';
import { Maybe } from '../types';

export const prepareErrorMessageForInput = (
  name: string,
  errors: FormikErrors<any>,
) => {
  if (!errors[name]) {
    return undefined;
  }
  return [
    {
      status: 'error',
      message: errors[name],
    },
  ];
};

export const prepareHintMessageForInput = (
  name: string,
  hint: JSX.Element | string,
) => {
  return [
    {
      status: 'hint',
      message: hint,
    },
  ];
};

export const prepareAllMessagesForInput = (
  name: string,
  hint: Maybe<JSX.Element | string>,
  errors: FormikErrors<any>,
) => {
  const errorMessages = prepareErrorMessageForInput(name, errors) || [];
  if (hint) {
    const hintMessage = prepareHintMessageForInput(name, hint);
    return [...errorMessages, ...hintMessage];
  }
  return errorMessages;
};
