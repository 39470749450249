import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  Drawer,
  Dropdown,
  DropdownItem,
  Heading,
  Icons,
  Menu,
  Space,
  TopBar,
  Notifications,
  IconButton,
} from 'plume-ui';
import useMenu from '../hooks/useMenu';
import { useTranslation } from 'react-i18next';
import { DependencyContainer } from '../DependencyContainer';
import { useTrackEvent } from '../features/trackingAnalytics/hooks/useTrackEvent';
import { MixPanelEvents } from '../mixPanelEvents';
import { useOktaAuth } from '@okta/okta-react';
import { APP_URL, Routes, defaultLimitForDropdown } from '../config';
import { useGetCurrentRoute } from '../utils/hooks/useGetCurrentRoute';
import {
  confirmationModalAtom,
  Introspect,
  introspectAtom,
  Partner,
  partnersAtom,
  selectedCompanySelector,
  selectedPartnerSelector,
  companiesAtom,
} from '../state';
import { useRecoilState, useRecoilValue } from 'recoil';
import { usePersistentState } from '../persistent-state/persistent-state';
import { Company } from '../features/companies/types';
import { FlexRole } from '../features/login/types';
import ForRole from '../features/login/components/ForRole';
import ConfirmationModal from '../components/ConfirmationModal/ConfirmationModal';
import { Maybe, FlexApiResponse } from '../types';
import { useRedirectToRoute } from '../utils/hooks/useRedirectToRoute';
import { makeTextExcerpt, roleToDisplayNameTranslation } from '../helpers/text';

const {
  loginService,
  introspectService,
  partnersService,
  companyService,
} = new DependencyContainer();

const getUserNameForDropdown = () => {
  const { firstName } = loginService.getUser();
  return `${firstName}`;
};

const getBadgeInitials = () => {
  const { firstName, lastName } = loginService.getUser();
  return `${firstName[0]}${lastName[0]}`;
};
const getFullName = () => {
  const { firstName, lastName } = loginService.getUser();
  return `${firstName} ${lastName}`;
};

const AppLayout: FunctionComponent = ({ children }) => {
  const { t } = useTranslation();
  const trackEvent = useTrackEvent();
  const { oktaAuth } = useOktaAuth();
  const getCurrentRoute = useGetCurrentRoute();
  const getMenuItems = useMenu();
  const [introspect, setIntrospect] = useRecoilState(introspectAtom);
  const [partners, setPartners] = useRecoilState(partnersAtom);
  const selectedCompany = useRecoilValue(selectedCompanySelector);
  const selectedPartner = useRecoilValue(selectedPartnerSelector);
  const [selectedCompanyId, setSelectedCompanyId] = usePersistentState(
    'selectedCompanyId',
  );
  const [selectedPartnerId, setSelectedPartnerId] = usePersistentState(
    'selectedPartnerId',
  );
  const [confirmationModal, setConfirmationModal] = useRecoilState(
    confirmationModalAtom,
  );
  const [companies, setCompanies] = useRecoilState(companiesAtom);
  const [open, setOpen] = useState(true);
  const redirectToRoute = useRedirectToRoute();

  useEffect(() => {
    if (window.innerWidth < 1024) {
      setOpen(false);
    }
  }, []);

  useEffect(() => {
    introspectService
      .getIntrospect()
      .then((introspect: Introspect) => {
        setIntrospect(introspect);
        if (
          introspect.role === FlexRole.FlexAdmin &&
          introspect.corporationId
        ) {
          setSelectedCompanyId(introspect.corporationId);
        }
      })
      .catch((error: any) => console.error('error getting introspect'));

    const currentRoute = getCurrentRoute();
    if (currentRoute === Routes.Index) {
      return;
    }
  }, []);

  useEffect(() => {
    fetchPartners();
  }, [selectedCompanyId, selectedPartnerId]);

  const fetchPartners = async () => {
    partnersService
      .getPartners()
      .then((partners: Partner[]) => {
        setPartners(partners);
        const [firstPartner] = partners;
        if (!selectedPartnerId && firstPartner) {
          setSelectedPartnerId(firstPartner.id);
        }
        if (selectedPartnerId) {
          fetchCompanies(selectedPartnerId);
        }
      })
      .catch((error: any) => console.error('error getting partner'));
  };

  const logout = async () => {
    loginService.removeAuthError();
    trackEvent({
      eventName: MixPanelEvents.LOGOUT,
    });
    await oktaAuth.signOut({
      postLogoutRedirectUri: APP_URL,
    });
  };

  const fetchCompanies = async (partnerId: string) => {
    if (!partnerId) {
      return;
    }
    companyService
      .getCompanies(partnerId, 1, defaultLimitForDropdown)
      .then((companies: FlexApiResponse<Company[]>) => {
        setCompanies(companies.items);
        const [firstCompany] = companies.items;
        if (!selectedCompanyId && firstCompany) {
          setSelectedCompanyId(firstCompany.id);
          redirectToRoute(Routes.Status);
        }
        if (!companies.items.length) {
          redirectToRoute(Routes.Companies);
        }
      })
      .catch((error: any) => console.error('error getting company'));
  };

  const onSelectCompany = (company: Company) => {
    setSelectedCompanyId(company.id);
    if (selectedCompanyId) redirectToRoute(Routes.Status);
  };

  const onSelectPartner = (partner: Partner) => {
    if (selectedCompany) {
      setSelectedCompanyId(undefined);
    }
    setSelectedPartnerId(partner.id);
    fetchCompanies(partner.id);
  };

  const getSelectCompanyDropdownLabel = (): string => {
    if (selectedCompany) {
      return selectedCompany.businessName;
    }
    return t('selectCompany');
  };
  const getSelectPartnerDropdownLabel = (): string => {
    if (selectedPartner) {
      return selectedPartner?.name;
    }
    return t('selectPartner');
  };
  const userDropdownItems = (
    username: string,
    email: string,
    role: Maybe<string>,
  ) => (
    <div className="AppLayout__user--dropdown-wrapper">
      <div style={{ color: '#dee0e2' }}>{username}</div>
      <div>{email}</div>
      <div>{role}</div>
    </div>
  );
  return (
    <div className="AppLayout">
      <TopBar
        leftSlotRenderer={() => (
          <IconButton selected={open} onClick={() => setOpen(!open)}>
            <Icons.HamburgerIcon />
          </IconButton>
        )}
        userDropdown={{
          username: getUserNameForDropdown(),
          variant: 'withAvatar',
          badge: {
            badgeContent: getBadgeInitials(),
            size: 'standard',
          },
          signOutAction: () => logout(),
        }}
        userDropdownInfo={userDropdownItems(
          getFullName(),
          loginService.getUser().email,
          t(roleToDisplayNameTranslation(introspect?.role)),
        )}
        rightSlotRenderer={() => {
          return (
            <ForRole differentThanRole={FlexRole.FlexAdmin}>
              <Dropdown
                ghost
                label={makeTextExcerpt(getSelectCompanyDropdownLabel(), 25)}
                disabled={Boolean(!selectedPartner) || companies.length === 0}
                closeOnItemClick
                noSearchMatchMessage={t('noResults')}
                searchPlaceholder={t('searchByName')}
                searchBar
                classes={(currentClasses) => ({
                  ...currentClasses,
                  root: `${currentClasses.root} AppLayout__company-selector`,
                  iconLeft: `${currentClasses.iconLeft} AppLayout__company-selector-icon-left`,
                  iconButton: `${currentClasses.iconButton} AppLayout__company-selector-icon-button`,
                  icon: `${currentClasses.icon} AppLayout__company-selector-icon`,
                })}
              >
                {companies.map((company: Company) => (
                  <DropdownItem
                    selected={company.id === selectedCompanyId}
                    onClick={() => onSelectCompany(company)}
                    key={company.id}
                  >
                    {makeTextExcerpt(company.businessName, 25)}
                  </DropdownItem>
                ))}
              </Dropdown>
            </ForRole>
          );
        }}
        centerSlotRenderer={() => {
          return (
            <>
              <ForRole differentThanRole={FlexRole.FlexAdmin}>
                <Dropdown
                  ghost
                  label={getSelectPartnerDropdownLabel()}
                  closeOnItemClick
                  noSearchMatchMessage={t('noResults')}
                  searchPlaceholder={t('searchByName')}
                  searchBar
                  classes={(currentClasses) => ({
                    ...currentClasses,
                    root: `${currentClasses.root} AppLayout__partner-selector`,
                    iconLeft: `${currentClasses.iconLeft} AppLayout__partner-selector-icon-left`,
                    iconButton: `${currentClasses.iconButton} AppLayout__partner-selector-icon-button`,
                    icon: `${currentClasses.icon} AppLayout__partner-selector-icon`,
                    label: `${currentClasses.label} AppLayout__partner-selector-label`,
                  })}
                >
                  {partners?.map((partner: Partner) => (
                    <DropdownItem
                      selected={partner.id === selectedPartnerId}
                      onClick={() => onSelectPartner(partner)}
                      key={partner.id}
                    >
                      {partner.name}
                    </DropdownItem>
                  ))}
                </Dropdown>
              </ForRole>
              <ForRole role={FlexRole.FlexAdmin}>
                {selectedCompany?.businessName ? (
                  <p>{selectedCompany.businessName}</p>
                ) : (
                  ''
                )}
              </ForRole>
            </>
          );
        }}
        classes={(current) => ({
          ...current,
          centerSlot: `${current.centerSlot} AppLayout__top-bar-center-slot`,
          rightmostElements: `${current.rightmostElements} AppLayout__top-bar-right-slot`,
          leftSlot: `${current.leftSlot} AppLayout__top-bar-left-slot`,
        })}
      />
      <div className="AppLayout__row">
        <Drawer
          isOpen={open}
          hideButton
          classes={(current) => ({
            ...current,
            root: `${current.root} AppLayout__drawer`,
          })}
          contentRenderer={(isOpen: boolean, isTransitionFinished: boolean) => (
            <div>
              <div className="AppLayout__drawer-heading">
                <Icons.ApplicationsIcon width={24} />
                {isOpen && <Heading size="xl">{t('appName')}</Heading>}
              </div>
              <Space size="l"></Space>
              <Menu
                groupedItems={getMenuItems()}
                preventSwitchingGroupName={!isTransitionFinished}
                variant={isOpen ? 'expanded' : 'collapsed'}
              />
            </div>
          )}
        />
        <div className="AppLayout__content" style={{ width: '100%' }}>
          {children}
        </div>
      </div>
      <ConfirmationModal
        isOpen={confirmationModal.isOpen}
        onRequestClose={() =>
          setConfirmationModal((prev) => ({
            ...prev,
            isOpen: false,
          }))
        }
        onConfirm={() => confirmationModal.onConfirm?.()}
      />
      <Notifications />
    </div>
  );
};

export default AppLayout;
