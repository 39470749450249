import { DependencyContainer } from '../../../DependencyContainer';
import { Introspect } from '../../../state';

export default class IntrospectService {
  constructor(private readonly factory: DependencyContainer) {}

  async getIntrospect(): Promise<Introspect> {
    return await this.factory.introspectClient.fetchIntrospect();
  }
}
