import React from 'react';
import { Icons } from 'plume-ui';
import { MenuItems } from 'plume-ui/dist/components/Menu/Menu';
import { useRedirectToRoute } from '../utils/hooks/useRedirectToRoute';
import { Routes } from '../config';
import { useGetCurrentRoute } from '../utils/hooks/useGetCurrentRoute';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import {
  introspectAtom,
  selectedPartnerSelector,
  selectedCompanySelector,
} from '../state';
import { FlexRole } from '../features/login/types';

const useMenu = () => {
  const getCurrentRoute = useGetCurrentRoute();
  const redirectToRoute = useRedirectToRoute();
  const getIntrospect = useRecoilValue(introspectAtom);
  const { t } = useTranslation();
  const selectedPartner = useRecoilValue(selectedPartnerSelector);
  const selectedCompany = useRecoilValue(selectedCompanySelector);
  const currentRoute = getCurrentRoute();

  const menuItemsForAll: MenuItems = [
    {
      name: t('menu.status'),
      iconLeft: <Icons.LineGraphIcon width={24} />,
      iconLeftSelected: <Icons.LineGraphFillIcon width={24} />,
      iconRight: <Icons.ArrowRightIcon />,
      onItemClick: () =>
        selectedCompany !== undefined ? redirectToRoute(Routes.Status) : null,
      isSelected: [Routes.Index, Routes.Status].includes(currentRoute),
      isDisabled: selectedCompany === undefined,
    },
    {
      name: t('menu.individualPolicy'),
      iconLeft: <Icons.IndividualPoliciesIcon width={24} />,
      iconLeftSelected: <Icons.IndividualPoliciesFillIcon width={24} />,
      iconRight: <Icons.ArrowRightIcon />,
      onItemClick: () =>
        selectedCompany !== undefined
          ? redirectToRoute(Routes.IndividualPolicies)
          : null,
      isSelected: currentRoute === Routes.IndividualPolicies,
      isDisabled: selectedCompany === undefined,
    },
    {
      name: t('menu.templates'),
      iconLeft: <Icons.TemplatesIcon width={24} />,
      iconLeftSelected: <Icons.TemplatesFillIcon width={24} />,
      iconRight: <Icons.ArrowRightIcon />,
      onItemClick: () =>
        selectedCompany !== undefined
          ? redirectToRoute(Routes.Templates)
          : null,
      isSelected: currentRoute === Routes.Templates,
      isDisabled: selectedCompany === undefined,
    },
    {
      name: t('menu.users'),
      iconLeft: <Icons.GroupIcon width={24} />,
      iconLeftSelected: <Icons.GroupFillIcon width={24} />,
      iconRight: <Icons.ArrowRightIcon />,
      onItemClick: () =>
        selectedCompany !== undefined ? redirectToRoute(Routes.Users) : null,
      isSelected: currentRoute === Routes.Users,
      isDisabled: selectedCompany === undefined,
    },
  ];

  const flexAdminItems: MenuItems = [
    {
      name: t('menu.customerSupport'),
      iconLeft: <Icons.CommentsIcon width={24} />,
      iconLeftSelected: <Icons.CommentsIcon width={24} />,
      iconRight: <Icons.ArrowRightIcon />,
      onItemClick: () =>
        selectedCompany !== undefined
          ? redirectToRoute(Routes.CustomerSupport)
          : null,
      isSelected: currentRoute === Routes.CustomerSupport,
      isDisabled: selectedCompany === undefined,
    },
  ];
  const menuItems: MenuItems = [
    ...menuItemsForAll,
    {
      name: t('menu.companies'),
      iconLeft: <Icons.CompanyIcon width={24} />,
      iconLeftSelected: <Icons.CompanyFillIcon width={24} />,
      iconRight: <Icons.ArrowRightIcon />,
      onItemClick: () =>
        selectedPartner !== undefined
          ? redirectToRoute(Routes.Companies)
          : null,
      isSelected: currentRoute === Routes.Companies,
      isDisabled: selectedPartner === undefined,
    },
  ];

  const grouped = [
    {
      items:
        getIntrospect?.role === FlexRole.FlexAdmin
          ? [...menuItemsForAll, ...flexAdminItems]
          : menuItems,
    },
  ];
  return () => grouped;
};

export default useMenu;
