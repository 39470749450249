import React, { FunctionComponent } from 'react';
import {
  AreaChart,
  Block,
  Heading,
  PendingContent,
  Spinner,
  Tooltip,
  IconButton,
  Icons,
} from 'plume-ui';
import { ChartPeriodContext } from '../../../contexts/ChartPeriodContext';
import FormattedMessage from '../../../../../utils/components/FormattedMessage';
import { TimeLinePeriodTypes } from '../../../types';
import MonthDropdown from '../../MonthDropdown/MonthDropdown';
import TimeLinePicker from '../../TimelinePicker/TimelinePicker';
import { useTranslation } from 'react-i18next';
import { useTimeLine } from '../../../hooks/useTimeLine';
import { BlockStyles } from 'plume-ui/dist/components/Block/Block';
import { DependencyContainer } from '../../../../../DependencyContainer';
import { useRecoilValue } from 'recoil';
import { selectedCompanySelector } from '../../../../../state';
import { useFetchData } from '../../../../../hooks/useFetchData';
import { renderXaxisTicks } from '../../../helpers/tick-formatter';

const addStylesToBlock = (current: BlockStyles) => ({
  ...current,
  root: `${current.root} ImpactedEmployees`,
});
const { statusService } = new DependencyContainer();
const ImpactedEmployeeOnboardingChartContainer: FunctionComponent = () => {
  const {
    getContextDefaultValues,
    selectedPeriodType,
    selectedMonth,
    getPeriod,
    getType,
  } = useTimeLine();
  const { t } = useTranslation();

  const tickFormatterPercentage = (value: any) => {
    const parsedValue = (parseFloat(value) * 100).toFixed(0).toString();
    return parsedValue + '%';
  };

  const impactedEmployeesGroup = [
    {
      label: t('impactedEmployeesChart.ipsec'),
      backgroundColor: 'rgba(229, 177, 0, 0.2)',
      stroke: '#E5B100',
      strokeWidth: 2,
      key: 'groupC',
    },
    {
      label: t('impactedEmployeesChart.radius'),
      backgroundColor: 'rgba(220, 5, 98, 0.2)',
      stroke: '#DC0562',
      strokeWidth: 2,
      key: 'groupD',
    },
  ];
  const selectedCompany = useRecoilValue(selectedCompanySelector);
  let [loading, data, error] = useFetchData<
    Array<Record<'xAxisLabel' | string, string | number>>,
    any
  >(
    async () => {
      if (!selectedCompany) {
        return;
      }
      const [period, type] = [getPeriod(), getType()];
      return await statusService.getImpactedEmployees(
        selectedCompany.id,
        period,
        type,
      );
    },
    undefined,
    [selectedCompany, selectedPeriodType, selectedMonth],
    () => selectedCompany === undefined,
  );

  return (
    <ChartPeriodContext.Provider value={getContextDefaultValues()}>
      <PendingContent
        loading={loading}
        isError={Boolean(error)}
        hideContent
        loader={Spinner}
      >
        <Block classes={addStylesToBlock} id="ImpactedEmployeesChart">
          <div className="ImpactedEmployees__labelContainer">
            <Heading level={3} size="xl" medium>
              <FormattedMessage id="impactedEmployeesChart.title" />
            </Heading>
            <Tooltip
              heading={t('impactedEmployeesChart.title')}
              subSectionsObj={[
                {
                  subheading: t(
                    'impactedEmployeesChart.tooltip.subheadingMonth',
                  ),
                  paragraph: t('impactedEmployeesChart.tooltip.month'),
                },
                {
                  subheading: t(
                    'impactedEmployeesChart.tooltip.subheadingDays',
                  ),
                  paragraph: t('impactedEmployeesChart.tooltip.30days'),
                },
                {
                  subheading: t(
                    'impactedEmployeesChart.tooltip.subheadingHours',
                  ),
                  paragraph: t('impactedEmployeesChart.tooltip.24hours'),
                },
              ]}
              position={'above'}
              alignment={'center'}
            >
              <IconButton small={true}>
                <Icons.InfoIcon />
              </IconButton>
            </Tooltip>
          </div>
          <div className="ImpactedEmployees__timeline--container">
            <span className="ImpactedEmployees__timeline--month">
              {selectedPeriodType === TimeLinePeriodTypes.CalendarMonth && (
                <MonthDropdown />
              )}
            </span>
            <TimeLinePicker />
          </div>
          {!data?.length ? (
            <div className="EmployeeOnboarding__empty--container">
              <FormattedMessage id="noDataAvailable" />
            </div>
          ) : (
            <AreaChart
              domainY={[0, 1]}
              isStacked={false}
              tickFormatter={tickFormatterPercentage}
              data={data}
              groups={impactedEmployeesGroup}
              legendPosition="bottom"
              showPercentageValue
              xAxisTickInterval={renderXaxisTicks(selectedPeriodType)}
            />
          )}
        </Block>
      </PendingContent>
    </ChartPeriodContext.Provider>
  );
};
export default ImpactedEmployeeOnboardingChartContainer;
