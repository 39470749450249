import { AxiosResponse } from 'axios';
import { FlexApiUrls } from '../../../urls';
import { FlexApiResponse } from '../../../types';
import { HttpClient } from '../../../httpClient';
import { User, UpdateUser } from '../../../state';
import { CreateUserDto, CreateUserResponse } from '../types';

export default class UsersClient extends HttpClient {
  async fetchUsers(
    companyId: string,
  ): Promise<AxiosResponse<FlexApiResponse<User[]>>> {
    const response = await this.get<AxiosResponse<FlexApiResponse<User[]>>>(
      FlexApiUrls.getUsersUrl(companyId),
    );
    return response;
  }

  async createUser(
    dto: CreateUserDto,
    companyId: string,
  ): Promise<AxiosResponse<CreateUserResponse>> {
    return await this.post<AxiosResponse<CreateUserResponse>>(
      FlexApiUrls.getCreateUserUrl(companyId),
      dto,
    );
  }

  async resetPasswordUser(
    companyId: string,
    email: string,
  ): Promise<AxiosResponse<CreateUserResponse>> {
    return await this.put<AxiosResponse<CreateUserResponse>>(
      FlexApiUrls.getResetPasswordUserUrl(companyId, email),
      '',
    );
  }

  async updateUser(
    dto: UpdateUser,
    companyId: string,
    email: string,
  ): Promise<AxiosResponse<CreateUserResponse>> {
    return await this.patch<AxiosResponse<CreateUserResponse>>(
      FlexApiUrls.getUpdateUserUrl(companyId, email),
      dto,
    );
  }

  async deleteUser(companyId: string, email: string): Promise<void> {
    await this.delete(FlexApiUrls.getDeleteUserUrl(companyId, email));
  }
}
