import { TunnelTypes } from './features/configurations/types';

export enum Routes {
  Index = '/',
  Login = '/login',
  IndividualPolicies = '/individual-policies',
  Templates = '/templates',
  Template = '/template/:id',
  CreateTemplate = '/template/create',
  Users = '/users',
  Configurations = '/config',
  Companies = '/companies',
  Status = '/status',
  LoginCallback = '/implicit/callback',
  CustomerSupport = '/customer-support',
}

export const LOGIN_SCREEN_ROUTE = Routes.Login;

export const DEFAULT_LIMIT_OF_RECORDS: number = 9;

export const APP_URL = process.env.REACT_APP_URL || window.location.origin;
export const FLEX_BACKEND_URL =
  process.env.REACT_FLEX_BACKEND_URL || 'http://localhost:3001';
export const OKTA_BASE_URL =
  process.env.REACT_APP_OKTA_BASE || 'https://external-dev.sso.plume.com';
export const OKTA_ISSUER =
  process.env.REACT_APP_OKTA_ISSUER || `${OKTA_BASE_URL}/oauth2/default`;
export const OKTA_CLIENT_ID =
  process.env.REACT_APP_OKTA_CLIENT_ID || '0oa15glqqc4MrpK8R0h8';
export const OKTA_REDIRECT_URI =
  process.env.REACT_APP_OKTA_REDIRECT_URI ||
  `${window.location.origin}/implicit/callback`;

export const TRANSLATION_FILE_PATH_TEMPLATE =
  process.env.REACT_APP_TRANSLATION_FILE_PATH_TEMPLATE || '/{{lng}}.json';

export const MIXPANEL_ID =
  process.env.REACT_APP_MIXPANEL_ID || '99e8af7b1fbd73baf56738baf08df3dd';

export const mapTunnelTypeToSubnetMax: Record<TunnelTypes, number> = {
  [TunnelTypes.IPSEC]: 32,
  [TunnelTypes.IPSECDynamic]: 32,
  [TunnelTypes.IPSECStatic]: 32,
};
export const maxSubnetPrefixValue = 128;
export const defaultLimitForDropdown = 500;
export const templateNameMaxCharacters = 22; // to cut the rest of text on templates list
export const uploadModalMaxRecentUploads = 4;

export const phoneValidationRegex = /^\+(?:[0-9] ?){6,14}[0-9]$/;
