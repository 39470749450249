import LoginService from './features/login/LoginService';
import CompanyService from './features/companies/services/CompanyService';
import CompanyClient from './features/companies/services/CompanyClient';
import IntrospectClient from './features/login/services/IntrospectClient';
import IntrospectService from './features/login/services/IntrospectService';
import UsersService from './features/users/services/UsersService';
import UsersClient from './features/users/services/UsersClient';
import TemplatesClient from './features/templates/services/TemplatesClient';
import TemplatesService from './features/templates/services/TemplatesService';
import IndividualPoliciesService from './features/individualPolicies/services/IndividualPoliciesService';
import IndividualPoliciesClient from './features/individualPolicies/services/IndividualPoliciesClient';
import PartnersService from './features/login/services/PartnersService';
import PartnersClient from './features/login/services/PartnersClient';
import StatusService from './features/status/services/StatusService';
import StatusClient from './features/status/services/StatusClient';
import CustomerSupportService from './features/customerSupport/services/CustomerSupportService';
import CustomerSupportClient from './features/customerSupport/services/CustomerSupportClient';

export class DependencyContainer {
  // services
  loginService = new LoginService(this);
  companyService = new CompanyService(this);
  introspectService = new IntrospectService(this);
  partnersService = new PartnersService(this);
  usersService = new UsersService(this);
  templatesService = new TemplatesService(this);
  individualPoliciesService = new IndividualPoliciesService(this);
  statusService = new StatusService(this);
  customerSupportService = new CustomerSupportService(this);

  //clients
  companyClient = new CompanyClient(this);
  introspectClient = new IntrospectClient(this);
  usersClient = new UsersClient(this);
  templatesClient = new TemplatesClient(this);
  individualPoliciesClient = new IndividualPoliciesClient(this);
  partnersClient = new PartnersClient(this);
  statusClient = new StatusClient(this);
  customerSupportClient = new CustomerSupportClient(this);
}
