import React, { FunctionComponent, useEffect } from 'react';
import persistentState from './persistent-state';
import { useSetRecoilState } from 'recoil';

const PersistentStateRoot: FunctionComponent = (props) => {
  const setSelectedCompanyId = useSetRecoilState(
    persistentState.getAtom('selectedCompanyId'),
  );
  const setSelectedPartnerId = useSetRecoilState(
    persistentState.getAtom('selectedPartnerId'),
  );

  useEffect(() => {
    const unsubscribe = persistentState.subscribe((key: any, value: any) => {
      switch (key) {
        case 'selectedCompanyId':
          setSelectedCompanyId(value);
          break;
        case 'selectedPartnerId':
          setSelectedPartnerId(value);
          break;
      }
    });
    return unsubscribe;
  }, []);

  return <>{props.children}</>;
};

export default PersistentStateRoot;
