export enum MixPanelEvents {
  AUTHENTICATION_SUCCESS = 'AUTHENTICATION_SUCCESS',
  LOGOUT = 'LOGOUT',
  SCREEN = 'SCREEN',
  PARTNER_SELECTION = 'PARTNER_SELECTION',
  COMPANY_SELECTION = 'COMPANY_SELECTION',
  ADD_COMPANY_TEMPLATE_SUCCESS = 'ADD_COMPANY_TEMPLATE_SUCCESS',
  ADD_COMPANY_TEMPLATE_FAILURE = 'ADD_COMPANY_TEMPLATE_FAILURE',
  EDIT_COMPANY_TEMPLATE_SUCCESS = 'EDIT_COMPANY_TEMPLATE_SUCCESS',
  EDIT_COMPANY_TEMPLATE_FAILURE = 'EDIT_COMPANY_TEMPLATE_FAILURE',
  DELETE_COMPANY_TEMPLATE_SUCCESS = 'DELETE_COMPANY_TEMPLATE_SUCCESS',
  DELETE_COMPANY_TEMPLATE_FAILURE = 'DELETE_COMPANY_TEMPLATE_FAILURE',
  ADD_INDIVIDUAL_POLICY_SUCCESS = 'ADD_INDIVIDUAL_POLICY_SUCCESS',
  ADD_INDIVIDUAL_POLICY_FAILURE = 'ADD_INDIVIDUAL_POLICY_FAILURE',
  EDIT_INDIVIDUAL_POLICY_SUCCESS = 'EDIT_INDIVIDUAL_POLICY_SUCCESS',
  EDIT_INDIVIDUAL_POLICY_FAILURE = 'EDIT_INDIVIDUAL_POLICY_FAILURE',
  DELETE_INDIVIDUAL_POLICY_SUCCESS = 'DELETE_INDIVIDUAL_POLICY_SUCCESS',
  DELETE_INDIVIDUAL_POLICY_FAILURE = 'DELETE_INDIVIDUAL_POLICY_FAILURE',
  ADD_COMPANY_SUCCESS = 'ADD_COMPANY_SUCCESS',
  ADD_COMPANY_FAILURE = 'ADD_COMPANY_FAILURE',
  EDIT_COMPANY_SUCCESS = 'EDIT_COMPANY_SUCCESS',
  EDIT_COMPANY_FAILURE = 'EDIT_COMPANY_FAILURE',
  DELETE_COMPANY_SUCCESS = 'DELETE_COMPANY_SUCCESS',
  DELETE_COMPANY_FAILURE = 'DELETE_COMPANY_FAILURE',
  ADD_USER_SUCCESS = 'ADD_USER_SUCCESS',
  ADD_USER_FAILURE = 'ADD_USER_FAILURE',
  RESET_PASSWORD_USER_SUCCESS = 'RESET_PASSWORD_USER_SUCCESS',
  RESET_PASSWORD_USER_FAILURE = 'RESET_PASSWORD_USER_FAILURE',
  EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS',
  EDIT_USER_FAILURE = 'EDIT_USER_FAILURE',
  DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS',
  DELETE_USER_FAILURE = 'DELETE_USER_FAILURE',
  ADD_CUSTOMER_SUPPORT_SUCCESS = 'ADD_CUSTOMER_SUPPORT_SUCCESS',
  ADD_CUSTOMER_SUPPORT_FAILURE = 'ADD_CUSTOMER_SUPPORT_FAILURE',
}
