import React, { FunctionComponent, useEffect } from 'react';
import { CollapseCard, Heading, Status } from 'plume-ui';
import { useFormikContext } from 'formik';
import { TemplateConfigurationFieldsNames } from '../../form-data-marshaller';
import { FormFieldDefinition } from '../../../../types';
import FormFieldRenderer from '../../FormFieldRenderer';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useTrackEvent } from '../../../trackingAnalytics/hooks/useTrackEvent';
import { MixPanelEvents } from '../../../../mixPanelEvents';
import { AvailableScreens } from '../../../trackingAnalytics/types';
import classNames from 'classnames';
import { getFieldKey } from '../../../../helpers/fields';

export type TemplateConfigurationSectionProps = {
  fieldsDefinitions: Array<
    FormFieldDefinition<TemplateConfigurationFieldsNames>
  >;
  sectionTitle: string;
  sectionIdentifier: string;
  isOpen?: boolean;
  isRequired?: boolean;
  onFormError?: (sectionIdentifier: string) => void;
};

const requiredFieldsNotEmpty = (values: any, requiredFieldsNames: string[]) => {
  if (!values) {
    return false;
  }
  const logicalValues = requiredFieldsNames.map((fieldName) => {
    const fieldValue = values[fieldName] || undefined;
    if (!fieldValue) {
      return false;
    }
    if (Array.isArray(fieldValue)) {
      const emptyItems = fieldValue.filter(
        (v) => v === '' || v === null || v === undefined,
      );
      if (emptyItems.length === fieldValue.length) {
        return false;
      }
      return fieldValue.length > 0;
    }
    return true;
  });
  return Boolean(logicalValues.filter((v) => !v).length === 0);
};

const hasAnyOfFieldsError = (
  fieldsDefinitions: Array<
    FormFieldDefinition<TemplateConfigurationFieldsNames>
  >,
  errorsObject: any,
) => {
  if (!errorsObject) {
    return false;
  }
  let hasError = false;
  const errors = Object.keys(errorsObject);
  const fieldNames = fieldsDefinitions
    .map((d) => (d.type !== 'spacer' ? d.name : undefined))
    .filter((d) => !!d);
  fieldNames.forEach((name) => {
    if (name && errors.includes(name)) {
      hasError = true;
      return;
    }
  });
  return hasError;
};

const TemplateConfigurationSection: FunctionComponent<TemplateConfigurationSectionProps> = ({
  fieldsDefinitions,
  sectionIdentifier,
  sectionTitle,
  isRequired = true,
  isOpen = true,
  onFormError,
}) => {
  const { t } = useTranslation();
  const { values, errors } = useFormikContext();
  const requiredFieldsNames = fieldsDefinitions
    .filter((d) => d.type !== 'spacer' && d.required)
    .map((d) => (d.type !== 'spacer' ? d.name : undefined))
    .filter((d) => d !== undefined) as TemplateConfigurationFieldsNames[];
  const isSectionCompleted = requiredFieldsNotEmpty(
    values,
    requiredFieldsNames,
  );
  const hasFormError = hasAnyOfFieldsError(fieldsDefinitions, errors);
  const trackEvent = useTrackEvent();

  useEffect(() => {
    trackEvent({
      eventName: MixPanelEvents.SCREEN,
      additionalContent: {
        SCREEN: AvailableScreens.TemplatePolicyDetail,
      },
    });
  }, []);

  useEffect(() => {
    if (hasFormError) {
      onFormError?.(sectionIdentifier);
    }
  }, [hasFormError]);

  return (
    <div className="TemplateConfigurationSection">
      <CollapseCard
        open={isOpen}
        title={<Heading size="xl">{sectionTitle}</Heading>}
        actions={[
          isRequired && (
            <Status
              classes={(current) => ({
                ...current,
                indicator: classNames(
                  'TemplateConfigurationSection__status-indicator',
                ),
                root: `${current.root} TemplateConfigurationSection__status`,
                label: cx(current.root, {
                  'TemplateConfigurationSection__Status-label': true,
                  'TemplateConfigurationSection__status-label--ok': isSectionCompleted,
                  'TemplateConfigurationSection__status-label--warning': !isSectionCompleted,
                }),
              })}
              color={isSectionCompleted ? 'ok' : 'warning'}
              label={isSectionCompleted ? t('complete') : t('incomplete')}
            />
          ),
        ]}
      >
        <div className="TemplateConfigurationSection__wrapper">
          {fieldsDefinitions.map((field, index) => (
            <FormFieldRenderer
              fieldDefinition={field}
              key={getFieldKey(field, index)}
            />
          ))}
        </div>
      </CollapseCard>
    </div>
  );
};
export default TemplateConfigurationSection;
