import { TemplateConfigurationFieldsNames } from '../form-data-marshaller';
import yup from '../../../utils/yupInstance';
import { useTranslation } from 'react-i18next';

const useValidationSchema = () => {
  const { t } = useTranslation();
  return yup.object().shape({
    [TemplateConfigurationFieldsNames.WorkNetworkSsid]: yup
      .string()
      .min(3, t('WorkNetworkConfigurations.validation.ssid.min'))
      .max(32, t('WorkNetworkConfigurations.validation.ssid.max'))
      .required(t('WorkNetworkConfigurations.validation.ssid.required')),
    [TemplateConfigurationFieldsNames.WorkNetworkDns]: yup
      .array()
      .required(t('WorkNetworkConfigurations.validation.dns.required'))
      .min(1, t('WorkNetworkConfigurations.validation.dns.required'))
      .of(
        yup
          .string()
          .required(t('WorkNetworkConfigurations.validation.dns.required'))
          .ip(t('WorkNetworkConfigurations.validation.dns.ip')),
      ),
    [TemplateConfigurationFieldsNames.WorkNetworkDHCPOptions]: yup
      .array()
      .of(
        yup.object().shape({
          id: yup
            .number()
            .min(
              1,
              t(
                'WorkNetworkConfigurations.validation.dhcpOptions.id.positiveNumber',
              ),
            )
            .required(
              t('WorkNetworkConfigurations.validation.dhcpOptions.id.required'),
            ),
          value: yup
            .string()
            .required(
              t(
                'WorkNetworkConfigurations.validation.dhcpOptions.value.required',
              ),
            ),
        }),
      )
      .optional(),
    [TemplateConfigurationFieldsNames.WorkNetworkClasslessStaticRoutes]: yup
      .array()
      .of(
        yup
          .string()
          .ipWithSubnet(
            t('WorkNetworkConfigurations.validation.classlessStaticRoutes.ip'),
          )
          .maxSubnetPrefix(
            32,
            t(
              'WorkNetworkConfigurations.validation.classlessStaticRoutes.subnet',
            ),
          ),
      )
      .optional(),

    // Radius section
    [TemplateConfigurationFieldsNames.RadiusAuthenticationPrimaryAuthorizationServer]: yup
      .string()
      .ipWithPort(t('radiusAuthConfigurations.validation.generic.ipWithPort'))
      .required(
        t('radiusAuthConfigurations.validation.primaryAuthIP.required'),
      ),
    [TemplateConfigurationFieldsNames.RadiusAuthenticationPrimaryAuthorizationServerPSK]: yup
      .string()
      .min(3, t('radiusAuthConfigurations.validation.primaryAuthPSK.min'))
      .required(
        t('radiusAuthConfigurations.validation.primaryAuthPSK.required'),
      ),
    [TemplateConfigurationFieldsNames.RadiusAuthenticationSecondaryAuthorizationServer]: yup
      .string()
      .ipWithPort(t('radiusAuthConfigurations.validation.generic.ipWithPort'))
      .optional(),
    [TemplateConfigurationFieldsNames.RadiusAuthenticationSecondaryAuthorizationServerPSK]: yup
      .string()
      .when(
        TemplateConfigurationFieldsNames.RadiusAuthenticationSecondaryAuthorizationServer,
        {
          is: (field: string) => field?.length > 0,
          then: yup
            .string()
            .required(
              t(
                'radiusAuthConfigurations.validation.secondaryAuthPSK.required',
              ),
            ),
        },
      ),
    [TemplateConfigurationFieldsNames.RadiusAuthenticationPrimaryAccountingServer]: yup
      .string()
      .ipWithPort(t('radiusAuthConfigurations.validation.generic.ipWithPort'))
      .optional(),
    [TemplateConfigurationFieldsNames.RadiusAuthenticationPrimaryAccountingServerPSK]: yup
      .string()
      .when(
        TemplateConfigurationFieldsNames.RadiusAuthenticationPrimaryAccountingServer,
        {
          is: (field: string) => field?.length > 0,
          then: yup
            .string()
            .required(
              t(
                'radiusAuthConfigurations.validation.primaryAccountServerPSK.required',
              ),
            ),
        },
      ),
    [TemplateConfigurationFieldsNames.RadiusAuthenticationSecondaryAccountingServer]: yup
      .string()
      .ipWithPort(t('radiusAuthConfigurations.validation.generic.ipWithPort'))
      .optional(),
    [TemplateConfigurationFieldsNames.RadiusAuthenticationSecondaryAccountingServerPSK]: yup
      .string()
      .when(
        TemplateConfigurationFieldsNames.RadiusAuthenticationSecondaryAccountingServer,
        {
          is: (field: string) => field?.length > 0,
          then: yup
            .string()
            .required(
              t(
                'radiusAuthConfigurations.validation.secondaryAccountServerPSK.required',
              ),
            ),
        },
      ),

    // IPSec Primary
    [TemplateConfigurationFieldsNames.IPSecPrimaryTunnelType]: yup
      .string()
      .required(t('IPsecConfigurations.validation.tunnelType.required')),
    [TemplateConfigurationFieldsNames.IPSecPrimaryTunnelAddress]: yup
      .string()
      .remoteWAN(t('IPsecConfigurations.validation.tunnelAddress.ip'))
      .required(t('IPsecConfigurations.validation.tunnelAddress.required')),
    [TemplateConfigurationFieldsNames.IPSecPrimaryRoutes]: yup
      .array()
      .required(t('IPsecConfigurations.validation.routes.required'))
      .min(1, t('IPsecConfigurations.validation.routes.required'))
      .of(
        yup
          .string()

          .ipWithSubnet(t('IPsecConfigurations.validation.routes.ip'))
          .maxSubnetPrefix(
            32,
            t('IPsecConfigurations.validation.routes.subnet'),
          ),
      ),
    [TemplateConfigurationFieldsNames.IPSecPrimaryRemoteSubnets]: yup
      .array()
      .of(
        yup
          .string()
          .ipWithSubnet(t('IPsecConfigurations.validation.remoteSubnets.ip'))
          .maxSubnetPrefix(
            32,
            t('IPsecConfigurations.validation.remoteSubnets.subnet'),
          ),
      )
      .optional(),
    [TemplateConfigurationFieldsNames.IPSecPrimaryHealthcheckEndpoint]: yup
      .string()
      .ip(t('IPsecConfigurations.validation.healthCheckEndpoint.ip'))
      .optional(),
    // IPSec Secondary
    [TemplateConfigurationFieldsNames.IPSecSecondaryTunnelType]: yup
      .string()
      .optional(),
    [TemplateConfigurationFieldsNames.IPSecSecondaryTunnelAddress]: yup
      .string()
      .remoteWAN(t('IPsecConfigurations.validation.tunnelAddress.ip'))
      .optional(),
    [TemplateConfigurationFieldsNames.IPSecSecondaryRoutes]: yup
      .array()
      .of(
        yup
          .string()
          .ipWithSubnet(t('IPsecConfigurations.validation.routes.ip'))
          .maxSubnetPrefix(
            32,
            t('IPsecConfigurations.validation.routes.subnet'),
          ),
      )
      .optional(),
    [TemplateConfigurationFieldsNames.IPSecSecondaryRemoteSubnets]: yup
      .array()
      .of(
        yup
          .string()
          .ipWithSubnet(t('IPsecConfigurations.validation.remoteSubnets.ip'))
          .maxSubnetPrefix(
            32,
            t('IPsecConfigurations.validation.remoteSubnets.subnet'),
          ),
      )
      .optional(),
    [TemplateConfigurationFieldsNames.IPSecSecondaryHealthcheckEndpoint]: yup
      .string()
      .ip(t('IPsecConfigurations.validation.healthCheckEndpoint.ip'))
      .optional(),
  });
};

export default useValidationSchema;
