import { FlexRole } from '../features/login/types';
import { Maybe, Nullable } from '../types';

export const roleToDisplayNameTranslation = (role: Maybe<string>) => {
  switch (role) {
    case FlexRole.Admin:
      return 'roleDisplayNames.admin';
    case FlexRole.FlexAdmin:
      return 'roleDisplayNames.flexAdmin';
    case FlexRole.PartnerAdmin:
      return 'roleDisplayNames.partnerAdmin';
    case FlexRole.ReadOnly:
      return 'roleDisplayNames.readOnly';
    default:
      return 'roleDisplayNames.unknown';
  }
};

export const makeTextExcerpt = (
  text: Maybe<string>,
  length: number,
  suffix: string = '…',
) => (text && text.length > length ? text.substring(0, length) + suffix : text);

export const copyToClipboard = async (
  dataStr: Nullable<string>,
  onSuccess?: CallableFunction,
  onError?: CallableFunction,
) => {
  if (!dataStr) {
    return;
  }
  try {
    await navigator.clipboard.writeText(dataStr);
    onSuccess?.();
  } catch (error) {
    onError?.();
  }
};
