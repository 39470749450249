import {
  selectedUserToEditAtom,
  selectedCompanySelector,
  introspectAtom,
} from '../../../state';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { DependencyContainer } from '../../../DependencyContainer';
import { notify } from 'plume-ui';
import { MixPanelEvents } from '../../../mixPanelEvents';
import { useTrackEvent } from '../../trackingAnalytics/hooks/useTrackEvent';
import useWithConfirmation from '../../../hooks/useWithConfirmation';
import { useUsers } from './useUsers';
import { UserTable } from '../types';
import { FlexRole } from '../../login/types';

export type UserAction = {
  onClick: (user: UserTable) => void;
  label: string;
  isVisible: (user: UserTable) => boolean; // determines whether to show action or not
};
const { usersService } = new DependencyContainer();

const useUserActions = (): ((user: UserTable) => UserAction[]) => {
  const { t } = useTranslation();
  const trackEvent = useTrackEvent();
  const setSelectedUser = useSetRecoilState(selectedUserToEditAtom);
  const selectedCompany = useRecoilValue(selectedCompanySelector);
  const withConfirmation = useWithConfirmation();
  const getIntrospect = useRecoilValue(introspectAtom);
  const { runFetch: fetchUsers } = useUsers();
  const onEditUser = (user: UserTable) => {
    const [firstName, lastName] = user.name.split(' ');
    const selectedUser = {
      profile: {
        email: user.email,
        firstName: firstName,
        lastName: lastName,
        role: user.role,
      },
    };
    setSelectedUser(selectedUser);
  };
  const onDeleteUser = async (user: UserTable) => {
    if (!selectedCompany) {
      return;
    }
    try {
      await usersService.deleteUser(selectedCompany.id, user.email);

      notify({
        title: t('success'),
        body: t('deleteUser.successMessage'),
        type: 'success',
      });
      await fetchUsers();
      trackEvent({
        eventName: MixPanelEvents.DELETE_USER_SUCCESS,
        additionalContent: {
          email: user.email,
          companyId: selectedCompany.id,
        },
      });
    } catch (error) {
      trackEvent({
        eventName: MixPanelEvents.DELETE_USER_FAILURE,
        additionalContent: {
          email: user.email,
          companyId: selectedCompany.id,
        },
      });
      notify({
        title: t('error'),
        body: t('deleteUser.failureMessage'),
        type: 'error',
      });
    }
  };

  const resetPasswordUser = async (user: UserTable) => {
    if (!selectedCompany) {
      return;
    }
    try {
      await usersService.resetPassword(selectedCompany.id, user.email);

      notify({
        title: t('success'),
        body: t('resetPassword.successMessage'),
        type: 'success',
      });
      trackEvent({
        eventName: MixPanelEvents.RESET_PASSWORD_USER_SUCCESS,
        additionalContent: {
          email: user.email,
          companyId: selectedCompany.id,
        },
      });
    } catch (error) {
      trackEvent({
        eventName: MixPanelEvents.RESET_PASSWORD_USER_FAILURE,
        additionalContent: {
          email: user.email,
          companyId: selectedCompany.id,
        },
      });
      notify({
        title: t('error'),
        body: t('somethingWentWrong'),
        type: 'error',
      });
    }
  };
  const userActions: UserAction[] = [
    {
      onClick: (user: UserTable) => onEditUser(user),
      label: t('edit'),
      isVisible: () => getIntrospect?.role !== FlexRole.ReadOnly,
    },
    {
      onClick: (user: UserTable) =>
        withConfirmation({
          title: t('deleteUser.pleaseConfirm'),
          body: `${t('deleteUser.content')} ${user.name}`,
          onConfirm: () => onDeleteUser(user),
        }),
      label: t('delete'),
      isVisible: () => getIntrospect?.role !== FlexRole.ReadOnly,
    },
    {
      onClick: (user: UserTable) => resetPasswordUser(user),
      label: t('users.dropdown.resetPassword'),
      isVisible: () => getIntrospect?.role !== FlexRole.ReadOnly,
    },
  ];
  return (user: UserTable) =>
    userActions.filter((action) => action.isVisible(user));
};

export default useUserActions;
