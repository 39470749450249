import React, { FunctionComponent } from 'react';
import {
  Button,
  Icons,
  InputField,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'plume-ui';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import {
  TemplateForOperations,
  templateForOperationsAtom,
} from '../../../state';
import FormattedMessage from '../../../utils/components/FormattedMessage';
import { useState } from 'react';
import { Maybe } from '../../../types';

export type CreateTemplateModalProps = {
  isOpen: boolean;
  onRequestClose: () => void;
  onSubmit: (templateForOperations: TemplateForOperations) => void;
};

const CreateTemplateModal: FunctionComponent<CreateTemplateModalProps> = (
  props,
) => {
  const { t } = useTranslation();
  const [templateForOperations, setTemplateForOperations] = useRecoilState(
    templateForOperationsAtom,
  );
  const [loading, setLoading] = useState<boolean>(false);

  const onSave = async (
    templateForOperations: Maybe<TemplateForOperations>,
  ) => {
    setLoading(true);
    if (!templateForOperations) {
      setLoading(false);
      return;
    }
    await props?.onSubmit(templateForOperations);
    setLoading(false);
    props.onRequestClose?.();
  };

  const onClose = () => {
    setLoading(false);
    setTemplateForOperations(undefined);
    props.onRequestClose?.();
  };

  const onCancel = () => {
    onClose();
  };
  const isDuplicateAction = templateForOperations?.id != null;
  const modalTitleId = isDuplicateAction
    ? 'templates.duplicateTemplateModal.title'
    : 'templates.createTemplateModal.title';
  const modalSubtitleId = isDuplicateAction
    ? 'templates.duplicateTemplateModal.subTitle'
    : 'templates.createTemplateModal.subTitle';
  const modalSubmitButtonTitleId = isDuplicateAction ? 'submit' : 'next';

  return (
    <Modal isOpen={props.isOpen} onRequestClose={onClose}>
      <ModalHeader
        classes={(current) => ({
          ...current,
          root: `${current.root} TemplatesContainer__header`,
          textWrapper: `${current.root} TemplatesContainer__header-text`,
        })}
        title={t(modalTitleId)}
        subtitle={t(modalSubtitleId)}
        closeIcon={<Icons.CrossIcon />}
      />
      <ModalBody
        classes={(current) => ({
          ...current,
          root: `${current.root} TemplatesContainer__body`,
        })}
      >
        <InputField
          label={t('templates.createTemplateModal.label')}
          value={templateForOperations?.name}
          onInput={(e) =>
            setTemplateForOperations((t) => ({
              ...t,
              name: e.currentTarget.value,
            }))
          }
        />
      </ModalBody>
      <ModalFooter>
        <Button
          classes={(current) => ({
            ...current,
            root: `${current.root} TemplatesContainer__cancel-button`,
          })}
          onClick={() => onCancel()}
          styleVariant="tertiary"
        >
          <FormattedMessage id="cancel" />
        </Button>
        <Button
          styleVariant="superprimary"
          disabled={
            loading ||
            Boolean(
              !templateForOperations?.name ||
                templateForOperations?.name.length === 0,
            )
          }
          onClick={() => {
            setLoading(true);
            setTimeout(() => onSave(templateForOperations), 500);
          }}
        >
          <FormattedMessage id={modalSubmitButtonTitleId} />
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CreateTemplateModal;
