import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

export type FormattedMessageProps = {
  id: string;
  params?: Record<string, any>;
};

const FormattedMessage: FunctionComponent<FormattedMessageProps> = ({
  id,
  params = {},
}) => {
  const { t } = useTranslation();
  const message = t(id, params);
  return <>{message}</>;
};

export default FormattedMessage;
