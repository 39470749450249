import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  notify,
  Spinner,
  PendingContent,
} from 'plume-ui';
import { ModalStyles } from 'plume-ui/dist/components/Modal/Modal';
import { Formik, Form, FormikValues, FormikProps } from 'formik';
import yup from '../../../utils/yupInstance';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { DependencyContainer } from '../../../DependencyContainer';
import { useTrackEvent } from '../../trackingAnalytics/hooks/useTrackEvent';
import { MixPanelEvents } from '../../../mixPanelEvents';
import {
  selectedCompanySelector,
  selectedUserSelector,
  UpdateUser,
  usersAtom,
} from '../../../state';
import FormFieldRenderer from '../../configurations/FormFieldRenderer';
import useUserFormFields from '../../users/hooks/useUserFormFields';
import { FormFieldDefinition } from '../../../types';
import FormattedMessage from '../../../utils/components/FormattedMessage';
import { UserRoles, CreateUserDto } from '../../users/types';
import { getFieldKey } from '../../../helpers/fields';

type CreateUserModalProps = {
  isOpen: boolean;
  onRequestClose: (success: boolean) => void;
  onFinish?: () => void;
};
const { usersService } = new DependencyContainer();
export type CreateUserFormValues = {
  email: string;
  firstName: string;
  lastName: string;
};

export enum UserFormFields {
  Email = 'email',
  FirstName = 'firstName',
  LastName = 'lastName',
}

const CreateUserModal: FunctionComponent<CreateUserModalProps> = ({
  isOpen,
  onRequestClose,
  onFinish,
}) => {
  const { t } = useTranslation();
  const trackEvent = useTrackEvent();
  const selectedCompany = useRecoilValue(selectedCompanySelector);
  const [loading, setLoading] = useState<boolean>(false);
  const selectedUser = useRecoilValue(selectedUserSelector);
  const setUsers = useSetRecoilState(usersAtom);
  const onClose = (success: boolean) => {
    onRequestClose(success);
  };

  const getFormInitialValues = (): CreateUserFormValues => {
    if (selectedUser?.profile) {
      return {
        [UserFormFields.FirstName]: selectedUser?.profile.firstName,
        [UserFormFields.LastName]: selectedUser?.profile.lastName,
        [UserFormFields.Email]: selectedUser?.profile.email,
      };
    }
    return {
      [UserFormFields.FirstName]: '',
      [UserFormFields.LastName]: '',
      [UserFormFields.Email]: '',
    };
  };

  const onSubmit = async (values: FormikValues) => {
    const dto = {
      profile: {
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        role: UserRoles.FlexAdmin,
      },
    };
    if (!selectedUser) {
      await onCreate(values, dto);
    } else {
      await onEdit(values, dto);
    }
  };
  const onCreate = async (values: FormikValues, dto: CreateUserDto) => {
    if (!selectedCompany) {
      return;
    }
    setLoading(true);
    try {
      await usersService.createUser(dto, selectedCompany.id);
      notify({
        title: t('notifications.success'),
        body: t('addUser.successMessage'),
        type: 'success',
      });
      onFinish?.();
      onClose(false);
      trackEvent({
        eventName: MixPanelEvents.ADD_USER_SUCCESS,
        additionalContent: {
          email: values.email,
          companyId: selectedCompany.id,
        },
      });
    } catch (error) {
      if (error.response) {
        notify({
          title: t('notifications.failure'),
          body: error.response.data?.message?.toString(),
          type: 'error',
        });
      }
      trackEvent({
        eventName: MixPanelEvents.ADD_USER_FAILURE,
        additionalContent: {
          email: values.email,
          companyId: selectedCompany.id,
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const onEdit = async (values: FormikValues, dto: CreateUserDto) => {
    if (!selectedUser || !selectedCompany || !selectedUser.profile) {
      return;
    }
    setLoading(true);
    try {
      const updatedUser = await usersService.updateUser(
        dto,
        selectedCompany.id,
        selectedUser.profile.email,
      );
      updateUserList(updatedUser);
      notify({
        title: t('success'),
        body: t('editUser.successMessage'),
        type: 'success',
      });
      trackEvent({
        eventName: MixPanelEvents.EDIT_USER_SUCCESS,
        additionalContent: {
          email: values.email,
          companyId: selectedCompany.id,
        },
      });
      onFinish?.();
      onClose(false);
    } catch (error) {
      trackEvent({
        eventName: MixPanelEvents.EDIT_USER_FAILURE,
        additionalContent: {
          email: values.email,
          companyId: selectedCompany.id,
        },
      });
      const message = error?.response?.data?.message;
      notify({
        title: t('notifications.failure'),
        body: message,
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const updateUserList = (user: UpdateUser) => {
    setUsers((currentUsers) =>
      currentUsers.map((u) =>
        u.profile?.email !== user.profile.email ? u : (user as UpdateUser),
      ),
    );
  };
  const getValidationSchema = () =>
    yup.object().shape({
      email: yup
        .string()
        .email(t('addUser.form.email.valid'))
        .max(100, t('addUser.form.email.message'))
        .required(t('addUser.form.email.required')),
      firstName: yup
        .string()
        .max(50, t('addUser.form.firstName.message'))
        .required(t('addUser.form.firstName.required')),
      lastName: yup
        .string()
        .max(50, t('addUser.form.lastName.message'))
        .required(t('addUser.form.lastName.required')),
    });
  const fieldsDefinitions = useUserFormFields();

  const isSubmitButtonDisabled = (formik: FormikProps<FormikValues>) => {
    let countFilledFields = 0;
    if (!selectedUser) {
      fieldsDefinitions.forEach((element) => {
        if (element.type !== 'spacer' && Boolean(formik.values[element.name])) {
          ++countFilledFields;
        }
      });
      const hasAllFieldsFilled = countFilledFields < fieldsDefinitions.length;
      return hasAllFieldsFilled;
    }

    return false;
  };

  return (
    <Modal
      isOpen={isOpen}
      solidBackground
      scrollable={false}
      onRequestClose={() => onClose(false)}
      classes={(current: ModalStyles) => ({
        ...current,
        root: `${current.root} CreateUserModal`,
      })}
    >
      <ModalHeader title={t('addUser.userInformation')} />
      <Formik
        initialValues={getFormInitialValues()}
        validationSchema={getValidationSchema()}
        validateOnChange={false}
        onSubmit={onSubmit}
      >
        {(formik: FormikProps<FormikValues>) => (
          <Form>
            <PendingContent loading={loading} transparent loader={Spinner}>
              <ModalBody>
                <div className="CreateUserModal__form">
                  {fieldsDefinitions.map(
                    (
                      fieldsDefinition: FormFieldDefinition<UserFormFields>,
                      index: number,
                    ) => (
                      <FormFieldRenderer
                        key={getFieldKey(fieldsDefinition, index)}
                        labelsAsPlaceholders
                        fieldDefinition={fieldsDefinition}
                      />
                    ),
                  )}
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  classes={(current) => ({
                    ...current,
                    root: `${current.root} CreateUserModal__cancel-button`,
                  })}
                  onClick={() => onClose(false)}
                  styleVariant="tertiary"
                >
                  <FormattedMessage id="cancel" />
                </Button>
                <Button
                  disabled={isSubmitButtonDisabled(formik) || loading}
                  type="submit"
                  styleVariant="superprimary"
                >
                  <FormattedMessage id="save" />
                </Button>
              </ModalFooter>
            </PendingContent>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
export default CreateUserModal;
