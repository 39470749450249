import { useRecoilValue } from 'recoil';
import { introspectAtom } from '../../../state';
import { FlexRole } from '../types';

export const useRole = () => {
  const introspect = useRecoilValue(introspectAtom);

  const hasRole = (role: FlexRole): boolean => {
    if (!introspect) {
      return false;
    }
    return introspect.role === role;
  };

  const runForRole = (role: FlexRole, callback: CallableFunction) => {
    if (!introspect) {
      return;
    }
    if (introspect.role !== role) {
      return;
    }
    callback();
  };

  return { hasRole, runForRole };
};
