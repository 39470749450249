import { TemplateWithoutId } from '../templates/types';
import { Nullable } from '../../types';

export type IndividualPolicy = {
  id: string;
  status: IndividualPolicyStatus;
  employeeId: string;
  employeeEmail: string;
  template: string;
  flexPolicy: FlexPolicy;
};

export type FlexPolicy = {
  id: string;
  name: string;
};

export type IndividualPolicyTable = {
  id: string;
  employeeId: string;
  externalId: string;
  templateId: string;
  template: string;
  enable: boolean;
  flexPolicy: FlexPolicy;
  locationId: string;
  status: string;
};

export enum IndividualPolicyStatus {
  Suspended = 'location_assigned_policy_disabled',
  Active = 'location_assigned_policy_enabled',
  Unassigned = 'location_unassigned_policy_disabled',
  Pending = 'location_unassigned_policy_enabled',
}

export type IndividualPolicyResponseItem = {
  id: string;
  employeeId: string;
  externalId: string;
  enable: boolean;
  wifi: WiFi;
  primaryTunnel: Tunnel;
  secondaryTunnel: Tunnel;
  network: Network;
  employeePolicyId: string;
  flexPolicy: FlexPolicy;
  locationId: string;
  status: IndividualPolicyStatus;
};

export type WiFiRadius = {
  ip: string;
  secret: string;
  port: number;
};

export type TunnelIPSec = {
  remoteEndpointId: string;
  remoteSubnets: string[];
  lifetime: number;
  ikeLifetime: number;
  psk: string;
  localEndpointId: string;
  ikeEncSuite: string[];
  ikeAuthSuite: string[];
  ikeDhGroups: string[];
  espEncSuite: string[];
  espAuthSuite: string[];
  espDhGroups: string[];
};

export type Tunnel = {
  remoteEndpoint: string;
  routes: string[];
  healthCheck: {
    remoteIp: string;
    interval: number;
    timeout: number;
  };
  tunnelIp: string;
  ipSec: TunnelIPSec;
};

export type NetworkOption = {
  id: number;
  value: string;
};

export type Network = {
  dns: string[];
  domainName: string;
  classlessStaticRoutes: string[];
  webProxyAutoDiscovery: string;
  options: NetworkOption[];
  subnet: string;
};

export enum WPAModes {
  Auto = 'WPA_MODE_AUTO',
}

export type WiFi = {
  ssid: string;
  wpaMode: WPAModes;
  fastTransition: boolean;
  primaryRadius: WiFiRadius;
  secondaryRadiuses: WiFiRadius[];
  primaryAccounting: WiFiRadius;
  secondaryAccountings: WiFiRadius[];
};

export type IndividualPoliciesTableRecordItem = Pick<
  IndividualPolicyResponseItem,
  'id' | 'employeeId' | 'enable' | 'externalId' | 'status' | 'locationId'
> & {
  template: string;
  templateId: string;
};

export type CreateIndividualPolicyDto = {
  employeeId: string;
  externalId: string;
  ipv4LanSubnet: string;
  ipSecPSKSecondary: string;
  ipSecPSKPrimary: string;
  localIdPrimary: string;
  localIdSecondary: string;
  localTunnelIpPrimary: string;
  localTunnelIpSecondary: string;
  remoteTunnelIpPrimary: string;
  remoteTunnelIpSecondary: string;
  enable: boolean;
};

export type CreateIndividualPolicyPayload = TemplateWithoutId & {
  employeeId: string;
  externalId: string;
};
export type UpdateIndividualPolicyPayload = CreateIndividualPolicyPayload;

export type BulkUploadsItem = {
  id: string;
  createdAt: string;
  status: BulkUploadStatus;
  completedAt: string;
  rowCount: number;
  failedRowCount: number;
  successRowCount: number;
  errors: Nullable<any>;
};

export enum BulkUploadStatus {
  Wait = 'wait',
  Active = 'active',
  Failed = 'failed',
  Completed = 'completed',
}

export type SortablePoliciesFieldNames = 'employeeId' | 'externalId';
