import { createContext, Dispatch, SetStateAction } from 'react';
import { TimeLinePeriodTypes } from '../types';
import { Maybe } from '../../../types';
import { mapPeriodToLabelId } from '../hooks/useTimeLine';

export type ChartPeriodContextValues = {
  selectedPeriodType: TimeLinePeriodTypes;
  setSelectedPeriodType: Dispatch<SetStateAction<TimeLinePeriodTypes>>;
  selectedMonth: Maybe<string>;
  selectedPeriodLabel: string;
  setSelectedMonth: Dispatch<SetStateAction<Maybe<string>>>;
};

export const ChartPeriodContext = createContext<ChartPeriodContextValues>({
  selectedPeriodType: TimeLinePeriodTypes.Last24Hours,
  setSelectedPeriodType: () => {},
  selectedPeriodLabel:
    mapPeriodToLabelId[TimeLinePeriodTypes.Last24Hours] || '',
  selectedMonth: undefined,
  setSelectedMonth: () => {},
});
