import { DependencyContainer } from '../../../DependencyContainer';
import {
  BulkUploadsItem,
  CreateIndividualPolicyDto,
  CreateIndividualPolicyPayload,
  IndividualPolicy,
  IndividualPolicyResponseItem,
} from '../types';
import { FlexApiResponse, Maybe } from '../../../types';
import {
  uploadModalMaxRecentUploads,
  defaultLimitForDropdown,
} from '../../../config';
import { removeEmpty } from '../../../helpers/objects';
import moment from 'moment';
import { TemplateWithoutId } from '../../templates/types';

export default class IndividualPoliciesService {
  constructor(private readonly factory: DependencyContainer) {}

  async getIndividualPolicies(
    companyId: string,
    page: number = 1,
    phrase?: string,
  ): Promise<FlexApiResponse<IndividualPolicyResponseItem[]>> {
    if (page < 1) {
      throw new Error('Page must be greater than zero');
    }
    const response = await this.factory.individualPoliciesClient.fetchIndividualPolicies(
      companyId,
      page,
      defaultLimitForDropdown,
      phrase,
    );
    return response.data;
  }

  async getIndividualPolicy(
    policyId: string,
    templateId: string,
    companyId: string,
  ): Promise<IndividualPolicyResponseItem> {
    const response = await this.factory.individualPoliciesClient.fetchIndividualPolicy(
      policyId,
      templateId,
      companyId,
    );
    return response.data;
  }

  async deleteIndividualPolicy(
    companyId: string,
    templateId: string,
    policyId: string,
  ): Promise<void> {
    await this.factory.individualPoliciesClient.deleteIndividualPolicy(
      companyId,
      templateId,
      policyId,
    );
  }

  private prepareIndividualPolicyDto(
    dto: CreateIndividualPolicyDto,
    selectedTemplate?: Maybe<TemplateWithoutId>,
  ): CreateIndividualPolicyPayload {
    const secondaryTunnel = selectedTemplate?.secondaryTunnel
      ? {
          tunnelIp: dto.localTunnelIpSecondary || undefined,
          ipSec: {
            localEndpointId: dto.localIdSecondary || undefined,
            psk: dto.ipSecPSKSecondary || undefined,
          },
          healthCheck: {
            remoteIp: dto.remoteTunnelIpSecondary || undefined,
          },
        }
      : null;
    return removeEmpty({
      enable: dto.enable,
      employeeId: dto.employeeId,
      externalId: dto.externalId,
      primaryTunnel: {
        tunnelIp: dto.localTunnelIpPrimary || undefined,
        ipSec: {
          localEndpointId: dto.localIdPrimary || undefined,
          psk: dto.ipSecPSKPrimary || undefined,
        },
        healthCheck: {
          remoteIp: dto.remoteTunnelIpPrimary || undefined,
        },
      },
      secondaryTunnel,
      network: {
        subnet: dto.ipv4LanSubnet || undefined,
      },
    });
  }

  async createIndividualPolicy(
    companyId: string,
    templateId: string,
    createIndividualPolicyDto: CreateIndividualPolicyDto,
    selectedTemplate: Maybe<TemplateWithoutId>,
  ): Promise<IndividualPolicy> {
    const template = await this.factory.templatesService.getTemplate(
      templateId,
      companyId,
    );
    if (!template) {
      throw new Error('Unable to fetch selected template.');
    }
    const finalDto = this.prepareIndividualPolicyDto(
      createIndividualPolicyDto,
      selectedTemplate,
    );
    const response = await this.factory.individualPoliciesClient.createIndividualPolicies(
      companyId,
      templateId,
      finalDto,
    );
    return response.data.items;
  }

  async updateIndividualPolicy(
    policyId: string,
    templateId: string,
    companyId: string,
    updateIndividualPolicyDto: CreateIndividualPolicyDto,
    selectedTemplate: Maybe<TemplateWithoutId>,
  ): Promise<IndividualPolicy> {
    const template = await this.factory.templatesService.getTemplate(
      templateId,
      companyId,
    );
    if (!template) {
      throw new Error('Unable to fetch selected template.');
    }
    const finalDto = this.prepareIndividualPolicyDto(
      updateIndividualPolicyDto,
      selectedTemplate,
    );
    const response = await this.factory.individualPoliciesClient.updateIndividualPolicy(
      policyId,
      companyId,
      templateId,
      finalDto,
    );
    return response.data.items;
  }

  async uploadFile(companyId: string, file: File) {
    return await this.factory.individualPoliciesClient.uploadFile(
      companyId,
      file,
    );
  }

  async getUploads(companyId: string): Promise<BulkUploadsItem[]> {
    const response = await this.factory.individualPoliciesClient.fetchUploads(
      companyId,
    );
    return response.data.items
      .map((item) => this.parseUploadItem(item))
      .splice(0, uploadModalMaxRecentUploads);
  }

  async getUploadById(
    uploadId: string,
    companyId: string,
  ): Promise<BulkUploadsItem> {
    const response = await this.factory.individualPoliciesClient.fetchUploadById(
      uploadId,
      companyId,
    );
    return this.parseUploadItem(response.data);
  }

  async fetchFailedRowsUrl(uploadId: string, companyId: string): Promise<any> {
    const response = await this.factory.individualPoliciesClient.fetchFailedRowsUrl(
      uploadId,
      companyId,
    );
    return response.data;
  }

  private parseUploadItem(entry: BulkUploadsItem): BulkUploadsItem {
    return {
      ...entry,
      createdAt: moment(entry.createdAt).format('HH:mm a M/D/Y'),
    };
  }
}
