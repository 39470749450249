import React, { FunctionComponent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Button,
  Dropdown,
  DropdownItem,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Icons,
  Table,
  Tabs,
  Tooltip,
  PendingContent,
  Spinner,
} from 'plume-ui';
import { useTranslation } from 'react-i18next';
import { useTrackEvent } from '../../trackingAnalytics/hooks/useTrackEvent';
import { MixPanelEvents } from '../../../mixPanelEvents';
import { AvailableScreens } from '../../trackingAnalytics/types';
import CreateUserModal from '../../components/CreateUserModal/CreateUserModal';
import { useUsers } from '../hooks/useUsers';
import useUserActions, { UserAction } from '../hooks/useUserActions';
import { useRecoilValue, useRecoilState } from 'recoil';
import {
  User,
  introspectAtom,
  selectedUserToEditAtom,
  UserTable,
} from '../../../state';
import { FlexRole } from '../../login/types';
import {
  makeTextExcerpt,
  roleToDisplayNameTranslation,
} from '../../../helpers/text';

const UsersContainer: FunctionComponent = () => {
  const { t } = useTranslation();
  const trackEvent = useTrackEvent();
  const [userModalOpen, setUserModalOpen] = useState(false);
  const getIntrospect = useRecoilValue(introspectAtom);
  const {
    loading,
    error,
    users,
    runFetch: fetchUsers,
    isFetchComplete,
  } = useUsers();
  const getUserActions = useUserActions();
  const [selectedUserToEdit, setSelectedUserToEdit] = useRecoilState(
    selectedUserToEditAtom,
  );

  const recoveryStatuses = [
    'RECOVERY',
    'PASSWORD_EXPIRED',
    'LOCKED_OUT',
    'SUSPENDED',
    'DEPROVISIONED',
    'DEACTIVATED',
  ];

  useEffect(() => {
    trackEvent({
      eventName: MixPanelEvents.SCREEN,
      additionalContent: {
        SCREEN: AvailableScreens.Users,
      },
    });
  }, []);

  const tableHeaderRows = [
    { name: t('users.userName'), fieldName: 'name' },
    { name: t('users.userEmail'), fieldName: 'email' },
    { name: t('users.userRole'), fieldName: 'role' },
    {
      name: '',
      fieldName: 'actions',
      render: (user: UserTable) => {
        const userActions = getUserActions(user);
        if (userActions.length === 0) {
          return null;
        }
        return (
          <Dropdown
            listPosition="right"
            closeOnItemClick
            button={
              <IconButton>
                <Icons.DotsVerticalIcon />
              </IconButton>
            }
          >
            {userActions.map((action: UserAction) => (
              <DropdownItem
                key={action.label}
                onClick={() => setTimeout(() => action.onClick(user))}
              >
                {action.label}
              </DropdownItem>
            ))}
          </Dropdown>
        );
      },
    },
  ];

  const activeUsersDataRows =
    users
      ?.filter((u: User) => u.status === 'ACTIVE')
      .map((u: User) => ({
        name: makeTextExcerpt(
          u.profile?.firstName + ' ' + u.profile?.lastName,
          24,
        ),
        email: makeTextExcerpt(u.profile?.email, 32),
        role: t(roleToDisplayNameTranslation(u.profile?.role)),
      })) || [];

  const recoveryUsersDataRows =
    users
      ?.filter((u: User) => u.status && recoveryStatuses.includes(u.status))
      .map((u: User) => ({
        name: makeTextExcerpt(
          u.profile?.firstName + ' ' + u.profile?.lastName,
          24,
        ),
        email: makeTextExcerpt(u.profile?.email, 32),
        role: t(roleToDisplayNameTranslation(u.profile?.role)),
      })) || [];

  const invitedUsersDataRows =
    users
      ?.filter((u: User) => u.status === 'PROVISIONED')
      .map((u: User) => ({
        name: makeTextExcerpt(
          u.profile?.firstName + ' ' + u.profile?.lastName,
          24,
        ),
        email: makeTextExcerpt(u.profile?.email, 32),
        role: t(roleToDisplayNameTranslation(u.profile?.role)),
      })) || [];

  const ActiveUsers: FunctionComponent = () => {
    return (
      <>
        <Grid>
          <GridItem colSpan="12">
            <Table
              headerRow={tableHeaderRows as any}
              dataRows={[...activeUsersDataRows] as any}
              truncateCellContent={false}
            />
          </GridItem>
        </Grid>
        <PendingContent
          loading={loading}
          loader={Spinner}
          isError={error}
          transparent
        >
          {isFetchComplete && (
            <>
              {!activeUsersDataRows ||
                (activeUsersDataRows.length === 0 && (
                  <p className="UsersContainer__no-data">
                    {t('users.noactiveusers')}
                  </p>
                ))}
            </>
          )}
        </PendingContent>
      </>
    );
  };

  const InvitedUsers: FunctionComponent = () => {
    return (
      <>
        <Grid>
          <GridItem colSpan="12">
            <Table
              headerRow={tableHeaderRows as any}
              dataRows={[...invitedUsersDataRows] as any}
              truncateCellContent={false}
            />
          </GridItem>
        </Grid>
        <PendingContent
          loading={loading}
          loader={Spinner}
          isError={error}
          transparent
        >
          {isFetchComplete && (
            <>
              {!invitedUsersDataRows ||
                (invitedUsersDataRows.length === 0 && (
                  <p className="UsersContainer__no-data">
                    {t('users.noinvitedeusers')}
                  </p>
                ))}
            </>
          )}
        </PendingContent>
      </>
    );
  };

  const RecoveryUsers: FunctionComponent = () => {
    return (
      <>
        <Grid>
          <GridItem colSpan="12">
            <Table
              headerRow={tableHeaderRows as any}
              dataRows={[...recoveryUsersDataRows] as any}
              truncateCellContent={false}
            />
          </GridItem>
        </Grid>
        <PendingContent
          loading={loading}
          loader={Spinner}
          isError={error}
          transparent
        >
          {isFetchComplete && (
            <>
              {!recoveryUsersDataRows ||
                (recoveryUsersDataRows.length === 0 && (
                  <p className="UsersContainer__no-data">
                    {t('users.norecoveryeusers')}
                  </p>
                ))}
            </>
          )}
        </PendingContent>
      </>
    );
  };

  const userTabs = [
    {
      id: 'active',
      label: t('users.active'),
      content: <ActiveUsers />,
    },
    {
      id: 'invited',
      label: t('users.invited'),
      content: <InvitedUsers />,
    },
    {
      id: 'recovery',
      label: t('users.recovery'),
      content: <RecoveryUsers />,
    },
  ];

  return (
    <>
      <Helmet>
        <title>{t('users.title')}</title>
      </Helmet>
      <div className="UsersContainer p-xl">
        <Grid>
          <GridItem colSpan="12">
            <Heading level={1} ocrey>
              {t('users.title')}
              <Tooltip
                label={t('users.tooltip')}
                position="above"
                alignment="left"
              >
                <Icons.InfoIcon width={20} />
              </Tooltip>
            </Heading>
          </GridItem>
          <GridItem colSpan="12">
            <div className="UsersContainer__tabContainer">
              <Tabs
                options={userTabs}
                tabType={'flatTabs'}
                classes={(current) => ({
                  ...current,
                  root: `${current.root} UsersContainer__tabs`,
                })}
              />
              <Button
                styleVariant="superprimary"
                onClick={() => setUserModalOpen(true)}
                disabled={getIntrospect?.role === FlexRole.ReadOnly}
              >
                {t('users.createUserButton')}
              </Button>
            </div>
          </GridItem>
        </Grid>
        <CreateUserModal
          isOpen={userModalOpen || !!selectedUserToEdit}
          onFinish={fetchUsers}
          onRequestClose={() => {
            setUserModalOpen(false);
            if (selectedUserToEdit) {
              setSelectedUserToEdit(null);
            }
          }}
        />
        {/* <EditUserModal
          isOpen={!!selectedUserToEdit }
          onRequestClose={() => setSelectedUserToEdit(null)}
        /> */}
      </div>
    </>
  );
};

export default UsersContainer;
