import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  notify,
  Grid,
  GridItem,
  Heading,
  PendingContent,
  Spinner,
  StaticCard,
  Icons,
  Dropdown,
  DropdownItem,
  IconButton,
} from 'plume-ui';
import { Formik, Form, FormikValues, FormikProps } from 'formik';
import yup from '../../../utils/yupInstance';
import { useRecoilValue } from 'recoil';
import { DependencyContainer } from '../../../DependencyContainer';
import { useTrackEvent } from '../../trackingAnalytics/hooks/useTrackEvent';
import { MixPanelEvents } from '../../../mixPanelEvents';
import { selectedCompanySelector } from '../../../state';
import FormFieldRenderer, {
  FormFieldRenderStyles,
} from '../../configurations/FormFieldRenderer';
import { FormFieldDefinition } from '../../../types';
import FormattedMessage from '../../../utils/components/FormattedMessage';
import useCustomerSupportFormFields from '../hooks/useCustomerSupportFormFields';
import { Helmet } from 'react-helmet';
import { CreateCustomerSupportDto, CustomerSupportDetails } from '../types';
import { AvailableScreens } from '../../trackingAnalytics/types';
import { useFetchData } from '../../../hooks/useFetchData';
import useCopyCompanyId from '../../companies/hooks/useCopyCompanyId';
import { getFieldKey } from '../../../helpers/fields';
import { Company } from '../../companies/types';
import { AxiosError } from 'axios';

const { customerSupportService } = new DependencyContainer();

export type CreateCustomerSupportFormValues = {
  termsLink: string;
  privacyLink: string;
  faqLink: string;
  contactUsEmail: string;
  callUsNumber: string;
  chatSupportUrl: string;
  manageYourDataUrl: string;
};

export enum CreateCustomerSupportFormFields {
  ContactUsEmail = 'contactUsEmail',
  CallUsNumber = 'callUsNumber',
  ChatSupportUrl = 'chatSupportUrl',
  ManageYourDataUrl = 'manageYourDataUrl',
  FaqLink = 'faqLink',
  PrivacyLink = 'privacyLink',
  TermsLink = 'termsLink',
}

const CustomerSupportContainer: FunctionComponent = () => {
  const { t } = useTranslation();
  const trackEvent = useTrackEvent();
  const selectedCompany = useRecoilValue(selectedCompanySelector);
  useEffect(() => {
    trackEvent({
      eventName: MixPanelEvents.SCREEN,
      additionalContent: {
        SCREEN: AvailableScreens.CustomerSupportDetail,
      },
    });
  }, []);

  const [loadingCustomer, details, , runFetch] = useFetchData<
    CustomerSupportDetails & Company,
    any
  >(
    async () => {
      if (!selectedCompany) {
        return;
      }

      const companyDetails = await customerSupportService.getCompanyDetailsFlexAdminOnly(
        selectedCompany.id,
      );

      const customerSupportDetailsBasic: CustomerSupportDetails = {
        termsLink: '',
        privacyLink: '',
        faqLink: '',
        contactUsEnabled: false,
        contactUsEmail: '',
        callUsEnabled: false,
        callUsNumber: '',
        chatSupportEnabled: false,
        chatSupportUrl: '',
        manageYourDataEnabled: false,
        manageYourDataUrl: '',
      };
      let combinedData: CustomerSupportDetails & Company;

      try {
        const customerSupportDetails = await customerSupportService.getCustomerSupportDetail(
          selectedCompany.id,
        );

        combinedData = { ...customerSupportDetails, ...companyDetails };
      } catch (error) {
        const mappedError = error as AxiosError;
        if (mappedError?.response && mappedError?.response?.status === 404) {
          console.log('Customer support details not found.');
          combinedData = {
            ...customerSupportDetailsBasic,
            ...companyDetails,
          };
        } else {
          throw error;
        }
      }

      return combinedData;
    },
    undefined,
    [selectedCompany],
    () => selectedCompany === undefined,
  );

  const getFormInitialValues = (): CreateCustomerSupportFormValues => {
    if (details) {
      return {
        [CreateCustomerSupportFormFields.ContactUsEmail]:
          details.contactUsEmail,
        [CreateCustomerSupportFormFields.CallUsNumber]: details.callUsNumber,
        [CreateCustomerSupportFormFields.ChatSupportUrl]:
          details.chatSupportUrl,
        [CreateCustomerSupportFormFields.ManageYourDataUrl]:
          details.manageYourDataUrl,
        [CreateCustomerSupportFormFields.FaqLink]: details.faqLink,
        [CreateCustomerSupportFormFields.PrivacyLink]: details.privacyLink,
        [CreateCustomerSupportFormFields.TermsLink]: details.termsLink,
      };
    }
    return {
      [CreateCustomerSupportFormFields.ContactUsEmail]: '',
      [CreateCustomerSupportFormFields.CallUsNumber]: '',
      [CreateCustomerSupportFormFields.ChatSupportUrl]: '',
      [CreateCustomerSupportFormFields.ManageYourDataUrl]: '',
      [CreateCustomerSupportFormFields.FaqLink]: '',
      [CreateCustomerSupportFormFields.PrivacyLink]: '',
      [CreateCustomerSupportFormFields.TermsLink]: '',
    };
  };
  const prepareCustomerSupportDto = (
    values: CreateCustomerSupportFormValues,
  ): CreateCustomerSupportDto => {
    return {
      contactUsEmail: values[CreateCustomerSupportFormFields.ContactUsEmail],
      callUsNumber: values[CreateCustomerSupportFormFields.CallUsNumber],
      chatSupportUrl: values[CreateCustomerSupportFormFields.ChatSupportUrl],
      manageYourDataUrl:
        values[CreateCustomerSupportFormFields.ManageYourDataUrl],
      contactUsEnabled: !values[CreateCustomerSupportFormFields.ContactUsEmail]
        ? false
        : true,
      callUsEnabled: !values[CreateCustomerSupportFormFields.CallUsNumber]
        ? false
        : true,
      chatSupportEnabled: !values[
        CreateCustomerSupportFormFields.ChatSupportUrl
      ]
        ? false
        : true,
      manageYourDataEnabled: !values[
        CreateCustomerSupportFormFields.ManageYourDataUrl
      ]
        ? false
        : true,
      faqLink: values[CreateCustomerSupportFormFields.FaqLink],
      privacyLink: values[CreateCustomerSupportFormFields.PrivacyLink],
      termsLink: values[CreateCustomerSupportFormFields.TermsLink],
    };
  };

  const applyStylesToFormFieldRenderer = (current: FormFieldRenderStyles) => ({
    ...current,
    root: `${current.root} CustomerSupportContainer__form-row`,
    value: `${current.value} CustomerSupportContainer__form-row-value`,
  });

  const onSubmit = async (values: CreateCustomerSupportFormValues) => {
    const dto = prepareCustomerSupportDto(values);
    if (!selectedCompany) {
      return;
    }
    try {
      await customerSupportService.createCustomerSupportDetail(
        dto,
        selectedCompany.id,
      );
      notify({
        title: t('notifications.success'),
        body: t('customerSupport.successMessage'),
        type: 'success',
      });
      runFetch();
      trackEvent({
        eventName: MixPanelEvents.ADD_CUSTOMER_SUPPORT_SUCCESS,
        additionalContent: {
          companyId: selectedCompany.id,
        },
      });
    } catch (error) {
      const mappedError = error as AxiosError;
      if (mappedError?.response) {
        notify({
          title: t('notifications.failure'),
          body: mappedError?.response?.data?.message?.toString(),
          type: 'error',
        });
      }
      trackEvent({
        eventName: MixPanelEvents.ADD_CUSTOMER_SUPPORT_FAILURE,
        additionalContent: {
          companyId: selectedCompany.id,
        },
      });
    }
  };

  const getValidationSchema = () =>
    yup.object().shape({
      [CreateCustomerSupportFormFields.ContactUsEmail]: yup
        .string()
        .email()
        .max(100, t('customerSupport.form.contactUsEmail.message'))
        .optional(),
      [CreateCustomerSupportFormFields.CallUsNumber]: yup
        .string()
        .max(50, t('customerSupport.form.callUsNumber.message'))
        .optional(),
      [CreateCustomerSupportFormFields.ChatSupportUrl]: yup
        .string()
        .url(t('customerSupport.form.chatSupportUrl.message'))
        .optional(),
      [CreateCustomerSupportFormFields.ManageYourDataUrl]: yup
        .string()
        .url(t('customerSupport.form.manageYourDataUrl.message'))
        .optional(),
      [CreateCustomerSupportFormFields.FaqLink]: yup
        .string()
        .url(t('customerSupport.form.faqLink.message'))
        .optional(),
      [CreateCustomerSupportFormFields.PrivacyLink]: yup
        .string()
        .url(t('customerSupport.form.privacyLink.message'))
        .optional(),
      [CreateCustomerSupportFormFields.TermsLink]: yup
        .string()
        .url(t('customerSupport.form.termsLink.message'))
        .optional(),
    });
  const fieldsDefinitions = useCustomerSupportFormFields();
  const onCopyCompanyId = useCopyCompanyId<any>();
  return (
    <>
      <Helmet>
        <title>{t('customerSupport.title')}</title>
      </Helmet>
      <div className="CustomerSupportContainer p-xl">
        <Grid>
          <GridItem colSpan="12" tabletColSpan="6">
            <div className="CustomerSupportContainer__headerContainer">
              <Heading level={1} ocrey>
                <FormattedMessage id="customerSupport.title" />
              </Heading>
            </div>
          </GridItem>
        </Grid>

        <Formik
          initialValues={getFormInitialValues()}
          validationSchema={getValidationSchema()}
          validateOnChange={false}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {(formik: FormikProps<FormikValues>) => (
            <Form>
              <PendingContent
                loading={loadingCustomer}
                loader={Spinner}
                transparent
              >
                <div className="CustomerSupportContainer__save">
                  <Button type="submit" styleVariant="superprimary">
                    <FormattedMessage id="save" />
                  </Button>
                </div>

                <div className="CustomerSupportContainer__form">
                  {fieldsDefinitions.map(
                    (
                      fieldsDefinition: FormFieldDefinition<
                        CreateCustomerSupportFormFields
                      >,
                      index: number,
                    ) => (
                      <FormFieldRenderer
                        key={getFieldKey(fieldsDefinition, index)}
                        labelsAsPlaceholders
                        fieldDefinition={fieldsDefinition}
                        classes={applyStylesToFormFieldRenderer}
                      />
                    ),
                  )}
                </div>
              </PendingContent>
            </Form>
          )}
        </Formik>
        <Grid>
          <GridItem colSpan="12" tabletColSpan="6">
            <div className="CustomerSupportContainer__info">
              <StaticCard
                title={
                  <FormattedMessage id="customerSupport.information.title" />
                }
                actions={[
                  <Dropdown
                    listPosition="right"
                    closeOnItemClick
                    button={
                      <IconButton>
                        <Icons.DotsVerticalIcon width={24} />
                      </IconButton>
                    }
                  >
                    <DropdownItem
                      onClick={() => onCopyCompanyId(selectedCompany)}
                    >
                      <FormattedMessage id="copyCompanyId" />
                    </DropdownItem>
                  </Dropdown>,
                ]}
              >
                <p>
                  <div className="CustomerSupportContainer__info-title">
                    <FormattedMessage id="customerSupport.information.legalName" />
                  </div>
                  {details?.legalName}
                </p>
                <p>
                  <div className="CustomerSupportContainer__info-title">
                    <FormattedMessage id="customerSupport.information.businessName" />
                  </div>
                  {details?.businessName}
                </p>
                <p>
                  <div className="CustomerSupportContainer__info-title">
                    <FormattedMessage id="customerSupport.information.legalAddress" />
                  </div>
                  {details?.legalAddress}
                </p>
                <p>
                  <div className="CustomerSupportContainer__info-title">
                    <FormattedMessage id="customerSupport.information.country" />
                  </div>
                  {details?.country}
                </p>
              </StaticCard>
            </div>
          </GridItem>
        </Grid>
      </div>
    </>
  );
};
export default CustomerSupportContainer;
