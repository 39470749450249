import { AxiosResponse } from 'axios';
import { FlexApiUrls } from '../../../urls';
import { HttpClient } from '../../../httpClient';
import { Introspect } from '../../../state';

export default class IntrospectClient extends HttpClient {
  async fetchIntrospect(): Promise<Introspect> {
    const response = await this.get<AxiosResponse<Introspect>>(
      FlexApiUrls.getIntrospectUrl(),
    );
    return response.data;
  }
}
