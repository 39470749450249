import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ChainedBackend from 'i18next-chained-backend';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';
import { TRANSLATION_FILE_PATH_TEMPLATE } from '../config';

i18n
  .use(LanguageDetector)
  .use(ChainedBackend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: false,
    react: {
      useSuspense: false,
    },
    backend: {
      backends: [HttpBackend],
      backendOptions: [
        {
          crossdomain: true,
          loadPath: TRANSLATION_FILE_PATH_TEMPLATE,
        },
      ],
    },
  });

export default i18n;
