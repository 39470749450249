import { APP_URL, Routes } from './config';
import { Maybe } from './types';
import { StatPeriod, FetchStatType } from './features/status/types';

type Urls = Record<string, CallableFunction>;
export const LoginUrls: Urls = {
  getRedirectUriForOkta: () => APP_URL + Routes.LoginCallback,
  getCurrentUserUrl: () => '/api/v1/users/me',
};

export const FlexApiUrls: Urls = {
  getIntrospectUrl: () => '/flex/v1/introspect',
  getPartnersUrl: () => 'flex/v1/partners',
  //corporations
  getCreateCompanyUrl: (partnerId: string) =>
    `/flex/v1/partners/${partnerId}/corporations`,
  getCompaniesUrl: (partnerId: string, page: number, limit: number) =>
    `/flex/v1/partners/${partnerId}/corporations?page=${page}&limit=${limit}`,
  getCompanyUrl: (partnerId: string, companyId: string) =>
    `flex/v1/partners/${partnerId}/corporations/${companyId}`,
  getUpdateCompanyUrl: (partnerId: string, companyId: string) =>
    `/flex/v1/partners/${partnerId}/corporations/${companyId}`,
  getDeleteCompanyUrl: (partnerId: string, companyId: string) =>
    `/flex/v1/partners/${partnerId}/corporations/${companyId}`,
  getCompanyDetailsFlexAdminOnly: (companyId: string) =>
    `/flex/v1/corporations/${companyId}`,
  //Users
  getUsersUrl: (companyId: string) =>
    `/flex/v1/corporations/${companyId}/users`,
  getCreateUserUrl: (companyId: string) =>
    `/flex/v1/corporations/${companyId}/users`,
  getResetPasswordUserUrl: (companyId: string, email: string) =>
    `/flex/v1/corporations/${companyId}/users/${email}/reset-password`,
  getUpdateUserUrl: (companyId: string, email: string) =>
    `/flex/v1/corporations/${companyId}/users/${email}`,
  getDeleteUserUrl: (companyId: string, email: string) =>
    `/flex/v1/corporations/${companyId}/users/${email}`,

  getTemplatesUrl: (companyId: string) =>
    `/flex/v1/corporations/${companyId}/flex-policies/`,
  getPaginatedTemplatesUrl: (
    companyId: string,
    page: number,
    limit: number,
    phrase: Maybe<string>,
  ) => {
    const baseQs = {
      page,
      limit,
    };
    let qs: any = {
      ...baseQs,
    };
    if (phrase) {
      qs = {
        ...baseQs,
        name: phrase,
      };
    }
    qs = new URLSearchParams(qs).toString();
    return `/flex/v1/corporations/${companyId}/flex-policies?${qs}`;
  },

  getTemplateUrl: (id: string, companyId: string) =>
    `/flex/v1/corporations/${companyId}/flex-policies/${id}`,
  getIndividualPoliciesUrl: (
    companyId: string,
    page: number,
    limit: number,
    phrase: Maybe<string>,
  ) => {
    const baseQs = {
      page,
      limit,
    };
    let qs: any = {
      ...baseQs,
    };
    if (phrase) {
      qs = {
        ...baseQs,
        q: phrase,
      };
    }
    qs = new URLSearchParams(qs).toString();
    return `/flex/v1/corporations/${companyId}/employee-policies?${qs}`;
  },
  getDeleteIndividualPolicyUrl: (
    companyId: string,
    templateId: string,
    policyId: string,
  ) => {
    return `/flex/v1/corporations/${companyId}/flex-policies/${templateId}/employee-policies/${policyId}`;
  },
  getCreateIndividualPolicyUrl: (companyId: string, templateId: string) => {
    return `/flex/v1/corporations/${companyId}/flex-policies/${templateId}/employee-policies`;
  },
  getIndividualPolicyUrl: (
    policyId: string,
    companyId: string,
    templateId: string,
  ) => {
    return `/flex/v1/corporations/${companyId}/flex-policies/${templateId}/employee-policies/${policyId}`;
  },
  getStatUptime: (companyId: string) => {
    return `/flex/v2/corporations/${companyId}/statistics/uptime`;
  },
  getEmployeeStatusUrl: (
    companyId: string,
    period: StatPeriod,
    type: FetchStatType,
  ) => {
    const params = {
      startTime: period.start.toISOString(),
      endTime: period.end.toISOString(),
      type,
    };
    return `/flex/v1/corporations/${companyId}/statistics/employee-status?startTime=${params.startTime}&endTime=${params.endTime}&type=${params.type}`;
  },

  getIpsecUrl: (companyId: string, period: StatPeriod, type: FetchStatType) => {
    const params = {
      startTime: period.start.toISOString(),
      endTime: period.end.toISOString(),
      type,
    };
    return `/flex/v1/corporations/${companyId}/statistics/ipsec?startTime=${params.startTime}&endTime=${params.endTime}&type=${params.type}`;
  },

  getRadiusUrl: (
    companyId: string,
    period: StatPeriod,
    type: FetchStatType,
  ) => {
    const params = {
      startTime: period.start.toISOString(),
      endTime: period.end.toISOString(),
      type,
    };
    return `/flex/v1/corporations/${companyId}/statistics/radius?startTime=${params.startTime}&endTime=${params.endTime}&type=${params.type}`;
  },

  getUploadFileUrl: (companyId: string) =>
    `/flex/v1/corporations/${companyId}/bulk-uploads`,

  getUploadFileDetailsUrl: (uploadId: string, companyId: string) =>
    `/flex/v1/corporations/${companyId}/bulk-uploads/${uploadId}`,
  getUploadFileFailedRowsUrl: (uploadId: string, companyId: string) =>
    `/flex/v1/corporations/${companyId}/bulk-uploads/${uploadId}/failed-rows-url`,

  getImpactedEmployeesUrl: (
    companyId: string,
    period: StatPeriod,
    type: FetchStatType,
  ) => {
    const params = {
      startTime: period.start.toISOString(),
      endTime: period.end.toISOString(),
      type,
    };
    return `/flex/v1/corporations/${companyId}/statistics/impacted-employees?startTime=${params.startTime}&endTime=${params.endTime}&type=${params.type}`;
  },

  getEmployeeOnboardingUrl: (
    companyId: string,
    period: StatPeriod,
    type: FetchStatType,
  ) => {
    const params = {
      startTime: period.start.toISOString(),
      endTime: period.end.toISOString(),
      type,
    };
    return `/flex/v1/corporations/${companyId}/statistics/employee-onboarding?startTime=${params.startTime}&endTime=${params.endTime}&type=${params.type}`;
  },

  getCreateCustomerSupportDetails: (companyId: string) => {
    return `/flex/v1/corporations/${companyId}/customer-support-details`;
  },

  getCustomerSupportDetails: (companyId: string) => {
    return `/flex/v1/corporations/${companyId}/customer-support-details`;
  },
};

export const FrontlineUrls: Urls = {
  getFrontlineLocationUrl: (locationId: string) => `/location/${locationId}`,
};
