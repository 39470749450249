import { useFetchData } from '../../../hooks/useFetchData';
import { DependencyContainer } from '../../../DependencyContainer';
import { useRecoilValue, useRecoilState } from 'recoil';
import { User, selectedCompanySelector, usersAtom } from '../../../state';

type UseUserValues = {
  users: User[] | undefined;
  loading: boolean;
  error: any;
  runFetch: () => Promise<void>;
  isFetchComplete: boolean;
};
const { usersService } = new DependencyContainer();
export const useUsers = (): UseUserValues => {
  const selectedCompany = useRecoilValue(selectedCompanySelector);
  const [users, setUsers] = useRecoilState(usersAtom);
  const [loading, , error, runFetch, isFetchComplete] = useFetchData<
    User[],
    any
  >(
    async () => {
      if (!selectedCompany) {
        return;
      }
      return await usersService.getUsers(selectedCompany.id);
    },
    (users) => setUsers(users),
    [selectedCompany],
    () => selectedCompany === undefined,
  );
  return {
    loading,
    users,
    error,
    runFetch,
    isFetchComplete,
  };
};
