import { TimeLineDropdownItem, TimeLinePeriodTypes } from '../../types';
import { Dropdown, DropdownSelectableItem } from 'plume-ui';
import React, { FunctionComponent, useContext, useState } from 'react';
import FormattedMessage from '../../../../utils/components/FormattedMessage';
import { ChartPeriodContext } from '../../contexts/ChartPeriodContext';
import { getCurrentMonth } from '../MonthDropdown/MonthDropdown';

const timeLineDropdownItems = [
  {
    periodType: TimeLinePeriodTypes.Last24Hours,
    labelId: 'status.timelineDropdown.24hours',
  },
  {
    periodType: TimeLinePeriodTypes.Last30Days,
    labelId: 'status.timelineDropdown.30days',
  },
  {
    periodType: TimeLinePeriodTypes.CalendarMonth,
    labelId: 'status.timelineDropdown.month',
  },
];

const TimeLinePicker: FunctionComponent = () => {
  const [selectedPeriodType, setSelectedPeriodType] = useState<
    TimeLinePeriodTypes
  >(TimeLinePeriodTypes.Last24Hours);
  const context = useContext(ChartPeriodContext);

  const onSelect = (item: TimeLineDropdownItem): void => {
    setSelectedPeriodType(item.periodType);
    context && context.setSelectedPeriodType(item.periodType);
    const currentMonth = getCurrentMonth();
    if (selectedPeriodType === TimeLinePeriodTypes.CalendarMonth) {
      context && context.setSelectedMonth(currentMonth);
    }
  };

  return (
    <Dropdown
      ghost
      label={context.selectedPeriodLabel}
      closeOnItemClick
      classes={(currentClasses) => ({
        ...currentClasses,
        root: `${currentClasses.root} TimeLine__dropdown`,
        button: `${currentClasses.button} TimeLine__dropdown--button`,
      })}
    >
      {timeLineDropdownItems.map((item) => (
        <DropdownSelectableItem
          key={item.periodType}
          selected={selectedPeriodType === item.periodType}
          onClick={() => onSelect(item)}
        >
          <FormattedMessage id={item.labelId} />
        </DropdownSelectableItem>
      ))}
    </Dropdown>
  );
};

export default TimeLinePicker;
