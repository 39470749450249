export type CreateUserDto = {
  profile: {
    email: string;
    firstName: string;
    lastName: string;
    role: string;
  };
};
export type CreateUserResponse = {
  id: string;
} & CreateUserDto;

export type UserTable = {
  email: string;
  name: string;
  role: string;
};

export enum UserRoles {
  FlexAdmin = 'flexAdmin',
}
