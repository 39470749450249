import { FormFieldDefinition } from '../../../types';
import { TemplateConfigurationFieldsNames } from '../form-data-marshaller';
import {
  AuthenticationAlgorithms,
  DHGroups,
  EncryptionAlgorithms,
  TunnelTypes,
} from '../types';
import { useTranslation } from 'react-i18next';

export type UseFieldsDefinitionsValues = Record<
  | 'workNetworkFields'
  | 'radiusAuthenticationFields'
  | 'ipSecPrimaryFields'
  | 'ipSecSecondaryFields',
  Array<FormFieldDefinition<TemplateConfigurationFieldsNames>>
>;

const authenticationAlgorithms = [
  {
    value: AuthenticationAlgorithms.SHA256,
    label: 'SHA256',
    disabled: false,
  },
  {
    value: AuthenticationAlgorithms.SHA1,
    label: 'SHA1',
    disabled: false,
  },
  {
    value: AuthenticationAlgorithms.MD5,
    label: 'MD5',
    disabled: false,
  },
];
const encryptionAlgorithms = [
  {
    value: EncryptionAlgorithms.AES256,
    label: 'AES256',
    disabled: false,
  },
  {
    value: EncryptionAlgorithms.AES192,
    label: 'AES192',
    disabled: false,
  },
  {
    value: EncryptionAlgorithms.AES128,
    label: 'AES128',
    disabled: false,
  },
  {
    value: EncryptionAlgorithms.A3DES,
    label: '3DES',
    disabled: true,
  },
];
const dhGroups = [
  {
    value: DHGroups.GROUP_14,
    label: '14',
    disabled: false,
  },
  {
    value: DHGroups.GROUP_5,
    label: '5',
    disabled: false,
  },
  {
    value: DHGroups.GROUP_2,
    label: '2',
    disabled: false,
  },
  {
    value: DHGroups.GROUP_1,
    label: '1',
    disabled: false,
  },
];

const UseFieldsDefinitions = (
  isUpdating: boolean,
): UseFieldsDefinitionsValues => {
  const { t } = useTranslation();
  const workNetworkFields: FormFieldDefinition<
    TemplateConfigurationFieldsNames
  >[] = [
    {
      name: TemplateConfigurationFieldsNames.WorkNetworkSsid,
      labelId: 'WorkNetworkConfigurations.SSID',
      type: 'text',
      placeholder: 'WorkNetworkConfigurations.SSIDPlaceholder',
      required: true,
      isVisible: true,
    },
    {
      name: TemplateConfigurationFieldsNames.WorkNetworkDns,
      labelId: 'WorkNetworkConfigurations.dnsList',
      type: 'text-group',
      placeholder: t('WorkNetworkConfigurations.ipPlaceholder'),
      required: true,
      isVisible: true,
    },
    {
      name: TemplateConfigurationFieldsNames.WorkNetworkDHCPOptions,
      labelId: 'WorkNetworkConfigurations.DHCP.title',
      type: 'dhcp-options-picker',
      placeholder: t('WorkNetworkConfigurations.ipPlaceholder'),
      required: false,
      isVisible: true,
    },
    {
      name: TemplateConfigurationFieldsNames.WorkNetworkDomainName,
      labelId: 'WorkNetworkConfigurations.domainName',
      type: 'text',
      placeholder: 'WorkNetworkConfigurations.domainNamePlaceholder',
      required: false,
      isVisible: true,
    },
    {
      name: TemplateConfigurationFieldsNames.WorkNetworkClasslessStaticRoutes,
      labelId: 'WorkNetworkConfigurations.classlessStaticRoutes',
      type: 'text-group',
      placeholder: t(
        'WorkNetworkConfigurations.classlessStaticRoutesPlaceholder',
      ),
      required: false,
      isVisible: true,
    },
  ];

  const radiusAuthenticationFields: FormFieldDefinition<
    TemplateConfigurationFieldsNames
  >[] = [
    {
      name:
        TemplateConfigurationFieldsNames.RadiusAuthenticationPrimaryAuthorizationServer,
      labelId: 'radiusAuthConfigurations.primaryAuthIP',
      type: 'text',
      placeholder: 'IPsecConfigurations.ipWithPortPlaceholder',
      required: true,
      isVisible: true,
    },
    {
      name:
        TemplateConfigurationFieldsNames.RadiusAuthenticationPrimaryAuthorizationServerPSK,
      labelId: 'radiusAuthConfigurations.primaryAuthPSK',
      type: 'password',
      placeholder: 'radiusAuthConfigurations.primaryAuthPSKPlaceholder',
      required: true,
      isVisible: true,
    },
    {
      name:
        TemplateConfigurationFieldsNames.RadiusAuthenticationSecondaryAuthorizationServer,
      labelId: 'radiusAuthConfigurations.secondaryAuthIP',
      placeholder: 'IPsecConfigurations.ipWithPortPlaceholder',
      type: 'text',
      required: false,
      isVisible: true,
    },
    {
      name:
        TemplateConfigurationFieldsNames.RadiusAuthenticationSecondaryAuthorizationServerPSK,
      labelId: 'radiusAuthConfigurations.secondaryAuthPSK',
      placeholder: 'radiusAuthConfigurations.secondaryAuthPSKPlaceholder',
      type: 'password',
      required: false,
      isVisible: true,
    },
    {
      name:
        TemplateConfigurationFieldsNames.RadiusAuthenticationPrimaryAccountingServer,
      labelId: 'radiusAuthConfigurations.primaryAccountServerIP',
      placeholder: 'IPsecConfigurations.ipWithPortPlaceholder',
      type: 'text',
      required: false,
      isVisible: true,
    },
    {
      name:
        TemplateConfigurationFieldsNames.RadiusAuthenticationPrimaryAccountingServerPSK,
      labelId: 'radiusAuthConfigurations.primaryAccountServerPSK',
      placeholder:
        'radiusAuthConfigurations.primaryAccountServerPSKPlaceholder',
      type: 'password',
      required: false,
      isVisible: true,
    },
    {
      name:
        TemplateConfigurationFieldsNames.RadiusAuthenticationSecondaryAccountingServer,
      labelId: 'radiusAuthConfigurations.secondaryAccountServerIP',
      placeholder: 'IPsecConfigurations.ipWithPortPlaceholder',
      type: 'text',
      required: false,
      isVisible: true,
    },
    {
      name:
        TemplateConfigurationFieldsNames.RadiusAuthenticationSecondaryAccountingServerPSK,
      labelId: 'radiusAuthConfigurations.secondaryAccountServerPSK',
      placeholder: 'radiusAuthConfigurations.secondaryAuthPSKPlaceholder',
      type: 'password',
      required: false,
      isVisible: true,
    },
  ];

  const ipSecPrimaryFields: FormFieldDefinition<
    TemplateConfigurationFieldsNames
  >[] = [
    {
      name: TemplateConfigurationFieldsNames.IPSecPrimaryTunnelType,
      labelId: 'IPsecConfigurations.tunnelType',
      type: 'toggler',
      options: [
        {
          value: TunnelTypes.IPSEC,
          labelId: 'IPsecConfigurations.tunnelTypes.IPSecSite2Site',
        },
        {
          value: TunnelTypes.IPSECStatic,
          labelId: 'IPsecConfigurations.tunnelTypes.IPSecPoint2SiteStatic',
        },
        {
          value: TunnelTypes.IPSECDynamic,
          labelId: 'IPsecConfigurations.tunnelTypes.IPSecPoint2SiteDynamic',
        },
      ],
      disabled: isUpdating,
      required: true,
      isVisible: true,
    },
    {
      name: TemplateConfigurationFieldsNames.IPSecPrimaryTunnelAddress,
      labelId: 'IPsecConfigurations.tunnelAddress',
      type: 'text',
      placeholder: 'IPsecConfigurations.tunnelAddressPlaceholder',
      required: true,
      isVisible: true,
    },
    {
      name: TemplateConfigurationFieldsNames.IPSecPrimaryRemoteEndpoint,
      labelId: 'IPsecConfigurations.remoteEndpoint',
      type: 'text',
      placeholder: 'IPsecConfigurations.remoteEndpointPlaceholder',
      required: false,
      isVisible: true,
    },
    {
      name: TemplateConfigurationFieldsNames.IPSecPrimaryRoutes,
      labelId: 'IPsecConfigurations.routes',
      type: 'text-group',
      placeholder: t('IPsecConfigurations.routePlaceholder'),
      required: true,
      isVisible: true,
    },
    {
      name: TemplateConfigurationFieldsNames.IPSecPrimaryRemoteSubnets,
      labelId: 'IPsecConfigurations.remoteSubnets',
      type: 'text-group',
      placeholder: t('IPsecConfigurations.routePlaceholder'),
      required: false,
      isVisible: true,
    },
    {
      name: TemplateConfigurationFieldsNames.IPSecPrimaryHealthcheckEndpoint,
      labelId: 'IPsecConfigurations.healthcheckEndpoint',
      type: 'text',
      placeholder: 'IPsecConfigurations.healthcheckEndpointPlaceholder',
      required: false,
      isVisible: true,
    },
    {
      name: TemplateConfigurationFieldsNames.IPSecPrimaryHealthcheckInterval,
      labelId: 'IPsecConfigurations.healthcheckInterval',
      type: 'text',
      placeholder: 'IPsecConfigurations.healthcheckIntervalPlaceholder',
      required: false,
      isVisible: true,
    },
    {
      name: TemplateConfigurationFieldsNames.IPSecPrimaryHealthcheckTimeout,
      labelId: 'IPsecConfigurations.healthcheckTimeout',
      type: 'text',
      placeholder: 'IPsecConfigurations.timeoutPlaceholder',
      required: false,
      isVisible: true,
    },
    {
      name: TemplateConfigurationFieldsNames.IPSecPrimaryEncryptionAlgorithms,
      textBefore: 'IPsecConfigurations.phase1CryptoMethods',
      labelId: 'IPsecConfigurations.encryptionAlgorithms',
      type: 'checkbox-group',
      isVisible: true,
      values: encryptionAlgorithms,
    },
    {
      name:
        TemplateConfigurationFieldsNames.IPSecPrimaryAuthenticationAlgorithms,
      labelId: 'IPsecConfigurations.authenticationAlgorithms',
      type: 'checkbox-group',
      isVisible: true,
      values: authenticationAlgorithms,
    },
    {
      name: TemplateConfigurationFieldsNames.IPSecPrimaryDHGroup,
      labelId: 'IPsecConfigurations.dhGroup',
      type: 'checkbox-group',
      isVisible: true,
      values: dhGroups,
    },
    {
      name: TemplateConfigurationFieldsNames.IPSecPrimaryIKELifetime,
      labelId: 'IPsecConfigurations.IKELifetime',
      type: 'text',
      placeholder: 'IPsecConfigurations.timeoutPlaceholder',
      required: false,
      isVisible: true,
    },
    {
      name: TemplateConfigurationFieldsNames.IPSecPrimaryESPEncryption,
      textBefore: 'Phase 2 crypto methods',
      labelId: 'IPsecConfigurations.espEncryption',
      type: 'checkbox-group',
      isVisible: true,
      values: encryptionAlgorithms,
    },
    {
      name:
        TemplateConfigurationFieldsNames.IPSecPrimaryESPAuthenticationAlgorithms,
      labelId: 'IPsecConfigurations.espAuthentication',
      type: 'checkbox-group',
      isVisible: true,
      values: authenticationAlgorithms,
    },
    {
      name: TemplateConfigurationFieldsNames.IPSecPrimaryDHGroupPhase2,
      labelId: 'IPsecConfigurations.dhGroup',
      type: 'checkbox-group',
      isVisible: true,
      values: dhGroups,
    },
    {
      name: TemplateConfigurationFieldsNames.IPSecPrimaryIPSecLifetime,
      labelId: 'IPsecConfigurations.IPSecLifetime',
      type: 'text',
      placeholder: 'IPsecConfigurations.timeoutPlaceholder',
      required: false,
      isVisible: true,
    },
  ];

  const ipSecSecondaryFields: FormFieldDefinition<
    TemplateConfigurationFieldsNames
  >[] = [
    {
      name: TemplateConfigurationFieldsNames.IPSecSecondaryTunnelAddress,
      labelId: 'IPsecConfigurations.tunnelAddress',
      type: 'text',
      placeholder: 'IPsecConfigurations.tunnelAddressPlaceholder',
      required: true,
      isVisible: true,
    },
    {
      name: TemplateConfigurationFieldsNames.IPSecSecondaryRemoteEndpoint,
      labelId: 'IPsecConfigurations.remoteEndpoint',
      type: 'text',
      placeholder: 'IPsecConfigurations.remoteEndpoint',
      required: false,
      isVisible: true,
    },
    {
      name: TemplateConfigurationFieldsNames.IPSecSecondaryRoutes,
      labelId: 'IPsecConfigurations.routes',
      type: 'text-group',
      placeholder: t('IPsecConfigurations.routePlaceholder'),
      required: true,
      isVisible: true,
    },
    {
      name: TemplateConfigurationFieldsNames.IPSecSecondaryRemoteSubnets,
      labelId: 'IPsecConfigurations.remoteSubnets',
      type: 'text-group',
      placeholder: t('IPsecConfigurations.routePlaceholder'),
      required: false,
      isVisible: true,
    },
    {
      name: TemplateConfigurationFieldsNames.IPSecSecondaryHealthcheckEndpoint,
      labelId: 'IPsecConfigurations.healthcheckEndpoint',
      type: 'text',
      placeholder: 'IPsecConfigurations.healthcheckEndpointPlaceholder',
      required: false,
      isVisible: true,
    },
    {
      name: TemplateConfigurationFieldsNames.IPSecSecondaryHealthcheckInterval,
      labelId: 'IPsecConfigurations.healthcheckInterval',
      type: 'text',
      placeholder: 'IPsecConfigurations.healthcheckIntervalPlaceholder',
      required: false,
      isVisible: true,
    },
    {
      name: TemplateConfigurationFieldsNames.IPSecSecondaryHealthcheckTimeout,
      labelId: 'IPsecConfigurations.healthcheckTimeout',
      type: 'text',
      placeholder: 'IPsecConfigurations.timeoutPlaceholder',
      required: false,
      isVisible: true,
    },

    {
      name: TemplateConfigurationFieldsNames.IPSecSecondaryEncryptionAlgorithms,
      textBefore: 'IPsecConfigurations.phase1CryptoMethods',
      labelId: 'IPsecConfigurations.encryptionAlgorithms',
      type: 'checkbox-group',
      isVisible: true,
      values: encryptionAlgorithms,
    },
    {
      name:
        TemplateConfigurationFieldsNames.IPSecSecondaryAuthenticationAlgorithms,
      labelId: 'IPsecConfigurations.authenticationAlgorithms',
      type: 'checkbox-group',
      isVisible: true,
      values: authenticationAlgorithms,
    },
    {
      name: TemplateConfigurationFieldsNames.IPSecSecondaryDHGroup,
      labelId: 'IPsecConfigurations.dhGroup',
      type: 'checkbox-group',
      isVisible: true,
      values: dhGroups,
    },
    {
      name: TemplateConfigurationFieldsNames.IPSecSecondaryIKELifetime,
      labelId: 'IPsecConfigurations.IKELifetime',
      type: 'text',
      placeholder: '10',
      required: false,
      isVisible: true,
    },
    {
      name: TemplateConfigurationFieldsNames.IPSecSecondaryESPEncryption,
      textBefore: 'Phase 2 crypto methods',
      labelId: 'IPsecConfigurations.espEncryption',
      type: 'checkbox-group',
      isVisible: true,
      values: encryptionAlgorithms,
    },
    {
      name:
        TemplateConfigurationFieldsNames.IPSecSecondaryESPAuthenticationAlgorithms,
      labelId: 'IPsecConfigurations.espAuthentication',
      type: 'checkbox-group',
      isVisible: true,
      values: authenticationAlgorithms,
    },
    {
      name: TemplateConfigurationFieldsNames.IPSecSecondaryDHGroupPhase2,
      labelId: 'IPsecConfigurations.dhGroup',
      type: 'checkbox-group',
      isVisible: true,
      values: dhGroups,
    },
    {
      name: TemplateConfigurationFieldsNames.IPSecSecondaryIPSecLifetime,
      labelId: 'IPsecConfigurations.IPSecLifetime',
      type: 'text',
      placeholder: 'IPsecConfigurations.timeoutPlaceholder',
      required: false,
      isVisible: true,
    },
  ];

  return {
    workNetworkFields,
    ipSecPrimaryFields,
    ipSecSecondaryFields,
    radiusAuthenticationFields,
  };
};

export default UseFieldsDefinitions;
