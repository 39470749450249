import { FormFieldDefinition } from '../../../types';
import { UserFormFields } from '../../components/CreateUserModal/CreateUserModal';
import { selectedUserSelector } from '../../../state';
import { useRecoilValue } from 'recoil';

export type UseUserFormFieldsValues = Array<
  FormFieldDefinition<UserFormFields>
>;

const useUserFormFields = (): UseUserFormFieldsValues => {
  const selectedUser = useRecoilValue(selectedUserSelector);
  const fields: Array<FormFieldDefinition<UserFormFields>> = [
    {
      labelId: 'addUser.form.firstName.label',
      name: UserFormFields.FirstName,
      type: 'text',
      required: false,
      isVisible: true,
    },
    {
      labelId: 'addUser.form.lastName.label',
      name: UserFormFields.LastName,
      type: 'text',
      required: false,
      isVisible: true,
    },
    {
      labelId: 'addUser.form.email.label',
      name: UserFormFields.Email,
      type: 'text',
      required: false,
      isVisible: !selectedUser,
    },
  ];

  return fields;
};

export default useUserFormFields;
