import React, { FunctionComponent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Breadcrumb,
  BreadcrumbNavigation,
  Grid,
  GridItem,
  Heading,
  Icons,
  notify,
  PendingContent,
  Spinner,
  Tooltip,
} from 'plume-ui';
import { useTranslation } from 'react-i18next';
import { Routes } from '../../../config';
import { useRedirectToRoute } from '../../../utils/hooks/useRedirectToRoute';
import { useParams } from 'react-router-dom';
import { DependencyContainer } from '../../../DependencyContainer';
import { useFetchData } from '../../../hooks/useFetchData';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  selectedCompanySelector,
  templateForOperationsAtom,
} from '../../../state';
import { Formik } from 'formik';
import { Template, TemplateWithoutId } from '../../templates/types';
import {
  mapFormFieldsToDto,
  mapTemplateConfigurationToFormFields,
  TemplateConfigurationFormFields,
} from '../form-data-marshaller';
import useValidationSchema from '../hooks/useValidationSchema';
import FormattedMessage from '../../../utils/components/FormattedMessage';
import TemplateConfigurationForm from '../components/TemplateConfigurationForm/TemplateConfigurationForm';
import { Maybe } from '../../../types';
import { useTrackEvent } from '../../trackingAnalytics/hooks/useTrackEvent';
import { MixPanelEvents } from '../../../mixPanelEvents';
import { AvailableScreens } from '../../trackingAnalytics/types';
import { useRole } from '../../login/hooks/useRole';
import { FlexRole } from '../../login/types';
import useCopyTemplateId from '../../../hooks/useCopyTemplateId';

const { templatesService } = new DependencyContainer();

// @TODO: Update UI components to have exported styles type for
//  Breadcrumb in order to replace this any with proper type
const addStylesToBreadcrumb = (current: any) => ({
  ...current,
  root: `${current.root} ConfigurationsContainer__breadcrumb`,
});

const ConfigurationContainer: FunctionComponent = () => {
  const { t } = useTranslation();
  const redirectToRoute = useRedirectToRoute();
  const { id } = useParams();
  const selectedCompany = useRecoilValue(selectedCompanySelector);
  const validationSchema = useValidationSchema();
  const [loadingForm, setLoadingForm] = useState(false);
  const onCopyTemplateId = useCopyTemplateId();
  const [templateForOperations, setTemplateForOperations] = useRecoilState(
    templateForOperationsAtom,
  );
  const { hasRole } = useRole();
  const isFlexAdmin = hasRole(FlexRole.FlexAdmin);
  const trackEvent = useTrackEvent();
  useEffect(() => {
    trackEvent({
      eventName: MixPanelEvents.SCREEN,
      additionalContent: {
        SCREEN: AvailableScreens.TemplatePolicyDetail,
      },
    });
  }, []);
  const [loading, templateConfiguration, error] = useFetchData<
    TemplateWithoutId,
    any
  >(
    async () => {
      if (!selectedCompany || !id) {
        return;
      }
      return await templatesService.getTemplate(id, selectedCompany.id);
    },
    undefined,
    [selectedCompany, id],
  );

  useEffect(() => {
    setLoadingForm(loading);
  }, [loading]);

  if (!id && !templateForOperations?.name) {
    return null;
  }

  const getFormValues = (): TemplateConfigurationFormFields => {
    if (templateForOperations?.name) {
      // it means that we attempt to add new one
      return mapTemplateConfigurationToFormFields(undefined);
    }
    const initialValues = mapTemplateConfigurationToFormFields(
      templateConfiguration,
    );
    return initialValues;
  };

  const onSubmit = async (values: TemplateConfigurationFormFields) => {
    if (!isFlexAdmin) {
      return null;
    }
    if (
      !selectedCompany ||
      (!id && !templateForOperations?.name) ||
      (!templateConfiguration && !templateForOperations?.name)
    ) {
      return;
    }
    const templateWithId: Maybe<Template> = templateConfiguration
      ? {
          ...templateConfiguration,
          id,
        }
      : undefined;
    const dto = mapFormFieldsToDto(values, templateWithId);
    try {
      if (templateForOperations?.name) {
        await templatesService.createTemplate(selectedCompany.id, {
          name: templateForOperations.name,
          ...dto,
        });
        setTimeout(() => {
          notify({
            title: t('success'),
            body: t('configurations.templateCreated'),
            type: 'success',
          });
        }, 500);
        trackEvent({
          eventName: MixPanelEvents.ADD_COMPANY_TEMPLATE_SUCCESS,
          additionalContent: {
            companyId: selectedCompany.id,
          },
        });
        setTemplateForOperations(undefined);
      } else {
        await templatesService.updateTemplate(id, selectedCompany.id, dto);

        setTimeout(() => {
          notify({
            title: t('success'),
            body: t('configurations.templateUpdated'),
            type: 'success',
          });
        }, 500);
        trackEvent({
          eventName: MixPanelEvents.EDIT_COMPANY_TEMPLATE_SUCCESS,
          additionalContent: {
            companyId: selectedCompany.id,
            templateId: id,
          },
        });
      }
      redirectToRoute(Routes.Templates);
    } catch (error) {
      notify({
        title: t('error'),
        body: t('somethingWentWrong'),
        type: 'error',
      });
      trackEvent({
        eventName: MixPanelEvents.EDIT_COMPANY_TEMPLATE_FAILURE,
        additionalContent: {
          companyId: selectedCompany.id,
          templateId: id,
        },
      });
    }
  };

  const goToIndividualPoliciesWithFilter = () => {
    const query = templateConfiguration?.name;
    window.location.href = `${Routes.IndividualPolicies}?query=${query}`;
  };

  return (
    <>
      <Helmet>
        <title>{t('configurations.title')}</title>
      </Helmet>
      <div className="ConfigurationsContainer p-xl">
        <PendingContent
          loading={loadingForm}
          isError={error}
          hideContent
          loader={Spinner}
        >
          <Grid>
            <GridItem colSpan="12" tabletColSpan="6">
              <div className="ConfigurationsContainer__template--desc">
                {templateConfiguration && (
                  <>
                    <BreadcrumbNavigation
                      classes={(current) => ({
                        ...current,
                        root: `${current.root} ConfigurationsContainer__breadcrumbs`,
                      })}
                    >
                      <Breadcrumb
                        classes={addStylesToBreadcrumb}
                        href={Routes.Templates}
                        label={t('templates.title')}
                      />
                      <Breadcrumb label={templateConfiguration.name} />
                    </BreadcrumbNavigation>
                    <Heading size="s">
                      <FormattedMessage id="configurations.templateName" />
                    </Heading>
                    <div className="ConfigurationsContainer__template--name">
                      <p>{templateConfiguration.name}</p>
                      <Tooltip
                        label={t('templates.dropdown.copyTemplateId')}
                        position={'below'}
                        alignment={'center'}
                      >
                        <Icons.CopyIcon
                          onClick={() =>
                            onCopyTemplateId(templateConfiguration as Template)
                          }
                        />
                      </Tooltip>
                    </div>
                  </>
                )}
              </div>

              {id && (
                <p
                  className="ConfigurationsContainer__template--policy"
                  onClick={() => goToIndividualPoliciesWithFilter()}
                >
                  <FormattedMessage id="configurations.viewAllIndividualPolicies" />
                </p>
              )}

              <Formik
                initialValues={getFormValues()}
                enableReinitialize
                validationSchema={validationSchema}
                validateOnChange={false}
                onSubmit={onSubmit}
              >
                {({ values, errors }) => <TemplateConfigurationForm />}
              </Formik>
            </GridItem>
          </Grid>
        </PendingContent>
      </div>
    </>
  );
};
export default ConfigurationContainer;
