import React from 'react';
import { FunctionComponent } from 'react';
import { FlexRole } from '../types';
import { useRole } from '../hooks/useRole';
import { XOR } from '../../../utils/xor';

export type ForDesiredRoleProps = {
  role?: FlexRole;
};

export type ForDifferentThanRoleProps = {
  differentThanRole?: FlexRole;
};

export type ForRoleProps = XOR<ForDesiredRoleProps, ForDifferentThanRoleProps>;

const ForRole: FunctionComponent<ForRoleProps> = ({
  role,
  differentThanRole,
  children,
}) => {
  const { hasRole } = useRole();
  const isEligible = role
    ? hasRole(role)
    : differentThanRole
    ? !hasRole(differentThanRole)
    : false;

  if (!isEligible) {
    return null;
  }
  return <>{children}</>;
};

export default ForRole;
