import React from 'react';
import { AdjustableComponent } from 'plume-ui/dist/hooks/useClasses';
import { useClasses } from '../../utils/hooks/useClasses';
import { Button, Spinner } from 'plume-ui';
import {
  BulkUploadsItem,
  BulkUploadStatus,
} from '../../features/individualPolicies/types';
import { ButtonStyles } from 'plume-ui/dist/components/Button/Button';
import FormattedMessage from '../../utils/components/FormattedMessage';

export type UploadModalRowProps = {
  item: BulkUploadsItem;
  onItemDetailsClick: (item: BulkUploadsItem) => void;
};
export type UploadModalRowStyles = {
  root: string;
  date: string;
  status: string;
  actions: string;
};

const renderStatus = (item: BulkUploadsItem) => {
  const accepted = item.successRowCount;
  const total = item.rowCount;
  const failed = item.failedRowCount;

  if (item.status === BulkUploadStatus.Failed) {
    return (
      <>
        <FormattedMessage id="uploadModal.failed" />
      </>
    );
  }

  if (item.status === BulkUploadStatus.Wait) {
    return (
      <>
        <Spinner size="small" visible />
        <FormattedMessage id="uploadModal.processing" />
      </>
    );
  }

  if (failed > 0) {
    return (
      <>
        <span className="color-status-error">
          {accepted}/{total}{' '}
        </span>
        <FormattedMessage id="uploadModal.rowsAccepted" />
      </>
    );
  }
  return (
    <>
      <span className="color-status-success">
        <FormattedMessage id="uploadModal.all" /> {total}
      </span>{' '}
      <FormattedMessage id="uploadModal.rowsAccepted" />
    </>
  );
};

const UploadModalRow: AdjustableComponent<
  UploadModalRowProps,
  UploadModalRowStyles
> = ({ item, onItemDetailsClick, classes, ...props }) => {
  const styles = useClasses(
    {
      root: 'UploadModalRow',
      date: 'UploadModalRow__date',
      status: 'UploadModalRow__status',
      actions: 'UploadModalRow__actions',
    },
    classes,
  );

  return (
    <div key={item.id} className={styles.root}>
      <div className={styles.date}>{item.createdAt}</div>
      <div className={styles.status}>{renderStatus(item)}</div>
      <div className={styles.actions}>
        <Button
          onClick={() => onItemDetailsClick(item)}
          styleVariant="quaternary"
          classes={(current: ButtonStyles) => ({
            ...current,
            root: `${current.root} UploadModalRow__cta`,
          })}
        >
          <FormattedMessage id="view" />
        </Button>
      </div>
    </div>
  );
};
export default UploadModalRow;
