export enum TunnelTypes {
  IPSEC = 'SITE_TO_SITE',
  IPSECStatic = 'POINT_TO_SITE_STATIC',
  IPSECDynamic = 'POINT_TO_SITE_DYNAMIC',
}

export enum EncryptionAlgorithms {
  UNKNOWN = 'IPSEC_ENC_SUITE_UNKNOWN',
  AES256 = 'IPSEC_ENC_SUITE_AES256',
  AES192 = 'IPSEC_ENC_SUITE_AES192',
  AES128 = 'IPSEC_ENC_SUITE_AES128',
  A3DES = 'IPSEC_ENC_SUITE_3DES',
}

export enum AuthenticationAlgorithms {
  UNKNOWN = 'IPSEC_AUTH_SUITE_UNKNOWN',
  SHA256 = 'IPSEC_AUTH_SUITE_SHA256',
  SHA1 = 'IPSEC_AUTH_SUITE_SHA1',
  MD5 = 'IPSEC_AUTH_SUITE_MD5',
}

export enum DHGroups {
  UNKNOWN = 'IPSEC_DH_GROUP_UNKNOWN',
  GROUP_1 = 'IPSEC_DH_GROUP_1',
  GROUP_2 = 'IPSEC_DH_GROUP_2',
  GROUP_5 = 'IPSEC_DH_GROUP_5',
  GROUP_14 = 'IPSEC_DH_GROUP_14',
}
