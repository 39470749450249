import { createPersistentState } from './core';

type InitialState = {
  selectedCompanyId: string | undefined;
  selectedPartnerId: string;
};

const initialState: InitialState = {
  selectedCompanyId: undefined,
  selectedPartnerId: '',
};
const persistentStateWrapper = createPersistentState(initialState);

export const {
  persistentState,
  usePersistentState,
  getAtom,
  initializePersistentState,
} = persistentStateWrapper;

export default persistentStateWrapper;
