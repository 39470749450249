import { useState } from 'react';
import { FetchStatType, StatPeriod, TimeLinePeriodTypes } from '../types';
import { useTranslation } from 'react-i18next';
import { Maybe } from '../../../types';
import { ChartPeriodContextValues } from '../contexts/ChartPeriodContext';
import { DependencyContainer } from '../../../DependencyContainer';

export const mapPeriodToLabelId: Record<TimeLinePeriodTypes, string> = {
  [TimeLinePeriodTypes.CalendarMonth]: 'status.timelineDropdown.month',
  [TimeLinePeriodTypes.Last24Hours]: 'status.timelineDropdown.24hours',
  [TimeLinePeriodTypes.Last30Days]: 'status.timelineDropdown.30days',
};

const { statusService } = new DependencyContainer();

export type UseTimeLineValues = {
  getContextDefaultValues: () => ChartPeriodContextValues;
  selectedPeriodType: TimeLinePeriodTypes;
  selectedMonth: string;
  getPeriod: () => StatPeriod;
  getType: () => FetchStatType;
};

export const useTimeLine = (): UseTimeLineValues => {
  const { t } = useTranslation();
  const [selectedPeriodType, setSelectedPeriodType] = useState<
    TimeLinePeriodTypes
  >(TimeLinePeriodTypes.Last24Hours);
  const [selectedMonth, setSelectedMonth] = useState<Maybe<string>>();

  const getSelectedPeriodLabel = () => {
    const labelId = mapPeriodToLabelId[selectedPeriodType] || '';
    return t(labelId);
  };

  const getPeriod = (): StatPeriod => {
    return statusService.getPeriod(selectedPeriodType, selectedMonth);
  };

  const getType = (): FetchStatType => {
    return statusService.getStatType(selectedPeriodType);
  };

  const getContextDefaultValues = (): ChartPeriodContextValues => ({
    selectedPeriodLabel: getSelectedPeriodLabel(),
    setSelectedPeriodType,
    selectedPeriodType,
    selectedMonth,
    setSelectedMonth,
  });

  return {
    getContextDefaultValues,
    selectedPeriodType,
    selectedMonth: selectedMonth || '',
    getPeriod,
    getType,
  };
};
