import { AxiosResponse } from 'axios';
import { FlexApiUrls } from '../../../urls';
import { FlexApiResponse, FlexApiSingleResponse } from '../../../types';
import { HttpClient } from '../../../httpClient';
import {
  BulkUploadsItem,
  CreateIndividualPolicyPayload,
  IndividualPolicyResponseItem,
  UpdateIndividualPolicyPayload,
} from '../types';
import { defaultLimitForDropdown } from '../../../config';

export default class IndividualPoliciesClient extends HttpClient {
  async fetchIndividualPolicies(
    companyId: string,
    page: number = 1,
    limit: number = defaultLimitForDropdown,
    phrase?: string,
  ): Promise<AxiosResponse<FlexApiResponse<IndividualPolicyResponseItem[]>>> {
    const response = await this.get<
      AxiosResponse<FlexApiResponse<IndividualPolicyResponseItem[]>>
    >(FlexApiUrls.getIndividualPoliciesUrl(companyId, page, limit, phrase));
    return response;
  }

  async fetchIndividualPolicy(
    policyId: string,
    templateId: string,
    companyId: string,
  ): Promise<
    AxiosResponse<FlexApiSingleResponse<IndividualPolicyResponseItem>>
  > {
    const response = await this.get<
      AxiosResponse<FlexApiSingleResponse<IndividualPolicyResponseItem>>
    >(FlexApiUrls.getIndividualPolicyUrl(policyId, companyId, templateId));
    return response;
  }

  async deleteIndividualPolicy(
    companyId: string,
    templateId: string,
    policyId: string,
  ): Promise<void> {
    await this.delete<
      AxiosResponse<FlexApiResponse<IndividualPolicyResponseItem[]>>
    >(
      FlexApiUrls.getDeleteIndividualPolicyUrl(companyId, templateId, policyId),
    );
  }

  async createIndividualPolicies(
    companyId: string,
    templateId: string,
    dto: CreateIndividualPolicyPayload,
  ): Promise<AxiosResponse<FlexApiResponse<any>>> {
    const response = await this.post<
      AxiosResponse<FlexApiResponse<IndividualPolicyResponseItem>>
    >(FlexApiUrls.getCreateIndividualPolicyUrl(companyId, templateId), dto);
    return response;
  }

  async updateIndividualPolicy(
    policyId: string,
    companyId: string,
    templateId: string,
    dto: UpdateIndividualPolicyPayload,
  ): Promise<AxiosResponse<FlexApiResponse<any>>> {
    const response = await this.put<
      AxiosResponse<FlexApiResponse<IndividualPolicyResponseItem>>
    >(FlexApiUrls.getIndividualPolicyUrl(policyId, companyId, templateId), dto);
    return response;
  }

  async uploadFile(companyId: string, file: File) {
    const token = (await this.factory.loginService.getUser()).idToken;
    const formData = new FormData();
    formData.append('file', file);
    return await this.post(FlexApiUrls.getUploadFileUrl(companyId), formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  async fetchUploads(
    companyId: string,
  ): Promise<AxiosResponse<FlexApiResponse<BulkUploadsItem[]>>> {
    return await this.get<AxiosResponse<FlexApiResponse<BulkUploadsItem[]>>>(
      FlexApiUrls.getUploadFileUrl(companyId),
    );
  }

  async fetchUploadById(
    uploadId: string,
    companyId: string,
  ): Promise<AxiosResponse<BulkUploadsItem>> {
    return await this.get<AxiosResponse<BulkUploadsItem>>(
      FlexApiUrls.getUploadFileDetailsUrl(uploadId, companyId),
    );
  }

  async fetchFailedRowsUrl(
    uploadId: string,
    companyId: string,
  ): Promise<AxiosResponse<any>> {
    return await this.post<AxiosResponse<any>>(
      FlexApiUrls.getUploadFileFailedRowsUrl(uploadId, companyId),
      {},
    );
  }
}
