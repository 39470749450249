import { MixPanelEvents } from '../../mixPanelEvents';

export type TrackingUser = {
  id?: string;
  emailAddress?: string;
  role?: string;
};

export enum AvailableScreens {
  Users = 'Users',
  Login = 'Login',
  Status = 'Status',
  IndividualPolicyList = 'IndividualPolicyList',
  TemplatePolicyList = 'TemplatePolicyList',
  TemplatePolicyDetail = 'TemplatePolicyDetail',
  Companies = 'Companies',
  CustomerSupportDetail = 'CustomerSupportDetail',
}
type GenericTrackEventParams = {
  eventName: MixPanelEvents.LOGOUT | MixPanelEvents.AUTHENTICATION_SUCCESS;
  additionalContent?: Record<string, any>;
};
type MixPanelUserAdditionalContent = {
  companyId: string;
  email: string;
};
type MixPanelIndividualPolicyAdditionalContent = {
  companyId: string;
  templateId: string;
  policyId: string;
};
type MixPanelTemplateAdditionalContent = {
  companyId: string;
  templateId: string;
};
export type TrackEventParams =
  | GenericTrackEventParams
  | {
      eventName: MixPanelEvents.SCREEN;
      additionalContent: {
        SCREEN: AvailableScreens;
      };
    }
  | {
      eventName: MixPanelEvents.PARTNER_SELECTION;
    }
  | {
      eventName: MixPanelEvents.COMPANY_SELECTION;
      additionalContent: {
        partnerId: string;
      };
    }
  | {
      eventName: MixPanelEvents.ADD_COMPANY_TEMPLATE_SUCCESS;
      additionalContent: {
        companyId: string;
      };
    }
  | {
      eventName: MixPanelEvents.ADD_COMPANY_TEMPLATE_FAILURE;
      additionalContent: {
        companyId: string;
      };
    }
  | {
      eventName: MixPanelEvents.EDIT_COMPANY_TEMPLATE_SUCCESS;
      additionalContent: MixPanelTemplateAdditionalContent;
    }
  | {
      eventName: MixPanelEvents.EDIT_COMPANY_TEMPLATE_FAILURE;
      additionalContent: MixPanelTemplateAdditionalContent;
    }
  | {
      eventName: MixPanelEvents.DELETE_COMPANY_TEMPLATE_SUCCESS;
      additionalContent: MixPanelTemplateAdditionalContent;
    }
  | {
      eventName: MixPanelEvents.DELETE_COMPANY_TEMPLATE_FAILURE;
      additionalContent: MixPanelTemplateAdditionalContent;
    }
  | {
      eventName: MixPanelEvents.ADD_COMPANY_SUCCESS;
      additionalContent: {
        partnerId: string;
      };
    }
  | {
      eventName: MixPanelEvents.ADD_COMPANY_FAILURE;
      additionalContent: {
        partnerId: string;
      };
    }
  | {
      eventName: MixPanelEvents.EDIT_COMPANY_SUCCESS;
      additionalContent: {
        partnerId: string;
        companyId: string;
      };
    }
  | {
      eventName: MixPanelEvents.EDIT_COMPANY_FAILURE;
      additionalContent: {
        partnerId: string;
        companyId: string;
      };
    }
  | {
      eventName: MixPanelEvents.DELETE_COMPANY_FAILURE;
      additionalContent: {
        partnerId: string;
        companyId: string;
      };
    }
  | {
      eventName: MixPanelEvents.DELETE_COMPANY_SUCCESS;
      additionalContent: {
        partnerId: string;
        companyId: string;
      };
    }
  | {
      eventName: MixPanelEvents.ADD_INDIVIDUAL_POLICY_SUCCESS;
      additionalContent: MixPanelTemplateAdditionalContent;
    }
  | {
      eventName: MixPanelEvents.ADD_INDIVIDUAL_POLICY_FAILURE;
      additionalContent: MixPanelTemplateAdditionalContent;
    }
  | {
      eventName: MixPanelEvents.EDIT_INDIVIDUAL_POLICY_SUCCESS;
      additionalContent: MixPanelIndividualPolicyAdditionalContent;
    }
  | {
      eventName: MixPanelEvents.EDIT_INDIVIDUAL_POLICY_FAILURE;
      additionalContent: MixPanelIndividualPolicyAdditionalContent;
    }
  | {
      eventName: MixPanelEvents.DELETE_INDIVIDUAL_POLICY_SUCCESS;
      additionalContent: MixPanelIndividualPolicyAdditionalContent;
    }
  | {
      eventName: MixPanelEvents.DELETE_INDIVIDUAL_POLICY_FAILURE;
      additionalContent: MixPanelIndividualPolicyAdditionalContent;
    }
  | {
      eventName: MixPanelEvents.ADD_USER_SUCCESS;
      additionalContent: MixPanelUserAdditionalContent;
    }
  | {
      eventName: MixPanelEvents.ADD_USER_FAILURE;
      additionalContent: MixPanelUserAdditionalContent;
    }
  | {
      eventName: MixPanelEvents.EDIT_USER_SUCCESS;
      additionalContent: MixPanelUserAdditionalContent;
    }
  | {
      eventName: MixPanelEvents.EDIT_USER_FAILURE;
      additionalContent: MixPanelUserAdditionalContent;
    }
  | {
      eventName: MixPanelEvents.DELETE_USER_SUCCESS;
      additionalContent: MixPanelUserAdditionalContent;
    }
  | {
      eventName: MixPanelEvents.DELETE_USER_FAILURE;
      additionalContent: MixPanelUserAdditionalContent;
    }
  | {
      eventName: MixPanelEvents.RESET_PASSWORD_USER_SUCCESS;
      additionalContent: MixPanelUserAdditionalContent;
    }
  | {
      eventName: MixPanelEvents.RESET_PASSWORD_USER_FAILURE;
      additionalContent: MixPanelUserAdditionalContent;
    }
  | {
      eventName: MixPanelEvents.ADD_CUSTOMER_SUPPORT_SUCCESS;
      additionalContent: {
        companyId: string;
      };
    }
  | {
      eventName: MixPanelEvents.ADD_CUSTOMER_SUPPORT_FAILURE;
      additionalContent: {
        companyId: string;
      };
    };
