import { AxiosResponse } from 'axios';
import { FlexApiUrls } from '../../../urls';
import { HttpClient } from '../../../httpClient';
import { Partner } from '../../../state';

export default class PartnersClient extends HttpClient {
  async fetchPartners(): Promise<Partner[]> {
    const response = await this.get<AxiosResponse<Partner[]>>(
      FlexApiUrls.getPartnersUrl(),
    );
    return response.data;
  }
}
