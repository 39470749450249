import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  notify,
  PendingContent,
  Spinner,
} from 'plume-ui';

import { ModalStyles } from 'plume-ui/dist/components/Modal/Modal';
import { Formik, Form, FormikProps, FormikValues } from 'formik';
import { useRecoilValue, useRecoilState } from 'recoil';
import { DependencyContainer } from '../../../../DependencyContainer';
import { useTrackEvent } from '../../../trackingAnalytics/hooks/useTrackEvent';
import {
  selectedPartnerSelector,
  companyForOperationsAtom,
  companyAtom,
} from '../../../../state';
import { CreateCompanyDto } from '../../types';
import { MixPanelEvents } from '../../../../mixPanelEvents';
import yup from '../../../../utils/yupInstance';
import FormattedMessage from '../../../../utils/components/FormattedMessage';
import useCreateCompanyFields from '../../hooks/useCreateCompanyFields';
import FormFieldRenderer, {
  FormFieldRenderStyles,
} from '../../../configurations/FormFieldRenderer';
import { FormFieldDefinition } from '../../../../types';
import { phoneValidationRegex } from '../../../../config';
import useCustomerSupportFormFields from '../../../customerSupport/hooks/useCustomerSupportFormFields';
import { CreateCustomerSupportFormFields } from '../../../customerSupport/containers/CustomerSupportContainer';
import { useFetchData } from '../../../../hooks/useFetchData';
import { CustomerSupportDetails } from '../../../customerSupport/types';
import { removeEmptyCompanyValues } from '../../../../helpers/objects';
import { getFieldKey } from '../../../../helpers/fields';

const { customerSupportService } = new DependencyContainer();

type CreateCompanyModalProps = {
  isOpen: boolean;
  onRequestClose: (success: boolean) => void;
  selectedCompanyId?: string;
  onFinish?: () => void;
};

export enum CreateCompanyFormFields {
  LegalName = 'legal_name',
  BusinessName = 'business_name',
  TaxIdNumber = 'tax_id_number',
  LegalAddress = 'legal_address',
  Country = 'country',
  PrimaryEmail = 'primary_email',
  PrimaryPhone = 'primary_phone',
  Website = 'website',
  ContactUsEmail = 'contactUsEmail',
  CallUsNumber = 'callUsNumber',
  ChatSupportUrl = 'chatSupportUrl',
  ManageYourDataUrl = 'manageYourDataUrl',
  FaqLink = 'faqLink',
  PrivacyLink = 'privacyLink',
  TermsLink = 'termsLink',
}
export type CreateCompanyFormValues = Record<CreateCompanyFormFields, string>;

const { companyService } = new DependencyContainer();

const applyStylesToFormFieldRenderer = (current: FormFieldRenderStyles) => ({
  ...current,
  root: `${current.root} CreateCompanyModal__form-row`,
  value: `${current.value} CreateCompanyModal__form-row-value`,
});

const CreateCompanyModal: FunctionComponent<CreateCompanyModalProps> = ({
  isOpen,
  onRequestClose,
  onFinish,
}) => {
  const { t } = useTranslation();
  const trackEvent = useTrackEvent();
  const selectedPartner = useRecoilValue(selectedPartnerSelector);
  const {
    mandatoryFields,
    optionalFields,
    loadingCompany,
    error,
  } = useCreateCompanyFields();
  const customerSupportFormFields = useCustomerSupportFormFields();
  const [loading, setLoading] = useState<boolean>(false);
  const [companyOperations, setCompanyOperations] = useRecoilState(
    companyForOperationsAtom,
  );
  const [company, setCompany] = useRecoilState(companyAtom);
  const onClose = (success: boolean) => {
    setCompanyOperations(undefined);
    setCompany(undefined);
    onRequestClose(success);
  };

  const [, details, , , ,] = useFetchData<CustomerSupportDetails, any>(
    async () => {
      if (!company?.id) {
        return;
      }
      return await customerSupportService.getCustomerSupportDetail(company?.id);
    },
    undefined,
    [company?.id],
    () => company?.id === undefined,
  );
  const getFormInitialValues = (): CreateCompanyFormValues => {
    if (company) {
      return {
        [CreateCompanyFormFields.LegalName]: company.legalName,
        [CreateCompanyFormFields.BusinessName]: company.businessName,
        [CreateCompanyFormFields.TaxIdNumber]: company.taxIdentificationNumber,
        [CreateCompanyFormFields.LegalAddress]: company.legalAddress,
        [CreateCompanyFormFields.Country]: company.country,
        [CreateCompanyFormFields.PrimaryEmail]: company.primaryEmail,
        [CreateCompanyFormFields.PrimaryPhone]: company.primaryPhone,
        [CreateCompanyFormFields.Website]: company.website,
        [CreateCompanyFormFields.ContactUsEmail]: details?.contactUsEmail!,
        [CreateCompanyFormFields.CallUsNumber]: details?.callUsNumber!,
        [CreateCompanyFormFields.ChatSupportUrl]: details?.chatSupportUrl!,
        [CreateCompanyFormFields.ManageYourDataUrl]: details?.manageYourDataUrl!,
        [CreateCompanyFormFields.FaqLink]: details?.faqLink!,
        [CreateCompanyFormFields.PrivacyLink]: details?.privacyLink!,
        [CreateCompanyFormFields.TermsLink]: details?.termsLink!,
      };
    }
    return {
      [CreateCompanyFormFields.LegalName]: '',
      [CreateCompanyFormFields.BusinessName]: '',
      [CreateCompanyFormFields.TaxIdNumber]: '',
      [CreateCompanyFormFields.LegalAddress]: '',
      [CreateCompanyFormFields.Country]: '',
      [CreateCompanyFormFields.PrimaryEmail]: '',
      [CreateCompanyFormFields.PrimaryPhone]: '',
      [CreateCompanyFormFields.Website]: '',
      [CreateCompanyFormFields.ContactUsEmail]: '',
      [CreateCompanyFormFields.CallUsNumber]: '',
      [CreateCompanyFormFields.ChatSupportUrl]: '',
      [CreateCompanyFormFields.ManageYourDataUrl]: '',
      [CreateCompanyFormFields.FaqLink]: '',
      [CreateCompanyFormFields.PrivacyLink]: '',
      [CreateCompanyFormFields.TermsLink]: '',
    };
  };
  const prepareCompanyDto = (
    values: CreateCompanyFormValues,
  ): CreateCompanyDto => {
    const customerSupportFormValues = removeEmptyCompanyValues({
      contactUsEmail: values[CreateCompanyFormFields.ContactUsEmail],
      callUsNumber: values[CreateCompanyFormFields.CallUsNumber],
      chatSupportUrl: values[CreateCompanyFormFields.ChatSupportUrl],
      manageYourDataUrl: values[CreateCompanyFormFields.ManageYourDataUrl],
      contactUsEnabled: !values[CreateCompanyFormFields.ContactUsEmail]
        ? false
        : true,
      callUsEnabled: !values[CreateCompanyFormFields.CallUsNumber]
        ? false
        : true,
      chatSupportEnabled: !values[CreateCompanyFormFields.ChatSupportUrl]
        ? false
        : true,
      manageYourDataEnabled: !values[CreateCompanyFormFields.ManageYourDataUrl]
        ? false
        : true,
      faqLink: values[CreateCompanyFormFields.FaqLink],
      privacyLink: values[CreateCompanyFormFields.PrivacyLink],
      termsLink: values[CreateCompanyFormFields.TermsLink],
    });
    return removeEmptyCompanyValues({
      legalName: values[CreateCompanyFormFields.LegalName],
      businessName: values[CreateCompanyFormFields.BusinessName],
      taxIdentificationNumber: values[CreateCompanyFormFields.TaxIdNumber],
      legalAddress: values[CreateCompanyFormFields.LegalAddress],
      country: values[CreateCompanyFormFields.Country],
      primaryEmail: values[CreateCompanyFormFields.PrimaryEmail],
      primaryPhone: values[CreateCompanyFormFields.PrimaryPhone],
      website: values[CreateCompanyFormFields.Website],
      customerSupportDetails: customerSupportFormValues,
    }) as CreateCompanyDto;
  };

  const onCreate = async (values: CreateCompanyFormValues) => {
    const dto = prepareCompanyDto(values);
    if (!selectedPartner) {
      return;
    }
    setLoading(true);
    try {
      await companyService.createCompany(dto, selectedPartner.id);
      notify({
        title: t('notifications.success'),
        body: t('addCompany.successMessage'),
        type: 'success',
      });
      onFinish?.();
      onClose(false);
      trackEvent({
        eventName: MixPanelEvents.ADD_COMPANY_SUCCESS,
        additionalContent: {
          partnerId: selectedPartner.id,
        },
      });
    } catch (error) {
      const message = error?.response?.data?.message;
      notify({
        title: t('notifications.failure'),
        body: message,
        type: 'error',
      });
      trackEvent({
        eventName: MixPanelEvents.ADD_COMPANY_FAILURE,
        additionalContent: {
          partnerId: selectedPartner?.id,
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const onEdit = async (values: CreateCompanyFormValues) => {
    const dto = prepareCompanyDto(values);
    if (!selectedPartner || !companyOperations?.companyId) {
      return;
    }
    setLoading(true);
    try {
      await companyService.updateCompany(
        selectedPartner.id,
        companyOperations?.companyId,
        dto,
      );
      notify({
        title: t('notifications.success'),
        body: t('addCompany.editSuccessMessage'),
        type: 'success',
      });
      onFinish?.();
      onClose(false);
      trackEvent({
        eventName: MixPanelEvents.EDIT_COMPANY_SUCCESS,
        additionalContent: {
          partnerId: selectedPartner.id,
          companyId: companyOperations?.companyId,
        },
      });
    } catch (error) {
      const message =
        error?.response?.data?.message || t('addCompany.editFailureMessage');
      notify({
        title: t('notifications.failure'),
        body: message,
        type: 'error',
      });
      trackEvent({
        eventName: MixPanelEvents.EDIT_COMPANY_FAILURE,
        additionalContent: {
          partnerId: selectedPartner?.id,
          companyId: companyOperations?.companyId,
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (values: CreateCompanyFormValues) => {
    if (company) {
      await onEdit(values);
    } else {
      await onCreate(values);
    }
  };
  const getValidationSchema = () =>
    yup.object().shape({
      [CreateCompanyFormFields.LegalName]: yup
        .string()
        .min(3, t('addCompany.form.legalName.min'))
        .required(t('addCompany.form.legalName.required')),
      [CreateCompanyFormFields.BusinessName]: yup
        .string()
        .min(5, t('addCompany.form.businessName.min'))
        .required(t('addCompany.form.businessName.required')),
      [CreateCompanyFormFields.TaxIdNumber]: yup
        .string()
        .required(t('addCompany.form.TIN.required')),
      [CreateCompanyFormFields.LegalAddress]: yup
        .string()
        .required(t('addCompany.form.legalAddress.required')),
      [CreateCompanyFormFields.Country]: yup
        .string()
        .required(t('addCompany.form.country.required')),
      [CreateCompanyFormFields.PrimaryEmail]: yup
        .string()
        .email(t('addCompany.form.primaryEmail.email'))
        .nullable(true),
      [CreateCompanyFormFields.PrimaryPhone]: yup
        .string()
        .matches(phoneValidationRegex, t('addCompany.form.primaryPhone.phone'))
        .nullable(true),
      website: yup
        .string()
        .url(t('addCompany.form.website.url'))
        .nullable(true),
      [CreateCompanyFormFields.ContactUsEmail]: yup
        .string()
        .email()
        .max(100, t('customerSupport.form.contactUsEmail.message'))
        .nullable(true),
      [CreateCompanyFormFields.CallUsNumber]: yup
        .string()
        .max(50, t('customerSupport.form.callUsNumber.message'))
        .nullable(true),
      [CreateCompanyFormFields.ChatSupportUrl]: yup
        .string()
        .url(t('customerSupport.form.chatSupportUrl.message'))
        .nullable(true),
      [CreateCompanyFormFields.ManageYourDataUrl]: yup
        .string()
        .url(t('customerSupport.form.manageYourDataUrl.message'))
        .nullable(true),
      [CreateCompanyFormFields.FaqLink]: yup
        .string()
        .url(t('customerSupport.form.faqLink.message'))
        .nullable(true),
      [CreateCompanyFormFields.PrivacyLink]: yup
        .string()
        .url(t('customerSupport.form.privacyLink.message'))
        .nullable(true),
      [CreateCompanyFormFields.TermsLink]: yup
        .string()
        .url(t('customerSupport.form.termsLink.message'))
        .nullable(true),
    });

  const isSubmitButtonDisabled = (formik: FormikProps<FormikValues>) => {
    let countFilledFields = 0;
    mandatoryFields.forEach((element) => {
      if (Boolean(formik.values[(element as any).name])) {
        ++countFilledFields;
      }
    });
    const allFieldsLength = mandatoryFields.length;
    const hasAllFieldsFilled = countFilledFields < allFieldsLength;

    return hasAllFieldsFilled;
  };

  return (
    <Modal
      isOpen={isOpen}
      solidBackground
      onRequestClose={() => onClose(false)}
      classes={(current: ModalStyles) => ({
        ...current,
        root: `${current.root} CreateCompanyModal__modal`,
      })}
      frame
    >
      <ModalHeader title={t('addCompany.companyInformation')} />
      <Formik
        initialValues={getFormInitialValues()}
        validationSchema={getValidationSchema()}
        validateOnChange={false}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {(formik: FormikProps<FormikValues>) => (
          <Form>
            <PendingContent
              loading={loadingCompany}
              transparent
              isError={Boolean(error)}
              loader={Spinner}
            >
              <ModalBody>
                <div className="CreateCompanyModal__form">
                  <div className="CreateCompanyModal__form-section">
                    {mandatoryFields.map(
                      (
                        field: FormFieldDefinition<CreateCompanyFormFields>,
                        index: number,
                      ) => (
                        <FormFieldRenderer
                          key={getFieldKey(field, index)}
                          labelsAsPlaceholders
                          fieldDefinition={field}
                          classes={applyStylesToFormFieldRenderer}
                        />
                      ),
                    )}
                  </div>
                  <div className="CreateCompanyModal__subheading">
                    <FormattedMessage id="addCompany.customerSupportInformation" />
                  </div>

                  <div className="CreateCompanyModal__form-section">
                    {customerSupportFormFields.map(
                      (
                        field: FormFieldDefinition<
                          CreateCustomerSupportFormFields
                        >,
                        index: number,
                      ) => (
                        <FormFieldRenderer
                          key={
                            field.type !== 'spacer'
                              ? field.name
                              : `field${index}`
                          }
                          labelsAsPlaceholders
                          fieldDefinition={field}
                          classes={applyStylesToFormFieldRenderer}
                        />
                      ),
                    )}
                  </div>
                  <div className="CreateCompanyModal__subheading">
                    <FormattedMessage id="addCompany.optionalInformation" />
                  </div>

                  <div className="CreateCompanyModal__form-section">
                    {optionalFields.map(
                      (
                        field: FormFieldDefinition<CreateCompanyFormFields>,
                        index: number,
                      ) => (
                        <FormFieldRenderer
                          key={getFieldKey(field, index)}
                          labelsAsPlaceholders
                          fieldDefinition={field}
                          classes={applyStylesToFormFieldRenderer}
                        />
                      ),
                    )}
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  classes={(current) => ({
                    ...current,
                    root: `${current.root} CreateCompanyModal__cancel-button`,
                  })}
                  onClick={() => onClose(false)}
                  styleVariant="tertiary"
                >
                  <FormattedMessage id="cancel" />
                </Button>
                <Button
                  disabled={isSubmitButtonDisabled(formik) || loading}
                  type="submit"
                  styleVariant="superprimary"
                >
                  <FormattedMessage id="save" />
                </Button>
              </ModalFooter>
            </PendingContent>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
export default CreateCompanyModal;
