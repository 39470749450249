import moment from 'moment';

export enum TimeLinePeriodTypes {
  CalendarMonth = 'calendar_month',
  Last24Hours = 'last_24hrs',
  Last30Days = 'last_30days',
}

export type TimeLineDropdownItem = {
  labelId: string;
  periodType: TimeLinePeriodTypes;
};

export enum FetchStatType {
  Hourly = 'hourly',
  Daily = 'daily',
}

export type StatPeriod = {
  start: moment.Moment;
  end: moment.Moment;
};
export type AreaChartDataItem = {
  name: string;
  values: number[];
};
export type EmployeeStatusItem = {
  active: number;
  inactive: number;
  offline: number;
  timestamp: string;
};

export type EmployeeOnboardingItem = {
  provisioned: number;
  onboarded: number;
  timestamp: string;
};

export type StatisticsUptimeResponseItem = {
  data: {
    offline_percent: number;
    ipsec_percent: number;
    radius_percent: number;
    start_time: string;
    end_time: string;
  };
};

export type IpsecItem = {
  primary_active: number;
  secondary_active: number;
  all_inactive: number;
  timestamp: string;
};

export type RadiusItem = {
  primary_active: number;
  secondary_active: number;
  all_inactive: number;
  timestamp: string;
};

export type ImpactedEmployeesItem = {
  ipsec_impacted: number;
  radius_impacted: number;
  timestamp: string;
};
