import { FlexApiResponse, FormFieldDefinition, Maybe } from '../../../types';
import { IndividualPolicyFormFields } from '../components/CreateIndividualPolicyModal';
import { useFetchData } from '../../../hooks/useFetchData';
import { selectedCompanySelector, Template } from '../../../state';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { DependencyContainer } from '../../../DependencyContainer';
import { useTranslation } from 'react-i18next';
import { IndividualPolicyResponseItem } from '../types';
import { useState } from 'react';
import { TemplateWithoutId } from '../../templates/types';
import { defaultLimitForDropdown } from '../../../config';
import { TunnelTypes } from '../../configurations/types';
import { makeTextExcerpt } from '../../../helpers/text';
import {
  createindividualPolicyAtom,
  individualPolicyForOperationsAtom,
} from '../individualPoliciesState';

export type UseCreateIndividualPolicyFieldsValues = {
  loading: boolean;
  error: any;
  fields: Array<FormFieldDefinition<IndividualPolicyFormFields>>;
  individualPolicy: Maybe<IndividualPolicyResponseItem>;
  flushIndividualPolicyData: () => void;
  selectedTemplate: Maybe<TemplateWithoutId>;
};

const {
  templatesService,
  individualPoliciesService,
} = new DependencyContainer();

const useCreateIndividualPolicyFields = (): UseCreateIndividualPolicyFieldsValues => {
  const selectedCompany = useRecoilValue(selectedCompanySelector);
  const individualPolicyForOperations = useRecoilValue(
    individualPolicyForOperationsAtom,
  );
  const setIndividualPolicy = useSetRecoilState(createindividualPolicyAtom);
  const { t } = useTranslation();
  const [selectedTemplateId, setSelectedTemplateId] = useState();
  const [loading, templatesData, error] = useFetchData<
    FlexApiResponse<Template[]>,
    any
  >(
    async () => {
      if (!selectedCompany) {
        return;
      }
      return await templatesService.getTemplates(
        selectedCompany.id,
        1,
        defaultLimitForDropdown,
      );
    },
    undefined,
    [selectedCompany],
    () => !selectedCompany,
  );
  const [
    loadingSelectedTemplate,
    selectedTemplate,
    selectedTemplateError,
  ] = useFetchData<TemplateWithoutId, any>(
    async () => {
      if (!selectedCompany || !selectedTemplateId) {
        return;
      }
      return await templatesService.getTemplate(
        selectedTemplateId,
        selectedCompany.id,
      );
    },
    undefined,
    [selectedCompany, selectedTemplateId],
    () => !selectedCompany || !selectedTemplateId,
  );

  const [
    loadingPolicy,
    individualPolicyData,
    individualPolicyError,
    flushIndividualPolicyData,
  ] = useFetchData<IndividualPolicyResponseItem, any>(
    async () => {
      if (!selectedCompany || !individualPolicyForOperations) {
        return;
      }
      return await individualPoliciesService.getIndividualPolicy(
        individualPolicyForOperations.id,
        individualPolicyForOperations.templateId,
        selectedCompany.id,
      );
    },
    (policy: IndividualPolicyResponseItem) => {
      setIndividualPolicy(policy);
      setSelectedTemplateId(policy.flexPolicy.id);
    },
    [selectedCompany, individualPolicyForOperations],
  );
  const hasSelectedTemplateSecondaryTunnel = Boolean(
    selectedTemplate?.secondaryTunnel,
  );
  const fields: Array<FormFieldDefinition<IndividualPolicyFormFields>> = [
    {
      labelId: individualPolicyForOperations
        ? 'addPolicy.selectedTemplate'
        : 'addPolicy.chooseTemplate',
      name: IndividualPolicyFormFields.TemplateId,
      type: 'select',
      required: false,
      isVisible: true,
      onSelect: (templateId: string) => setSelectedTemplateId(templateId),
      isReadOnly: Boolean(individualPolicyForOperations?.id),
      placeholder: t('addPolicy.chooseTemplatePlaceholder'),
      options: (templatesData?.items || []).map((template) => ({
        labelId: makeTextExcerpt(template.name, 25) || '',
        value: template.id,
      })),
    },
    {
      name: IndividualPolicyFormFields.Suspend,
      type: 'checkbox',
      required: false,
      isVisible: true,
      labelId: t('addPolicy.suspendPolicy'),
    },
    {
      labelId: 'addPolicy.employeeID',
      name: IndividualPolicyFormFields.EmployeeId,
      type: 'text',
      required: true,
      isVisible: true,
      placeholder: t('addPolicy.placeholder.employeeID'),
    },
    {
      labelId: 'addPolicy.externalID',
      name: IndividualPolicyFormFields.ExternalId,
      type: 'text',
      required: true,
      isVisible: true,
      placeholder: t('addPolicy.placeholder.externalID'),
    },
    {
      labelId:
        selectedTemplate && selectedTemplate.vpnMode === TunnelTypes.IPSECStatic
          ? 'addPolicy.Ipv4Subnet32'
          : 'addPolicy.Ipv4',
      name: IndividualPolicyFormFields.Ipv4,
      type: 'text',
      required:
        selectedTemplate && selectedTemplate.vpnMode === TunnelTypes.IPSEC,
      isVisible:
        !selectedTemplate ||
        (selectedTemplate && selectedTemplate.vpnMode === TunnelTypes.IPSEC),
      placeholder: t('addPolicy.placeholder.ipv4LanSubnet'),
    },
    {
      type: 'spacer',
      isVisible:
        !selectedTemplate ||
        (selectedTemplate && selectedTemplate.vpnMode === TunnelTypes.IPSEC),
    },
    {
      labelId: 'addPolicy.IpsecPSKPrimary',
      name: IndividualPolicyFormFields.IpsecPSKPrimary,
      type: 'password',
      required: true,
      isVisible: true,
      placeholder: t('addPolicy.placeholder.IpsecPSKPrimary'),
    },
    {
      labelId: 'addPolicy.localIdPrimary',
      name: IndividualPolicyFormFields.LocalIdPrimary,
      type: 'text',
      required: true,
      isVisible: true,
      placeholder: t('addPolicy.placeholder.localIdPrimary'),
    },
    {
      labelId: 'addPolicy.localTunnelIpPrimary',
      name: IndividualPolicyFormFields.LocalTunnelIpPrimary,
      type: 'text',
      required:
        selectedTemplate &&
        selectedTemplate?.vpnMode === TunnelTypes.IPSECStatic,
      isVisible:
        selectedTemplate &&
        [TunnelTypes.IPSEC, TunnelTypes.IPSECStatic].includes(
          selectedTemplate?.vpnMode,
        ),
      placeholder: t('addPolicy.placeholder.localTunnelIpPrimary'),
    },
    {
      labelId: 'addPolicy.remoteTunnelIpPrimary',
      name: IndividualPolicyFormFields.RemoteTunnelIpPrimary,
      type: 'text',
      isVisible: true,
      placeholder: t('addPolicy.placeholder.remoteTunnelIpPrimary'),
    },
    {
      labelId: 'addPolicy.IpsecPSKSecondary',
      name: IndividualPolicyFormFields.IpsecPSKSecondary,
      type: 'password',
      required: hasSelectedTemplateSecondaryTunnel,
      isVisible: hasSelectedTemplateSecondaryTunnel,
      placeholder: t('addPolicy.placeholder.IpsecPSKSecondary'),
    },
    {
      labelId: 'addPolicy.localIdSecondary',
      name: IndividualPolicyFormFields.LocalIdSecondary,
      type: 'text',
      required: hasSelectedTemplateSecondaryTunnel,
      isVisible: hasSelectedTemplateSecondaryTunnel,
      placeholder: t('addPolicy.placeholder.localIdSecondary'),
    },
    {
      labelId: 'addPolicy.localTunnelIpSecondary',
      name: IndividualPolicyFormFields.LocalTunnelIpSecondary,
      type: 'text',
      required:
        selectedTemplate &&
        hasSelectedTemplateSecondaryTunnel &&
        selectedTemplate?.vpnMode === TunnelTypes.IPSECStatic,
      isVisible:
        selectedTemplate &&
        hasSelectedTemplateSecondaryTunnel &&
        [TunnelTypes.IPSEC, TunnelTypes.IPSECStatic].includes(
          selectedTemplate?.vpnMode,
        ),
      placeholder: t('addPolicy.placeholder.localTunnelIpSecondary'),
    },
    {
      labelId: 'addPolicy.remoteTunnelIpSecondary',
      name: IndividualPolicyFormFields.RemoteTunnelIpSecondary,
      type: 'text',
      isVisible: hasSelectedTemplateSecondaryTunnel,
      placeholder: t('addPolicy.placeholder.remoteTunnelIpSecondary'),
    },
  ];
  return {
    fields,
    selectedTemplate,
    loading: loading || loadingPolicy || loadingSelectedTemplate,
    error: error || individualPolicyError || selectedTemplateError,
    individualPolicy: individualPolicyData,
    flushIndividualPolicyData,
  };
};

export default useCreateIndividualPolicyFields;
