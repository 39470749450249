import { Template } from '../../../state';
import { DependencyContainer } from '../../../DependencyContainer';
import { TemplateWithoutId, UpdateTemplateDto } from '../types';
import { DeepPartial, FlexApiResponse } from '../../../types';
import { DEFAULT_LIMIT_OF_RECORDS } from '../../../config';

export default class TemplatesService {
  constructor(private readonly factory: DependencyContainer) {}

  async getTemplates(
    companyId: string,
    page: number = 1,
    limit: number = DEFAULT_LIMIT_OF_RECORDS,
    phrase?: string,
  ): Promise<FlexApiResponse<Template[]>> {
    const response = await this.factory.templatesClient.fetchTemplates(
      companyId,
      page,
      limit,
      phrase,
    );
    return response.data;
  }

  async getTemplate(id: string, companyId: string): Promise<TemplateWithoutId> {
    const response = await this.factory.templatesClient.fetchTemplate(
      id,
      companyId,
    );
    return response.data;
  }

  async updateTemplate(
    id: string,
    companyId: string,
    updateTemplateDto: DeepPartial<UpdateTemplateDto>,
  ): Promise<any> {
    const response = await this.factory.templatesClient.updateTemplate(
      id,
      companyId,
      updateTemplateDto,
    );
    return response.data.items;
  }

  async createTemplate(
    companyId: string,
    updateTemplateDto: DeepPartial<UpdateTemplateDto>,
  ): Promise<any> {
    const response = await this.factory.templatesClient.createTemplate(
      companyId,
      updateTemplateDto,
    );
    return response.data.items;
  }

  async duplicateTemplate(
    companyId: string,
    templateId: string,
    newName: string,
  ): Promise<any> {
    const template = await this.getTemplate(templateId, companyId);
    if (!template) {
      throw new Error(`Unable to find template with id ${templateId}`);
    }
    const duplicatedTemplateDto = {
      ...template,
      name: newName,
    };
    const response = await this.factory.templatesClient.createTemplate(
      companyId,
      duplicatedTemplateDto,
    );
    return response.data.items;
  }

  async deleteTemplate(companyId: string, templateId: string): Promise<any> {
    return await this.factory.templatesClient.deleteTemplate(
      companyId,
      templateId,
    );
  }
}
