export const getObjectElement = (index: any, array: any[]) => {
  if (!array) {
    return undefined;
  }
  const element = array[index];
  return element ? element : undefined;
};

export const removeEmptyCompanyValues = (obj: any) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === '') {
      delete obj[key];
    }
  });
  return obj;
};

export const removeEmpty = (object: any) => {
  let newObject: any = {};
  Object.keys(object).forEach((key: any) => {
    if (object[key] === Object(object[key])) {
      newObject[key] = removeEmpty(object[key]);
    } else if (object[key] !== undefined) {
      newObject[key] = object[key];
    }
  });
  return newObject;
};
