import React, { FunctionComponent } from 'react';
import './root.scss';
import useLoginWithOkta from './features/login/hooks/useLoginWithOkta';

const ForAuthenticated: FunctionComponent = ({ children }) => {
  useLoginWithOkta();
  return <>{children}</>;
};

export default ForAuthenticated;
