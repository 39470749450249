import {
  OKTA_BASE_URL,
  OKTA_CLIENT_ID,
  OKTA_ISSUER,
  FLEX_BACKEND_URL,
} from './config';

export type EnvironmentId =
  | 'local'
  | 'ci'
  | 'dogfood'
  | 'beta'
  | 'gamma'
  | 'kappa'
  | 'opensync'
  | 'osync'
  | 'rho-dev';
export type Environment = {
  id: EnvironmentId;
  oktaBaseUrl: string;
  oktaIssuer: string;
  oktaClientId: string;
  backendUrl: string;
  frontlineUrl: string;
};

const localEnv: Environment = {
  id: 'local',
  oktaBaseUrl: 'https://external-dev.sso.plume.com',
  oktaIssuer: 'https://external-dev.sso.plume.com/oauth2/default',
  oktaClientId: '0oa15glqqc4MrpK8R0h8',
  backendUrl: 'https://piranha-ci.dev.us-west-2.aws.plume.tech',
  frontlineUrl: 'https://ci.noc.plume.com',
};

const environments: Environment[] = [
  localEnv,
  {
    id: 'ci',
    oktaBaseUrl: 'https://external-dev.sso.plume.com',
    oktaIssuer: 'https://external-dev.sso.plume.com/oauth2/default',
    oktaClientId: '0oa15glqqc4MrpK8R0h8',
    backendUrl: 'https://piranha-ci.dev.us-west-2.aws.plume.tech',
    frontlineUrl: 'https://ci.noc.plume.com',
  },
  {
    id: 'dogfood',
    oktaBaseUrl: 'https://external.sso.plume.com',
    oktaIssuer: 'https://external.sso.plume.com/oauth2/default',
    oktaClientId: '0oagvqmzp9ki3vANS357',
    backendUrl: 'https://piranha-dog1.dogfood.us-west-2.aws.plume.tech',
    frontlineUrl: 'https://dogfood.noc.plume.com',
  },
  {
    id: 'beta',
    oktaBaseUrl: 'https://external.sso.plume.com',
    oktaIssuer: 'https://external.sso.plume.com/oauth2/default',
    oktaClientId: '0oagvquy4fOybe5u9357',
    backendUrl: 'https://piranha-beta.prod.us-west-2.aws.plumenet.io',
    frontlineUrl: 'https://beta.noc.plume.com',
  },
  {
    id: 'gamma',
    oktaBaseUrl: 'https://external.sso.plume.com',
    oktaIssuer: 'https://external.sso.plume.com/oauth2/default',
    oktaClientId: '0oagvqy1mbxOsPH6A357',
    backendUrl: 'https://piranha-gamma.prod.us-west-2.aws.plumenet.io',
    frontlineUrl: 'https://gamma.noc.plume.com',
  },
  {
    id: 'kappa',
    oktaBaseUrl: 'https://external.sso.plume.com',
    oktaIssuer: 'https://external.sso.plume.com/oauth2/default',
    oktaClientId: '0oagvqwcvuXn9BOAo357',
    backendUrl: 'https://piranha.eu-central-1.prod.kappa.plumenet.io',
    frontlineUrl: 'https://kappa.noc.plume.com',
  },
  {
    id: 'opensync',
    oktaBaseUrl: 'https://external.sso.plume.com',
    oktaIssuer: 'https://external.sso.plume.com/oauth2/default',
    oktaClientId: '0oanuxsqt09Ad2t4z357',
    backendUrl: 'https://piranha-opensync.dev.us-west-2.aws.plume.tech',
    frontlineUrl: 'https://opensync.noc.plume.com',
  },
  {
    id: 'osync',
    oktaBaseUrl: 'https://external.sso.plume.com',
    oktaIssuer: 'https://external.sso.plume.com/oauth2/default',
    oktaClientId: '0oanuy7f6wvFPZgTX357',
    backendUrl: 'https://piranha-osync.dogfood.us-west-2.aws.plume.tech',
    frontlineUrl: 'https://osync.noc.plume.com',
  },
  {
    id: 'rho-dev',
    oktaBaseUrl: 'https://external.sso.plume.com',
    oktaIssuer: 'https://external.sso.plume.com/oauth2/default',
    oktaClientId: '0oat4ao4eoj056Djg357',
    backendUrl: 'https://piranha.aps1.dev.rho.jiohs.net',
    frontlineUrl: 'https://rho-dev.noc.plume.com',
  },
];

export const getEnvironment = (): Environment => {
  const hostnameBits = window.location.hostname.split('.');

  if (hostnameBits.length > 3) {
    const envFromUrl = hostnameBits[hostnameBits.length - 4];
    const env = environments.find((e) => e.id === envFromUrl);
    return env || localEnv;
  } else {
    return localEnv;
  }
};

const getEnvProp = (propName: keyof Environment, fallbackValue: string) => {
  const env = getEnvironment();
  return env[propName] || fallbackValue;
};

export const getOktaClientId = () => {
  return getEnvProp('oktaClientId', OKTA_CLIENT_ID);
};

export const getOktaBaseUrl = () => {
  return getEnvProp('oktaBaseUrl', OKTA_BASE_URL);
};

export const getOktaIssuer = () => {
  return getEnvProp('oktaIssuer', OKTA_ISSUER);
};

export const getBackendUrl = () => {
  return getEnvProp('backendUrl', FLEX_BACKEND_URL);
};

export const getFrontlineUrl = () => {
  return getEnvProp('frontlineUrl', '');
};
