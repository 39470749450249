import React, { FunctionComponent } from 'react';
import { BarChart, Block, Heading, PendingContent, Spinner } from 'plume-ui';
import { ChartPeriodContext } from '../../../contexts/ChartPeriodContext';
import FormattedMessage from '../../../../../utils/components/FormattedMessage';
import { TimeLinePeriodTypes } from '../../../types';
import MonthDropdown from '../../MonthDropdown/MonthDropdown';
import TimeLinePicker from '../../TimelinePicker/TimelinePicker';
import { useTranslation } from 'react-i18next';
import { useTimeLine } from '../../../hooks/useTimeLine';
import { BlockStyles } from 'plume-ui/dist/components/Block/Block';
import { useFetchData } from '../../../../../hooks/useFetchData';
import { selectedCompanySelector } from '../../../../../state';
import { useRecoilValue } from 'recoil';
import { DependencyContainer } from '../../../../../DependencyContainer';
import { BarChartDataItem } from 'plume-ui/src/utils/charts/chartTypes';

const addStylesToBlock = (current: BlockStyles) => ({
  ...current,
  root: `${current.root} EmployeeStatus`,
});

const { statusService } = new DependencyContainer();

const EmployeeStatusChartContainer: FunctionComponent = () => {
  const { t } = useTranslation();
  const {
    getContextDefaultValues,
    selectedPeriodType,
    selectedMonth,
    getPeriod,
    getType,
  } = useTimeLine();
  const employeeStatusGroups = [
    {
      label: t('employeeStatusChart.active'),
      color: '#0E8B6A',
      stroke: '#0E8B6A',
      key: 'groupA',
      tooltipLabel: t('employeeStatusChart.activeTooltip'),
    },
    {
      label: t('employeeStatusChart.inactive'),
      color: '#E5B100',
      stroke: '#E5B100',
      key: 'groupB',
      tooltipLabel: t('employeeStatusChart.inactiveTooltip'),
    },
    {
      label: t('employeeStatusChart.offline'),
      color: '#DC0562',
      stroke: '#DC0562',
      key: 'groupC',
      tooltipLabel: t('employeeStatusChart.offlineTooltip'),
    },
  ];
  const selectedCompany = useRecoilValue(selectedCompanySelector);
  const [loading, data, error] = useFetchData<BarChartDataItem[], any>(
    async () => {
      if (!selectedCompany) {
        return;
      }
      const [period, type] = [getPeriod(), getType()];
      return await statusService.getEmployeeStatus(
        selectedCompany.id,
        period,
        type,
      );
    },
    undefined,
    [selectedCompany, selectedPeriodType, selectedMonth],
    () => selectedCompany === undefined,
  );

  return (
    <ChartPeriodContext.Provider value={getContextDefaultValues()}>
      <PendingContent
        loading={loading}
        isError={Boolean(error)}
        hideContent
        loader={Spinner}
      >
        <Block classes={addStylesToBlock} id="EmployeeStatusChart">
          <Heading level={3} size="xl" medium>
            <FormattedMessage id="employeeStatusChart.title" />
          </Heading>
          <div className="EmployeeStatus__timeline--container">
            <span className="EmployeeStatus__timeline--month">
              {selectedPeriodType === TimeLinePeriodTypes.CalendarMonth && (
                <MonthDropdown />
              )}
            </span>
            <TimeLinePicker />
          </div>
          {!data?.length ? (
            <div className="EmployeeOnboarding__empty--container">
              <FormattedMessage id="noDataAvailable" />
            </div>
          ) : (
            <BarChart
              stacked
              data={data}
              groups={employeeStatusGroups}
              config={{
                showPercentageOnTooltip: true,
                yAxisFormat: 'percentage',
                statusVariant: 'legend',
              }}
              legendPosition="bottom"
            />
          )}
        </Block>
      </PendingContent>
    </ChartPeriodContext.Provider>
  );
};
export default EmployeeStatusChartContainer;
