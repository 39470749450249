import React, { useState } from 'react';
import { AdjustableComponent } from '../../utils/hooks/useClasses';
import { Toggler } from 'plume-ui';
import { FieldInputProps, FormikProps } from 'formik';

type FormikInputToggleValue = {
  label: string;
  value: string;
};

type FormikInputToggleProps = {
  values: FormikInputToggleValue[];
  field: FieldInputProps<FormikInputToggleValue>;
  form: FormikProps<FormikInputToggleValue>;
} & Omit<JSX.IntrinsicElements['input'], 'value' | 'type'>;

export type FormikInputToggleStyles = {
  root: string;
  toggle: string;
  selectedToggle: string;
  label: string;
};

const FormikInputToggle: AdjustableComponent<
  FormikInputToggleProps,
  FormikInputToggleStyles
> = ({ values, field, form, ...props }) => {
  const [selected, setSelected] = useState<string>(field.value.toString());
  const toggleElements = values.map(({ label, value }) => ({
    key: value,
    label,
  }));

  return (
    <Toggler
      toggleElements={toggleElements}
      value={selected}
      onToggle={(selection) => {
        setSelected(selection.key);
        form.setFieldValue(field.name, selection.key);
      }}
      {...props}
    />
  );
};

export default FormikInputToggle;
