const IPv4SegmentFormat =
  '(?:[0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])';
const IPv4AddressFormat = `(${IPv4SegmentFormat}[.]){3}${IPv4SegmentFormat}`;
const IPv4AddressRegExp = new RegExp(`^${IPv4AddressFormat}$`);
export const isDomain = /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/;

export const isIP = (string: string, version: number = 4): boolean => {
  if (version === 4) {
    if (!IPv4AddressRegExp.test(string)) {
      return false;
    }
    const parts = string
      .split('.')
      .map((p) => parseInt(p))
      .sort((a, b) => a - b);
    return parts[3] <= 255;
  }
  if (version === 6) {
    throw new Error('Not implemented yet');
  }
  return false;
};

export const isNumericString = (string: string) => {
  if (typeof string != 'string') {
    return false;
  }
  return !isNaN(parseInt(string)) && !isNaN(parseFloat(string));
};
