import { MixpanelService } from '../../trackingAnalytics/services/mixPanel.service';
import { TrackEventParams } from '../types';
import { MixPanelEvents } from '../../../mixPanelEvents';

export const useTrackEvent = () => {
  return (params: TrackEventParams) => {
    const additionalContent =
      params.eventName !== MixPanelEvents.PARTNER_SELECTION
        ? params.additionalContent
        : null;
    MixpanelService.getInstance().storeEvent(
      params.eventName,
      additionalContent,
    );
  };
};
