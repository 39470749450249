import { useSetRecoilState } from 'recoil';
import { confirmationModalAtom } from '../state';

export type UseWithConfirmationParams = {
  onConfirm: () => void;
  onCancel?: () => void;
  title?: string;
  body?: string;
  cancelButtonLabel?: string;
  confirmButtonLabel?: string;
};

const useWithConfirmation = (): ((
  params: UseWithConfirmationParams,
) => void) => {
  const setConfirmationModal = useSetRecoilState(confirmationModalAtom);

  return (params) => {
    setConfirmationModal({
      isOpen: true,
      body: params.body,
      title: params.title,
      onConfirm: params.onConfirm,
      onCancel: params.onCancel,
      cancelButtonLabel: params.cancelButtonLabel,
      confirmButtonLabel: params.confirmButtonLabel,
    });
  };
};

export default useWithConfirmation;
