import React, { FunctionComponent } from 'react';
import { RecoilRoot } from 'recoil';
import { BrowserRouter as Router } from 'react-router-dom';
import AppContainer from './AppContainer';
import { initializePersistentState } from './persistent-state/persistent-state';
import PersistentStateRoot from './persistent-state/PersistentStateRoot';

const App: FunctionComponent = () => {
  return (
    <RecoilRoot
      initializeState={({ set }) => {
        initializePersistentState(set);
      }}
    >
      <PersistentStateRoot>
        <Router>
          <AppContainer />
        </Router>
      </PersistentStateRoot>
    </RecoilRoot>
  );
};

export default App;
