import {
  Template,
  TemplateWithoutId,
  UpdateTemplateDto,
} from '../templates/types';
import { WiFiRadius, WPAModes } from '../individualPolicies/types';
import { DeepPartial, Maybe, Nullable } from '../../types';
import {
  AuthenticationAlgorithms,
  DHGroups,
  EncryptionAlgorithms,
  TunnelTypes,
} from './types';

export enum TemplateConfigurationFieldsNames {
  // Work Network section
  WorkNetworkSsid = 'work_network_ssid',
  WorkNetworkDns = 'work_network_dns',
  WorkNetworkDHCPOptions = 'work_network_dhcp_options',
  WorkNetworkDomainName = 'work_network_domain_name',
  WorkNetworkClasslessStaticRoutes = 'work_network_classless_static_routes',

  // Radius Authentication section
  RadiusAuthenticationPrimaryAuthorizationServer = 'radius_authentication_primary_authorization_server',
  RadiusAuthenticationPrimaryAuthorizationServerPSK = 'radius_authentication_primary_authorization_server_psk',
  RadiusAuthenticationSecondaryAuthorizationServer = 'radius_authentication_secondary_authorization_server',
  RadiusAuthenticationSecondaryAuthorizationServerPSK = 'radius_authentication_secondary_authorization_server_psk',
  RadiusAuthenticationPrimaryAccountingServer = 'radius_authentication_primary_accounting_server',
  RadiusAuthenticationPrimaryAccountingServerPSK = 'radius_authentication_primary_accounting_server_psk',
  RadiusAuthenticationSecondaryAccountingServer = 'radius_authentication_secondary_accounting_server',
  RadiusAuthenticationSecondaryAccountingServerPSK = 'radius_authentication_secondary_accounting_server_psk',

  //IPsecPrimary
  IPSecPrimaryTunnelType = 'ipsec_primary_tunnel_type',
  IPSecPrimaryTunnelAddress = 'ipsec_primary_tunnel_address',
  IPSecPrimaryRemoteEndpoint = 'ipsec_primary_remote_endpoint',
  IPSecPrimaryRoutes = 'ipsec_primary_routes',
  IPSecPrimaryRemoteSubnets = 'ipsec_primary_remote_subnets',
  IPSecPrimaryIPSecLifetime = 'ipsec_primary_ipsec_lifetime',
  IPSecPrimaryIKELifetime = 'ipsec_primary_ike_lifetime',
  IPSecPrimaryHealthcheckEndpoint = 'ipsec_primary_healthcheck_endpoint',
  IPSecPrimaryHealthcheckInterval = 'ipsec_primary_healthcheck_interval',
  IPSecPrimaryHealthcheckTimeout = 'ipsec_primary_healthcheck_timeout',

  IPSecPrimaryEncryptionAlgorithms = 'ipsec_primary_encryption_algorithms',
  IPSecPrimaryAuthenticationAlgorithms = 'ipsec_primary_authentication_algorithms',
  IPSecPrimaryDHGroup = 'ipsec_primary_dh_group',
  IPSecPrimaryESPEncryption = 'ipsec_primary_esp_encryption',
  IPSecPrimaryESPAuthenticationAlgorithms = 'ipsec_primary_esp_authentication_algorithms',
  IPSecPrimaryDHGroupPhase2 = 'ipsec_primary_dh_group_phase_2',

  //IPsecSecondary
  IPSecSecondaryTunnelType = 'ipsec_secondary_tunnel_type',
  IPSecSecondaryTunnelAddress = 'ipsec_secondary_tunnel_address',
  IPSecSecondaryRemoteEndpoint = 'ipsec_secondary_remote_endpoint',
  IPSecSecondaryRoutes = 'ipsec_secondary_routes',
  IPSecSecondaryRemoteSubnets = 'ipsec_secondary_remote_subnets',
  IPSecSecondaryIPSecLifetime = 'ipsec_secondary_ipsec_lifetime',
  IPSecSecondaryIKELifetime = 'ipsec_secondary_ike_lifetime',
  IPSecSecondaryHealthcheckEndpoint = 'ipsec_secondary_healthcheck_endpoint',
  IPSecSecondaryHealthcheckInterval = 'ipsec_secondary_healthcheck_interval',
  IPSecSecondaryHealthcheckTimeout = 'ipsec_secondary_healthcheck_timeout',

  IPSecSecondaryEncryptionAlgorithms = 'ipsec_secondary_encryption_algorithms',
  IPSecSecondaryAuthenticationAlgorithms = 'ipsec_secondary_authentication_algorithms',
  IPSecSecondaryDHGroup = 'ipsec_secondary_dh_group',
  IPSecSecondaryESPEncryption = 'ipsec_secondary_esp_encryption',
  IPSecSecondaryESPAuthenticationAlgorithms = 'ipsec_secondary_esp_authentication_algorithms',
  IPSecSecondaryDHGroupPhase2 = 'ipsec_secondary_dh_group_phase_2',
}

export type TemplateConfigurationFormFields = {
  // Work Network section
  [TemplateConfigurationFieldsNames.WorkNetworkSsid]: string;
  [TemplateConfigurationFieldsNames.WorkNetworkDns]: string[];
  [TemplateConfigurationFieldsNames.WorkNetworkDHCPOptions]: Array<{
    id: number;
    value: string;
  }>;
  [TemplateConfigurationFieldsNames.WorkNetworkDomainName]: string;
  [TemplateConfigurationFieldsNames.WorkNetworkClasslessStaticRoutes]: string;

  // Radius Authentication section
  [TemplateConfigurationFieldsNames.RadiusAuthenticationPrimaryAuthorizationServer]: string;
  [TemplateConfigurationFieldsNames.RadiusAuthenticationPrimaryAuthorizationServerPSK]: string;
  [TemplateConfigurationFieldsNames.RadiusAuthenticationPrimaryAuthorizationServerPSK]: string;
  [TemplateConfigurationFieldsNames.RadiusAuthenticationSecondaryAuthorizationServer]: string;
  [TemplateConfigurationFieldsNames.RadiusAuthenticationSecondaryAuthorizationServerPSK]: string;
  [TemplateConfigurationFieldsNames.RadiusAuthenticationPrimaryAccountingServer]: string;
  [TemplateConfigurationFieldsNames.RadiusAuthenticationPrimaryAccountingServerPSK]: string;
  [TemplateConfigurationFieldsNames.RadiusAuthenticationSecondaryAccountingServer]: string;
  [TemplateConfigurationFieldsNames.RadiusAuthenticationSecondaryAccountingServerPSK]: string;

  //IPsecPrimary
  [TemplateConfigurationFieldsNames.IPSecPrimaryTunnelType]: TunnelTypes;
  [TemplateConfigurationFieldsNames.IPSecPrimaryTunnelAddress]: string;
  [TemplateConfigurationFieldsNames.IPSecPrimaryRemoteEndpoint]: string;
  [TemplateConfigurationFieldsNames.IPSecPrimaryRoutes]: string[];
  [TemplateConfigurationFieldsNames.IPSecPrimaryRemoteSubnets]: string[];
  [TemplateConfigurationFieldsNames.IPSecPrimaryIPSecLifetime]: string;
  [TemplateConfigurationFieldsNames.IPSecPrimaryIKELifetime]: string;
  [TemplateConfigurationFieldsNames.IPSecPrimaryHealthcheckEndpoint]: string;
  [TemplateConfigurationFieldsNames.IPSecPrimaryHealthcheckInterval]: string;
  [TemplateConfigurationFieldsNames.IPSecPrimaryHealthcheckTimeout]: string;
  [TemplateConfigurationFieldsNames.IPSecPrimaryHealthcheckTimeout]: string;

  [TemplateConfigurationFieldsNames.IPSecPrimaryEncryptionAlgorithms]: string[];
  [TemplateConfigurationFieldsNames.IPSecPrimaryAuthenticationAlgorithms]: string[];
  [TemplateConfigurationFieldsNames.IPSecPrimaryDHGroup]: string[];
  [TemplateConfigurationFieldsNames.IPSecPrimaryESPEncryption]: string[];
  [TemplateConfigurationFieldsNames.IPSecPrimaryESPAuthenticationAlgorithms]: string[];
  [TemplateConfigurationFieldsNames.IPSecPrimaryDHGroupPhase2]: string[];

  //IPsecSecondary
  [TemplateConfigurationFieldsNames.IPSecSecondaryTunnelType]: TunnelTypes;
  [TemplateConfigurationFieldsNames.IPSecSecondaryTunnelAddress]: string;
  [TemplateConfigurationFieldsNames.IPSecSecondaryRemoteEndpoint]: string;
  [TemplateConfigurationFieldsNames.IPSecSecondaryRoutes]: string[];
  [TemplateConfigurationFieldsNames.IPSecSecondaryRemoteSubnets]: string[];
  [TemplateConfigurationFieldsNames.IPSecSecondaryIPSecLifetime]: string;
  [TemplateConfigurationFieldsNames.IPSecSecondaryIKELifetime]: string;
  [TemplateConfigurationFieldsNames.IPSecSecondaryHealthcheckEndpoint]: string;
  [TemplateConfigurationFieldsNames.IPSecSecondaryHealthcheckInterval]: string;
  [TemplateConfigurationFieldsNames.IPSecSecondaryHealthcheckTimeout]: string;

  [TemplateConfigurationFieldsNames.IPSecSecondaryEncryptionAlgorithms]: string[];
  [TemplateConfigurationFieldsNames.IPSecSecondaryAuthenticationAlgorithms]: string[];
  [TemplateConfigurationFieldsNames.IPSecSecondaryDHGroup]: string[];
  [TemplateConfigurationFieldsNames.IPSecSecondaryESPEncryption]: string[];
  [TemplateConfigurationFieldsNames.IPSecSecondaryESPAuthenticationAlgorithms]: string[];
  [TemplateConfigurationFieldsNames.IPSecSecondaryDHGroupPhase2]: string[];
};

export type TemplateConfigurationMappedFields = Record<
  TemplateConfigurationFieldsNames,
  any
>;

export const assembleRadiusServerValue = (
  radiusData?: WiFiRadius | WiFiRadius[],
) => {
  if (!radiusData) {
    return '';
  }
  if (Array.isArray(radiusData)) {
    return radiusData[0] ? `${radiusData[0].ip}:${radiusData[0].port}` : '';
  }
  return `${radiusData.ip}:${radiusData.port}`;
};

const getRadiusSecret = (radiusData?: WiFiRadius | WiFiRadius[]): string => {
  if (!radiusData) {
    return '';
  }
  if (Array.isArray(radiusData)) {
    return radiusData[0]?.secret || '';
  }
  return radiusData.secret || '';
};

export const mapTemplateConfigurationToFormFields = (
  templateConfiguration?: TemplateWithoutId,
): TemplateConfigurationMappedFields => {
  return {
    // Work Network
    [TemplateConfigurationFieldsNames.WorkNetworkSsid]:
      templateConfiguration?.wifi.ssid,
    [TemplateConfigurationFieldsNames.WorkNetworkDns]:
      templateConfiguration?.network.dns,
    [TemplateConfigurationFieldsNames.WorkNetworkDHCPOptions]:
      templateConfiguration?.network.options || [],
    [TemplateConfigurationFieldsNames.WorkNetworkDomainName]:
      templateConfiguration?.network?.domainName || '',
    [TemplateConfigurationFieldsNames.WorkNetworkClasslessStaticRoutes]:
      templateConfiguration?.network?.classlessStaticRoutes || [],

    // Radius Authentication section
    [TemplateConfigurationFieldsNames.RadiusAuthenticationPrimaryAuthorizationServer]: assembleRadiusServerValue(
      templateConfiguration?.wifi.primaryRadius,
    ),
    [TemplateConfigurationFieldsNames.RadiusAuthenticationPrimaryAuthorizationServerPSK]: getRadiusSecret(
      templateConfiguration?.wifi.primaryRadius,
    ),
    [TemplateConfigurationFieldsNames.RadiusAuthenticationSecondaryAuthorizationServer]: assembleRadiusServerValue(
      templateConfiguration?.wifi.secondaryRadiuses,
    ),
    [TemplateConfigurationFieldsNames.RadiusAuthenticationSecondaryAuthorizationServerPSK]: getRadiusSecret(
      templateConfiguration?.wifi.secondaryRadiuses,
    ),
    [TemplateConfigurationFieldsNames.RadiusAuthenticationPrimaryAccountingServer]: assembleRadiusServerValue(
      templateConfiguration?.wifi.primaryAccounting,
    ),
    [TemplateConfigurationFieldsNames.RadiusAuthenticationPrimaryAccountingServerPSK]: getRadiusSecret(
      templateConfiguration?.wifi.primaryAccounting,
    ),
    [TemplateConfigurationFieldsNames.RadiusAuthenticationSecondaryAccountingServer]: assembleRadiusServerValue(
      templateConfiguration?.wifi.secondaryAccountings,
    ),
    [TemplateConfigurationFieldsNames.RadiusAuthenticationSecondaryAccountingServerPSK]: getRadiusSecret(
      templateConfiguration?.wifi.secondaryAccountings,
    ),

    // IPsecPrimary
    [TemplateConfigurationFieldsNames.IPSecPrimaryTunnelType]:
      templateConfiguration?.vpnMode || TunnelTypes.IPSEC,
    [TemplateConfigurationFieldsNames.IPSecPrimaryTunnelAddress]:
      templateConfiguration?.primaryTunnel?.remoteEndpoint || '',
    [TemplateConfigurationFieldsNames.IPSecPrimaryRemoteEndpoint]:
      templateConfiguration?.primaryTunnel?.ipSec.remoteEndpointId || '',
    [TemplateConfigurationFieldsNames.IPSecPrimaryRoutes]:
      templateConfiguration?.primaryTunnel?.routes,
    [TemplateConfigurationFieldsNames.IPSecPrimaryRemoteSubnets]:
      templateConfiguration?.primaryTunnel?.ipSec?.remoteSubnets || [],
    [TemplateConfigurationFieldsNames.IPSecPrimaryIPSecLifetime]:
      templateConfiguration?.primaryTunnel?.ipSec.lifetime || '',
    [TemplateConfigurationFieldsNames.IPSecPrimaryIKELifetime]:
      templateConfiguration?.primaryTunnel?.ipSec.ikeLifetime || '',
    [TemplateConfigurationFieldsNames.IPSecPrimaryHealthcheckEndpoint]:
      templateConfiguration?.primaryTunnel?.healthCheck?.remoteIp || '',
    [TemplateConfigurationFieldsNames.IPSecPrimaryHealthcheckInterval]:
      templateConfiguration?.primaryTunnel?.healthCheck?.interval || '',
    [TemplateConfigurationFieldsNames.IPSecPrimaryHealthcheckTimeout]:
      templateConfiguration?.primaryTunnel?.healthCheck?.timeout || '',

    [TemplateConfigurationFieldsNames.IPSecPrimaryEncryptionAlgorithms]:
      templateConfiguration?.primaryTunnel?.ipSec?.ikeEncSuite || [],
    [TemplateConfigurationFieldsNames.IPSecPrimaryAuthenticationAlgorithms]:
      templateConfiguration?.primaryTunnel?.ipSec?.ikeAuthSuite || [],
    [TemplateConfigurationFieldsNames.IPSecPrimaryDHGroup]:
      templateConfiguration?.primaryTunnel?.ipSec?.ikeDhGroups || [],
    [TemplateConfigurationFieldsNames.IPSecPrimaryESPEncryption]:
      templateConfiguration?.primaryTunnel?.ipSec?.espEncSuite || [],
    [TemplateConfigurationFieldsNames.IPSecPrimaryESPAuthenticationAlgorithms]:
      templateConfiguration?.primaryTunnel?.ipSec?.espAuthSuite || [],
    [TemplateConfigurationFieldsNames.IPSecPrimaryDHGroupPhase2]:
      templateConfiguration?.primaryTunnel?.ipSec?.espDhGroups || [],

    // IPsecSecondary
    [TemplateConfigurationFieldsNames.IPSecSecondaryTunnelType]:
      TunnelTypes.IPSEC,
    [TemplateConfigurationFieldsNames.IPSecSecondaryTunnelAddress]:
      templateConfiguration?.secondaryTunnel?.remoteEndpoint || '',
    [TemplateConfigurationFieldsNames.IPSecSecondaryRemoteEndpoint]:
      templateConfiguration?.secondaryTunnel?.ipSec.remoteEndpointId || '',
    [TemplateConfigurationFieldsNames.IPSecSecondaryRoutes]:
      templateConfiguration?.secondaryTunnel?.routes || [],
    [TemplateConfigurationFieldsNames.IPSecSecondaryRemoteSubnets]:
      templateConfiguration?.secondaryTunnel?.ipSec?.remoteSubnets || [],
    [TemplateConfigurationFieldsNames.IPSecSecondaryIPSecLifetime]:
      templateConfiguration?.secondaryTunnel?.ipSec.lifetime || '',
    [TemplateConfigurationFieldsNames.IPSecSecondaryIKELifetime]:
      templateConfiguration?.secondaryTunnel?.ipSec.ikeLifetime || '',
    [TemplateConfigurationFieldsNames.IPSecSecondaryHealthcheckEndpoint]:
      templateConfiguration?.secondaryTunnel?.healthCheck?.remoteIp || '',
    [TemplateConfigurationFieldsNames.IPSecSecondaryHealthcheckInterval]:
      templateConfiguration?.secondaryTunnel?.healthCheck?.interval || '',
    [TemplateConfigurationFieldsNames.IPSecSecondaryHealthcheckTimeout]:
      templateConfiguration?.secondaryTunnel?.healthCheck?.timeout || '',

    [TemplateConfigurationFieldsNames.IPSecSecondaryEncryptionAlgorithms]:
      templateConfiguration?.secondaryTunnel?.ipSec?.ikeEncSuite || [],
    [TemplateConfigurationFieldsNames.IPSecSecondaryAuthenticationAlgorithms]:
      templateConfiguration?.secondaryTunnel?.ipSec?.ikeAuthSuite || [],
    [TemplateConfigurationFieldsNames.IPSecSecondaryDHGroup]:
      templateConfiguration?.secondaryTunnel?.ipSec?.ikeDhGroups || [],
    [TemplateConfigurationFieldsNames.IPSecSecondaryESPEncryption]:
      templateConfiguration?.secondaryTunnel?.ipSec?.espEncSuite || [],
    [TemplateConfigurationFieldsNames.IPSecSecondaryESPAuthenticationAlgorithms]:
      templateConfiguration?.secondaryTunnel?.ipSec?.espAuthSuite || [],
    [TemplateConfigurationFieldsNames.IPSecSecondaryDHGroupPhase2]:
      templateConfiguration?.secondaryTunnel?.ipSec?.espDhGroups || [],
  };
};

export const decoupleRadius = (
  dsn: string,
  psk?: string,
): Maybe<Partial<WiFiRadius>> => {
  if (!dsn) {
    return undefined;
  }
  const parts = dsn.split(':');
  return {
    ip: parts[0] || undefined,
    port: parseInt(parts[1]) || undefined,
    secret: psk || undefined,
  };
};

const getValueForPatchRequest = (value: any, toNumber = false) => {
  if (Array.isArray(value) && value.length === 0) {
    return undefined;
  }
  const returnValue = value || undefined;
  if (returnValue && toNumber) {
    return parseInt(returnValue);
  }
  return returnValue;
};

const cryptoMethodsAreAllUnknown = (ipsec: any) => {
  return (
    ipsec?.espAuthSuite?.[0] === AuthenticationAlgorithms.UNKNOWN &&
    ipsec?.espDhGroups?.[0] === DHGroups.UNKNOWN &&
    ipsec?.espEncSuite?.[0] === EncryptionAlgorithms.UNKNOWN &&
    ipsec?.ikeAuthSuite?.[0] === AuthenticationAlgorithms.UNKNOWN &&
    ipsec?.ikeDhGroups?.[0] === DHGroups.UNKNOWN &&
    ipsec?.ikeEncSuite?.[0] === EncryptionAlgorithms.UNKNOWN
  );
};

const filterEmptyValuesPredicate = (value: any) =>
  (value == null || (Array.isArray(value) && value.length === 0)) &&
  value !== '';

export const getSafely = (object: any, fallback: any = null): Nullable<any> => {
  if (object == null) {
    return fallback;
  }
  if (Array.isArray(object)) {
    if (object.length === 0) {
      return fallback;
    } else {
      const values = object.filter(filterEmptyValuesPredicate);
      if (values.length === object.length) {
        return fallback;
      }
      return object.map((o) => getSafely(o));
    }
  }
  const keys = Object.keys(object);
  const emptyValues = Object.values(object).filter(filterEmptyValuesPredicate);
  // if the all the cryptoMethods are using UNKNOWN them as emptyValues
  if (
    emptyValues.length + (cryptoMethodsAreAllUnknown(object) ? 6 : 0) ===
    keys.length
  ) {
    return fallback;
  }
  return object;
};

export const getListOf = (list: any[], fallback: any) => {
  return list && list.length ? list : [fallback];
};

export const mapFormFieldsToDto = (
  values: TemplateConfigurationFormFields,
  originalEntity: Maybe<Template>,
): DeepPartial<UpdateTemplateDto> => {
  return {
    ...originalEntity,
    // Work Network
    enable: true,
    wifi: {
      ssid: getValueForPatchRequest(
        values[TemplateConfigurationFieldsNames.WorkNetworkSsid],
      ),
      wpaMode: WPAModes.Auto,
      primaryRadius: decoupleRadius(
        values[
          TemplateConfigurationFieldsNames
            .RadiusAuthenticationPrimaryAuthorizationServer
        ],
        values[
          TemplateConfigurationFieldsNames
            .RadiusAuthenticationPrimaryAuthorizationServerPSK
        ],
      ),
      secondaryRadiuses: getSafely([
        decoupleRadius(
          values[
            TemplateConfigurationFieldsNames
              .RadiusAuthenticationSecondaryAuthorizationServer
          ],
          values[
            TemplateConfigurationFieldsNames
              .RadiusAuthenticationSecondaryAuthorizationServerPSK
          ],
        ),
      ]),
      primaryAccounting: getSafely(
        decoupleRadius(
          values[
            TemplateConfigurationFieldsNames
              .RadiusAuthenticationPrimaryAccountingServer
          ],
          values[
            TemplateConfigurationFieldsNames
              .RadiusAuthenticationPrimaryAccountingServerPSK
          ],
        ),
      ),
      secondaryAccountings: getSafely([
        decoupleRadius(
          values[
            TemplateConfigurationFieldsNames
              .RadiusAuthenticationSecondaryAccountingServer
          ],
          values[
            TemplateConfigurationFieldsNames
              .RadiusAuthenticationSecondaryAccountingServerPSK
          ],
        ),
      ]),
    },
    network: {
      dns: getValueForPatchRequest(
        values[TemplateConfigurationFieldsNames.WorkNetworkDns],
      ),
      domainName: getValueForPatchRequest(
        values[TemplateConfigurationFieldsNames.WorkNetworkDomainName],
      ),
      classlessStaticRoutes: getValueForPatchRequest(
        values[
          TemplateConfigurationFieldsNames.WorkNetworkClasslessStaticRoutes
        ],
      ),
      options:
        values[TemplateConfigurationFieldsNames.WorkNetworkDHCPOptions]?.map(
          (o) => ({
            id: parseInt(o.id.toString()),
            value: o.value,
          }),
        ) || undefined,
    },
    primaryTunnel: {
      remoteEndpoint: getValueForPatchRequest(
        values[TemplateConfigurationFieldsNames.IPSecPrimaryTunnelAddress],
      ),
      routes: getValueForPatchRequest(
        values[TemplateConfigurationFieldsNames.IPSecPrimaryRoutes],
      ),
      ipSec: {
        lifetime: getValueForPatchRequest(
          values[TemplateConfigurationFieldsNames.IPSecPrimaryIPSecLifetime],
          true,
        ),
        ikeLifetime: getValueForPatchRequest(
          values[TemplateConfigurationFieldsNames.IPSecPrimaryIKELifetime],
          true,
        ),
        remoteEndpointId: getValueForPatchRequest(
          values[TemplateConfigurationFieldsNames.IPSecPrimaryRemoteEndpoint],
        ),
        remoteSubnets: getValueForPatchRequest(
          values[TemplateConfigurationFieldsNames.IPSecPrimaryRemoteSubnets],
        ),
        ikeEncSuite: getListOf(
          values[
            TemplateConfigurationFieldsNames.IPSecPrimaryEncryptionAlgorithms
          ],
          EncryptionAlgorithms.UNKNOWN,
        ),
        ikeAuthSuite: getListOf(
          values[
            TemplateConfigurationFieldsNames
              .IPSecPrimaryAuthenticationAlgorithms
          ],
          AuthenticationAlgorithms.UNKNOWN,
        ),
        ikeDhGroups: getListOf(
          values[TemplateConfigurationFieldsNames.IPSecPrimaryDHGroup],
          DHGroups.UNKNOWN,
        ),
        espEncSuite: getListOf(
          values[TemplateConfigurationFieldsNames.IPSecPrimaryESPEncryption],
          EncryptionAlgorithms.UNKNOWN,
        ),
        espAuthSuite: getListOf(
          values[
            TemplateConfigurationFieldsNames
              .IPSecPrimaryESPAuthenticationAlgorithms
          ],
          AuthenticationAlgorithms.UNKNOWN,
        ),
        espDhGroups: getListOf(
          values[TemplateConfigurationFieldsNames.IPSecPrimaryDHGroupPhase2],
          DHGroups.UNKNOWN,
        ),
      },
      healthCheck: getSafely({
        remoteIp: getValueForPatchRequest(
          values[
            TemplateConfigurationFieldsNames.IPSecPrimaryHealthcheckEndpoint
          ],
        ),
        interval: getValueForPatchRequest(
          values[
            TemplateConfigurationFieldsNames.IPSecPrimaryHealthcheckInterval
          ],
          true,
        ),
        timeout: getValueForPatchRequest(
          values[
            TemplateConfigurationFieldsNames.IPSecPrimaryHealthcheckTimeout
          ],
          true,
        ),
      }),
    },
    vpnMode:
      values[TemplateConfigurationFieldsNames.IPSecPrimaryTunnelType] ||
      TunnelTypes.IPSEC,
    secondaryTunnel: getSafely({
      remoteEndpoint: getValueForPatchRequest(
        values[TemplateConfigurationFieldsNames.IPSecSecondaryTunnelAddress],
      ),
      routes: getValueForPatchRequest(
        values[TemplateConfigurationFieldsNames.IPSecSecondaryRoutes],
      ),
      ipSec: getSafely(
        {
          lifetime: getValueForPatchRequest(
            values[
              TemplateConfigurationFieldsNames.IPSecSecondaryIPSecLifetime
            ],
            true,
          ),
          ikeLifetime: getValueForPatchRequest(
            values[TemplateConfigurationFieldsNames.IPSecSecondaryIKELifetime],
            true,
          ),
          remoteEndpointId: getValueForPatchRequest(
            values[
              TemplateConfigurationFieldsNames.IPSecSecondaryRemoteEndpoint
            ],
          ),
          remoteSubnets: getValueForPatchRequest(
            values[
              TemplateConfigurationFieldsNames.IPSecSecondaryRemoteSubnets
            ],
          ),
          ikeEncSuite: getListOf(
            values[
              TemplateConfigurationFieldsNames
                .IPSecSecondaryEncryptionAlgorithms
            ],
            EncryptionAlgorithms.UNKNOWN,
          ),
          ikeAuthSuite: getListOf(
            values[
              TemplateConfigurationFieldsNames
                .IPSecSecondaryAuthenticationAlgorithms
            ],
            AuthenticationAlgorithms.UNKNOWN,
          ),
          ikeDhGroups: getListOf(
            values[TemplateConfigurationFieldsNames.IPSecSecondaryDHGroup],
            DHGroups.UNKNOWN,
          ),
          espEncSuite: getListOf(
            values[
              TemplateConfigurationFieldsNames.IPSecSecondaryESPEncryption
            ],
            EncryptionAlgorithms.UNKNOWN,
          ),
          espAuthSuite: getListOf(
            values[
              TemplateConfigurationFieldsNames
                .IPSecSecondaryESPAuthenticationAlgorithms
            ],
            AuthenticationAlgorithms.UNKNOWN,
          ),
          espDhGroups: getListOf(
            values[
              TemplateConfigurationFieldsNames.IPSecSecondaryDHGroupPhase2
            ],
            DHGroups.UNKNOWN,
          ),
        },
        [],
      ),
      healthCheck: getSafely({
        remoteIp:
          values[
            TemplateConfigurationFieldsNames.IPSecSecondaryHealthcheckEndpoint
          ] || undefined,
        interval: getValueForPatchRequest(
          values[
            TemplateConfigurationFieldsNames.IPSecSecondaryHealthcheckInterval
          ],
          true,
        ),
        timeout: getValueForPatchRequest(
          values[
            TemplateConfigurationFieldsNames.IPSecSecondaryHealthcheckTimeout
          ],
          true,
        ),
      }),
    }),
  };
};
