import { DependencyContainer } from '../../../DependencyContainer';
import { StatisticsUptimeResponseItem, ImpactedEmployeesItem } from '../types';
import {
  EmployeeStatusItem,
  FetchStatType,
  StatPeriod,
  TimeLinePeriodTypes,
  EmployeeOnboardingItem,
  IpsecItem,
  RadiusItem,
} from '../types';
import moment from 'moment';
import { createEmptyDataSetForChart } from '../helpers/charts';
import { BarChartDataItem } from 'plume-ui/dist/utils/charts/chartTypes';

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
const LOCAL_DATE_FORMAT = 'MMM YYYY';

export default class StatusService {
  constructor(private readonly factory: DependencyContainer) {}
  async getStatisticsUptime(
    companyId: string,
  ): Promise<StatisticsUptimeResponseItem> {
    const response = await this.factory.statusClient.fetchStatUptime(companyId);
    return response.data;
  }
  getPeriod(
    selectedPeriod: TimeLinePeriodTypes,
    selectedMonth?: string,
  ): StatPeriod {
    const currentMonth = moment().format(LOCAL_DATE_FORMAT);
    const monthSubject = selectedMonth || currentMonth;
    const selectedMonthParts = monthSubject?.split(' ') || [];
    const selectedMonthNumber = months.findIndex(
      (m) => m === selectedMonthParts[0],
    );
    const selectedYearNumber = parseInt(selectedMonthParts[1]);
    const selectedMonthDate = new Date(selectedYearNumber, selectedMonthNumber);
    switch (selectedPeriod) {
      case TimeLinePeriodTypes.CalendarMonth:
        return {
          start: moment(selectedMonthDate).startOf('month'),
          end:
            moment(selectedMonthDate).format(LOCAL_DATE_FORMAT) === currentMonth
              ? moment()
              : moment(selectedMonthDate).endOf('month'),
        };
      case TimeLinePeriodTypes.Last24Hours:
        return {
          start: moment().subtract(24, 'hours'),
          end: moment(),
        };
      case TimeLinePeriodTypes.Last30Days:
        return {
          start: moment().subtract(30, 'days'),
          end: moment(),
        };
    }
  }

  getStatType(selectedPeriod: TimeLinePeriodTypes): FetchStatType {
    if (selectedPeriod === TimeLinePeriodTypes.Last24Hours) {
      return FetchStatType.Hourly;
    }
    return FetchStatType.Daily;
  }

  async getEmployeeStatus(
    companyId: string,
    period: StatPeriod,
    type: FetchStatType = FetchStatType.Hourly,
  ): Promise<BarChartDataItem[]> {
    const response = await this.factory.statusClient.fetchEmployeeStatusData(
      companyId,
      period,
      type,
    );
    const { data } = response.data;
    const format = type === FetchStatType.Hourly ? 'HH:00' : 'MMM DD';
    if (data.length === 0) {
      return createEmptyDataSetForChart();
    }
    return data.map((item: EmployeeStatusItem) => ({
      name: moment(item.timestamp).format(format),
      values: [item.active, item.inactive, item.offline],
    }));
  }

  async getEmployeeOnboarding(
    companyId: string,
    period: StatPeriod,
    type: FetchStatType = FetchStatType.Hourly,
  ): Promise<Array<Record<'xAxisLabel' | string, string | number>>> {
    const response = await this.factory.statusClient.fetchEmployeeOnboardingData(
      companyId,
      period,
      type,
    );
    const { data } = response.data;
    const format = type === FetchStatType.Hourly ? 'HH:00' : 'MMM DD';
    if (data.length === 0) {
      return createEmptyDataSetForChart();
    }
    return data.map((item: EmployeeOnboardingItem) => ({
      groupA: item.provisioned,
      groupB: item.onboarded,
      xAxisLabel: moment(item.timestamp).format(format),
    }));
  }

  async getIpsec(
    companyId: string,
    period: StatPeriod,
    type: FetchStatType = FetchStatType.Hourly,
  ): Promise<BarChartDataItem[]> {
    const response = await this.factory.statusClient.fetchIpsecData(
      companyId,
      period,
      type,
    );
    const { data } = response.data;
    const format = type === FetchStatType.Hourly ? 'HH:00' : 'MMM DD';
    if (data.length === 0) {
      return createEmptyDataSetForChart();
    }
    return data.map((item: IpsecItem) => ({
      name: moment(item.timestamp).format(format),
      values: [item.primary_active, item.secondary_active, item.all_inactive],
    }));
  }

  async getRadius(
    companyId: string,
    period: StatPeriod,
    type: FetchStatType = FetchStatType.Hourly,
  ): Promise<BarChartDataItem[]> {
    const response = await this.factory.statusClient.fetchRadiusData(
      companyId,
      period,
      type,
    );
    const { data } = response.data;

    const format = type === FetchStatType.Hourly ? 'HH:00' : 'MMM DD';
    if (data.length === 0) {
      return createEmptyDataSetForChart();
    }
    return data.map((item: RadiusItem) => ({
      name: moment(item.timestamp).format(format),
      values: [item.primary_active, item.secondary_active, item.all_inactive],
    }));
  }

  async getImpactedEmployees(
    companyId: string,
    period: StatPeriod,
    type: FetchStatType = FetchStatType.Hourly,
  ): Promise<Array<Record<'xAxisLabel' | string, string | number>>> {
    const response = await this.factory.statusClient.fetchImpactedEmployeesData(
      companyId,
      period,
      type,
    );
    const { data } = response.data;
    const format = type === FetchStatType.Hourly ? 'HH:00' : 'MMM DD';
    if (data.length === 0) {
      return createEmptyDataSetForChart();
    }
    return data.map((item: ImpactedEmployeesItem) => ({
      groupC: item.ipsec_impacted,
      groupD: item.radius_impacted,
      xAxisLabel: moment(item.timestamp).format(format),
    }));
  }
}
