import React, {
  FunctionComponent,
  useState,
  useEffect,
  useContext,
} from 'react';
import { Dropdown, DropdownSelectableItem } from 'plume-ui';
import moment from 'moment';
import { Maybe } from '../../../../types';
import { ChartPeriodContext } from '../../contexts/ChartPeriodContext';

const monthName = moment.monthsShort();

const getMonthList = (): string[] => {
  const now = new Date();
  const monthList: string[] = [];
  monthList.push(monthName[now.getMonth()] + ' ' + now.getFullYear());
  for (let i = 0; i <= 11; i++) {
    now.setMonth(now.getMonth() - 1, 1);
    monthList.push(monthName[now.getMonth()] + ' ' + now.getFullYear());
  }
  return monthList;
};

export const getCurrentMonth = () => {
  const now = new Date();
  return `${monthName[now.getMonth()]} ${now.getFullYear()}`;
};

const MonthDropdown: FunctionComponent = () => {
  const context = useContext(ChartPeriodContext);
  const [monthListItems, setMonthListItems] = useState<string[]>([]);
  const [selectedItem, setSelectedItem] = useState<Maybe<string>>();

  useEffect(() => {
    const months = getMonthList();
    setMonthListItems(months);
  }, []);

  const getSelectMonthDropdownLabel = (): string => {
    if (selectedItem) {
      return selectedItem;
    }
    return getCurrentMonth();
  };

  const onSelectMonth = (month: string): void => {
    setSelectedItem(month);
    context && context.setSelectedMonth(month);
  };

  return (
    <>
      <Dropdown
        ghost
        label={getSelectMonthDropdownLabel()}
        closeOnItemClick
        classes={(currentClasses) => ({
          ...currentClasses,
          root: `${currentClasses.root} Monthdropdown`,
          button: `${currentClasses.button} Monthdropdown__button`,
        })}
      >
        {monthListItems.map((month: string, index: number) => (
          <DropdownSelectableItem
            key={index}
            selected={selectedItem === month}
            onClick={() => onSelectMonth(month)}
          >
            {month}
          </DropdownSelectableItem>
        ))}
      </Dropdown>
    </>
  );
};
export default MonthDropdown;
