export type LoginCallbackErrorProps = {
  error: Error;
};

export type AuthError = {
  name: string;
  errorSummary: string;
  errorCode: string;
};

export type User = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  idToken: string;
  expiresAt: number;
};

export type LoginContainerAuthErrorProps = AuthError;

export enum FlexRole {
  Admin = 'admin',
  FlexAdmin = 'flexAdmin',
  PartnerAdmin = 'partnerAdmin',
  ReadOnly = 'readOnly',
}
