import { FormFieldDefinition, Maybe } from '../../../types';
import { CreateCompanyFormFields } from '../components/CreateCompanyModal/CreateCompanyModal';
import { getCountries } from '../../../helpers/countries';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  companyForOperationsAtom,
  selectedPartnerSelector,
  companyAtom,
} from '../../../state';
import { useFetchData } from '../../../hooks/useFetchData';
import { Company } from '../types';
import { DependencyContainer } from '../../../DependencyContainer';

export type UseCreateCompanyFieldsValues = {
  loadingCompany: boolean;
  error: any;
  companyData: Maybe<Company>;
  mandatoryFields: Array<FormFieldDefinition<CreateCompanyFormFields>>;
  optionalFields: Array<FormFieldDefinition<CreateCompanyFormFields>>;
  flushCompanyData: () => void;
};
const { companyService } = new DependencyContainer();

const useCreateCompanyFields = (): UseCreateCompanyFieldsValues => {
  const selectedPartner = useRecoilValue(selectedPartnerSelector);
  const setCompany = useSetRecoilState(companyAtom);
  const companyForOperations = useRecoilValue(companyForOperationsAtom);

  const [loading, companyData, companyError, flushCompanyData] = useFetchData<
    Company,
    any
  >(
    async () => {
      if (!selectedPartner || !companyForOperations?.companyId) {
        return;
      }
      return await companyService.getCompany(
        selectedPartner.id,
        companyForOperations?.companyId,
      );
    },
    (company: Company) => {
      setCompany(company);
    },
    [selectedPartner, companyForOperations],
  );
  const mandatoryFields: Array<FormFieldDefinition<CreateCompanyFormFields>> = [
    {
      labelId: 'addCompany.form.legalName.label',
      name: CreateCompanyFormFields.LegalName,
      type: 'text',
      required: false,
      isVisible: true,
    },
    {
      labelId: 'addCompany.form.businessName.label',
      name: CreateCompanyFormFields.BusinessName,
      type: 'text',
      required: false,
      isVisible: true,
    },
    {
      labelId: 'addCompany.form.TIN.label',
      name: CreateCompanyFormFields.TaxIdNumber,
      type: 'text',
      required: false,
      isVisible: true,
    },
    {
      labelId: 'addCompany.form.legalAddress.label',
      name: CreateCompanyFormFields.LegalAddress,
      type: 'text',
      required: false,
      isVisible: true,
    },
    {
      labelId: 'addCompany.form.country.label',
      name: CreateCompanyFormFields.Country,
      type: 'select',
      placeholder: 'Select country',
      required: false,
      isVisible: true,
      options: getCountries().map((c) => ({
        labelId: c.label,
        value: c.value,
      })),
    },
  ];

  const optionalFields: Array<FormFieldDefinition<CreateCompanyFormFields>> = [
    {
      labelId: 'addCompany.form.primaryEmail.label',
      name: CreateCompanyFormFields.PrimaryEmail,
      type: 'text',
      required: false,
      isVisible: true,
    },
    {
      labelId: 'addCompany.form.primaryPhone.label',
      name: CreateCompanyFormFields.PrimaryPhone,
      type: 'text',
      required: false,
      isVisible: true,
    },
    {
      labelId: 'addCompany.form.website.label',
      name: CreateCompanyFormFields.Website,
      type: 'text',
      required: false,
      isVisible: true,
    },
  ];

  return {
    mandatoryFields,
    optionalFields,
    loadingCompany: loading,
    error: companyError,
    companyData: companyData,
    flushCompanyData,
  };
};

export default useCreateCompanyFields;
