import React, { FunctionComponent, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  GridItem,
  Heading,
  Button,
  IconButton,
  Dropdown,
  Icons,
  Table,
  Pagination,
  DropdownItem,
  PendingContent,
  Spinner,
  notify,
} from 'plume-ui';
import {
  selectedPartnerSelector,
  companyForOperationsAtom,
  introspectAtom,
} from '../../../state';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Company } from '../types';
import FormattedMessage from '../../../utils/components/FormattedMessage';
import { useTrackEvent } from '../../trackingAnalytics/hooks/useTrackEvent';
import { MixPanelEvents } from '../../../mixPanelEvents';
import { AvailableScreens } from '../../trackingAnalytics/types';
import CreateCompanyModal from '../components/CreateCompanyModal/CreateCompanyModal';
import { useFetchData } from '../../../hooks/useFetchData';
import { FlexApiResponse } from '../../../types';
import { DependencyContainer } from '../../../DependencyContainer';
import { getLabelOfCountryCode } from '../../../helpers/countries';
import { FlexRole } from '../../login/types';
import useCopyCompanyId from '../hooks/useCopyCompanyId';
import useWithConfirmation from '../../../hooks/useWithConfirmation';
import { AxiosError } from 'axios';

const CompaniesContainer: FunctionComponent = () => {
  const { t } = useTranslation();
  const withConfirmation = useWithConfirmation();
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const { companyService } = new DependencyContainer();
  const getIntrospect = useRecoilValue(introspectAtom);
  const selectedPartner = useRecoilValue(selectedPartnerSelector);
  const trackEvent = useTrackEvent();
  const setCompanyOperations = useSetRecoilState(companyForOperationsAtom);
  const onCopyCompanyId = useCopyCompanyId<any>();
  useEffect(() => {
    trackEvent({
      eventName: MixPanelEvents.SCREEN,
      additionalContent: {
        SCREEN: AvailableScreens.Companies,
      },
    });
  }, []);

  const [
    loading,
    companies,
    error,
    refetchCompanies,
    isFetchComplete,
  ] = useFetchData<FlexApiResponse<Company[]>, any>(
    async () => {
      if (!selectedPartner) {
        return;
      }
      return await companyService.getCompanies(selectedPartner.id, page);
    },
    undefined,
    [selectedPartner, page],
    () => selectedPartner === undefined,
  );
  const headerRow = [
    {
      name: t('companies.table.legalName'),
      fieldName: 'legalName',
    },
    { name: t('companies.table.businessName'), fieldName: 'businessName' },
    { name: t('companies.table.legalAddress'), fieldName: 'legalAddress' },
    {
      name: t('companies.table.country'),
      fieldName: 'country',
      render: (company: Company) => {
        return getLabelOfCountryCode(company.country);
      },
    },
    {
      render: (company: Company) => {
        if (getIntrospect?.role !== FlexRole.ReadOnly) {
          return (
            <Dropdown
              listPosition="right"
              closeOnItemClick
              button={
                <IconButton>
                  <Icons.DotsVerticalIcon />
                </IconButton>
              }
            >
              <DropdownItem onClick={() => onCopyCompanyId(company)}>
                <FormattedMessage id="copyCompanyId" />
              </DropdownItem>
              <DropdownItem onClick={() => onEdit(company)}>
                <FormattedMessage id="edit" />
              </DropdownItem>
              <DropdownItem onClick={() => onDelete(company)}>
                <FormattedMessage id="delete" />
              </DropdownItem>
            </Dropdown>
          );
        }
      },
    },
  ];
  const onEdit = (company: Company) => {
    if (!company.id) return;
    setCompanyOperations({ companyId: company.id });
    setOpen(true);
  };

  const onDelete = async (company: Company) => {
    withConfirmation({
      title: t('companyActions.deleteCompanyModal.title'),
      body: t('companyActions.deleteCompanyModal.description'),
      confirmButtonLabel: t('delete'),
      cancelButtonLabel: t('cancel'),
      onConfirm: async () => {
        if (!company) {
          return;
        }
        try {
          await companyService.deleteCompany(selectedPartner?.id, company.id);

          notify({
            title: t('success'),
            body: t('companyActions.delete.successMessage'),
            type: 'success',
          });
          await refetchCompanies();
          trackEvent({
            eventName: MixPanelEvents.DELETE_COMPANY_SUCCESS,
            additionalContent: {
              partnerId: selectedPartner?.id || 'unknown',
              companyId: company.id,
            },
          });
        } catch (error) {
          trackEvent({
            eventName: MixPanelEvents.DELETE_COMPANY_FAILURE,
            additionalContent: {
              partnerId: selectedPartner?.id || 'unknown',
              companyId: company.id,
            },
          });
          const mappedError = error as AxiosError;
          notify({
            title: t('companyActions.delete.failureHeading'),
            body: mappedError?.response?.data?.message,
            type: 'error',
          });
        }
      },
    });
  };
  const tableDataRows = companies?.items.map((c: Company) => ({
    id: c.id,
    legalName: c.legalName,
    businessName: c.businessName,
    legalAddress: c.legalAddress,
    country: c.country,
  }));

  const totalPageCount = companies?.meta.totalPages
    ? companies?.meta.totalPages
    : 1;
  return (
    <>
      <Helmet>
        <title> {t('companies.title')} </title>
      </Helmet>
      <div className="CompaniesContainer p-xl">
        <Grid>
          <GridItem colSpan="12" tabletColSpan="6">
            <div className="CompaniesContainer__headerContainer">
              <Heading level={1} ocrey>
                <FormattedMessage id="companies.title" />
              </Heading>
            </div>
            <div className="CompaniesContainer__search">
              <Button
                styleVariant="superprimary"
                onClick={() => setOpen(true)}
                disabled={getIntrospect?.role === FlexRole.ReadOnly}
              >
                <FormattedMessage id="companies.addCompany" />
              </Button>
            </div>
          </GridItem>

          <GridItem colSpan="12">
            <PendingContent
              loading={loading}
              loader={Spinner}
              isError={error}
              transparent
            >
              {isFetchComplete && (
                <>
                  {(!companies?.items || companies?.items?.length) === 0 ? (
                    <p className="CompaniesContainer__no-data">
                      <FormattedMessage id="companies.noCompaniesFound" />
                    </p>
                  ) : (
                    <div className="CompaniesContainer__main-content">
                      {tableDataRows && (
                        <Table
                          headerRow={headerRow as any}
                          dataRows={tableDataRows}
                        />
                      )}
                    </div>
                  )}
                </>
              )}
            </PendingContent>
          </GridItem>

          {totalPageCount > 1 && (
            <div className="CompaniesContainer__pagination">
              <Pagination
                expandDirection="top"
                totalPageCount={totalPageCount}
                onPageSelect={(page: number) => setPage(page + 1)}
              />
            </div>
          )}
        </Grid>

        <CreateCompanyModal
          isOpen={open}
          onRequestClose={() => setOpen(false)}
          onFinish={refetchCompanies}
        />
      </div>
    </>
  );
};
export default CompaniesContainer;
