import { copyToClipboard } from '../helpers/text';
import { notify } from 'plume-ui';
import { useTranslation } from 'react-i18next';
import { Template } from '../features/templates/types';

type UseCopyTemplateIdValues<T extends Template> = (entity: T) => void;

export function useCopyTemplateId<
  T extends Template
>(): UseCopyTemplateIdValues<T> {
  const { t } = useTranslation();

  async function onCopyTemplateId<T extends Template>(template: T) {
    if (!template) {
      return;
    }
    await copyToClipboard(template.id, () => {
      notify({
        title: t('success'),
        body: t('templateIdCopied'),
        type: 'success',
      });
    });
  }

  return onCopyTemplateId;
}

export default useCopyTemplateId;
