import { HttpClient } from '../../../httpClient';
import { FlexApiUrls } from '../../../urls';
import { AxiosResponse } from 'axios';
import { CreateCustomerSupportDto } from '../types';
import { FlexApiSingleResponse } from '../../../types';
import { Company } from '../../companies/types';

export default class CustomerSupportClient extends HttpClient {
  async createCustomerSupportDetail(
    dto: CreateCustomerSupportDto,
    companyId: string,
  ): Promise<AxiosResponse<CreateCustomerSupportDto>> {
    return await this.put<AxiosResponse<CreateCustomerSupportDto>>(
      FlexApiUrls.getCreateCustomerSupportDetails(companyId),
      dto,
    );
  }

  async fetchCustomerSupportDetails(
    companyId: string,
  ): Promise<AxiosResponse<FlexApiSingleResponse<CreateCustomerSupportDto>>> {
    const response = await this.get<
      AxiosResponse<FlexApiSingleResponse<CreateCustomerSupportDto>>
    >(FlexApiUrls.getCustomerSupportDetails(companyId));
    return response;
  }

  async fetchCompanyDetails(
    companyId: string,
  ): Promise<AxiosResponse<FlexApiSingleResponse<Company>>> {
    const response = await this.get<
      AxiosResponse<FlexApiSingleResponse<Company>>
    >(FlexApiUrls.getCompanyDetailsFlexAdminOnly(companyId));
    return response;
  }
}
