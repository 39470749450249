import * as yup from 'yup';
import { StringSchema } from 'yup';
import { isIP, isNumericString, isDomain } from '../helpers/validators';

yup.addMethod<StringSchema>(yup.string, 'ip', function (message) {
  return this.test({
    name: 'ip',
    message,
    test: (value) => {
      if (!value) {
        return true;
      }
      return isIP(value);
    },
  });
});

yup.addMethod<StringSchema>(yup.string, 'ipWithSubnet', function (message) {
  return this.test({
    name: 'ipWithSubnet',
    message,
    test: (value) => {
      if (!value) {
        return true;
      }
      const [ip, prefix] = value.split('/');
      const isValidIp = isIP(ip);
      if (!prefix) {
        return false;
      }
      const isSubnetNumeric = isNumericString(prefix);
      return isValidIp && isSubnetNumeric;
    },
  });
});

yup.addMethod<StringSchema>(yup.string, 'maxSubnetPrefix', function (
  max: number,
  message: string,
) {
  return this.test({
    name: 'maxSubnetPrefix',
    message,
    params: {
      max,
    },
    test: (value) => {
      if (!value) {
        return true;
      }
      const [, prefix] = value.split('/');
      if (!prefix) {
        return false;
      }
      return parseInt(prefix) <= max;
    },
  });
});

yup.addMethod<StringSchema>(yup.string, 'ipWithPort', function (message) {
  return this.test('ipWithPort', message, function (value) {
    if (!value) {
      return true;
    }
    const [ip, port] = value.split(':');
    if (!port) {
      return false;
    }
    const isPortNumeric = isNumericString(port);
    return isIP(ip || '') && isPortNumeric;
  });
});

yup.addMethod<StringSchema>(yup.string, 'remoteWAN', function (message) {
  return this.test({
    name: 'remoteWAN',
    message,
    test: (value) =>
      value === null ||
      value === '' ||
      value === undefined ||
      isDomain.test(value) ||
      isIP(value),
  });
});

export default yup;
