import {
  IndividualPolicyResponseItem,
  IndividualPolicyTable,
  SortablePoliciesFieldNames,
} from './types';
import { atom, selector } from 'recoil';
import { Maybe } from '../../types';
import { FieldSort } from 'plume-ui/dist/components/Table/Table';
import { DEFAULT_LIMIT_OF_RECORDS } from '../../config';

export const individualPolicyAtom = atom<Maybe<IndividualPolicyResponseItem[]>>(
  {
    key: 'individualPolicyAtom',
    default: [] || undefined,
  },
);

export const createindividualPolicyAtom = atom<
  Maybe<IndividualPolicyResponseItem>
>({
  key: 'createindividualPolicyAtom',
  default: undefined,
});

export type IndividualPolicyForOperations = {
  id: string;
  templateId: string;
};

export const policySelectedPageAtom = atom<number>({
  key: 'policySelectedPageAtom',
  default: 0,
});

export const policySortAtom = atom<FieldSort | null>({
  key: 'policySortAtom',
  default: null,
});

export const policiesSearchAtom = atom<string>({
  key: 'policiesSearchAtom',
  default: '',
});

export const individualPolicyForOperationsAtom = atom<
  Maybe<IndividualPolicyForOperations>
>({
  key: 'individualPolicyForOperationsAtom',
  default: undefined,
});

export const policiesTableSelector = selector<IndividualPolicyTable[] | null>({
  key: 'policiesTableSelector',
  get: ({ get }) => {
    const policies = get(individualPolicyAtom);

    if (!policies) {
      return null;
    }

    let policyTable: IndividualPolicyTable[] = [];

    policyTable = policies.map((policy) => {
      return {
        id: policy.id,
        employeeId: policy.employeeId,
        externalId: policy.externalId,
        templateId: policy.flexPolicy.id,
        template: policy.flexPolicy.name,
        enable: policy.enable,
        flexPolicy: policy.flexPolicy,
        locationId: policy.locationId,
        status: policy.status,
      };
    });
    return policyTable;
  },
});

export const policiesFilterAtom = atom<string>({
  key: 'policiesFilterAtom',
  default: '',
});

const sortPolicies = (
  policies: IndividualPolicyTable[],
  sort: FieldSort,
): IndividualPolicyTable[] => {
  const fieldName = sort.fieldName as SortablePoliciesFieldNames;
  return policies.sort(
    (a, b) => a[fieldName]!.localeCompare(b[fieldName]!) * sort.direction,
  );
};

const sortedPolicies = selector<IndividualPolicyTable[] | null>({
  key: 'sortedPolicies',
  get: ({ get }) => {
    const policies = get(policiesTableSelector);
    const sort = get(policySortAtom);
    if (sort) {
      return sortPolicies([...policies!], sort);
    }
    return policies;
  },
});

const filteredPolicies = selector<IndividualPolicyTable[] | null>({
  key: 'filteredPolicies',
  get: ({ get }) => {
    let policies = get(sortedPolicies);
    const filter = get(policiesSearchAtom);

    if (filter && policies) {
      policies = policies.filter(
        (policy) =>
          policy.template
            .toLocaleLowerCase()
            .includes(filter.toLocaleLowerCase()) ||
          policy.employeeId
            .toLocaleLowerCase()
            .includes(filter.toLocaleLowerCase()),
      );
    }
    return policies;
  },
});

export const currentPoliciesPage = selector<Maybe<IndividualPolicyTable[]>>({
  key: 'currentPoliciesPage',
  get: ({ get }) => {
    let policies = get(filteredPolicies);
    let selectedPage = get(policySelectedPageAtom);

    return policies?.slice(
      selectedPage * DEFAULT_LIMIT_OF_RECORDS,
      (selectedPage + 1) * DEFAULT_LIMIT_OF_RECORDS,
    );
  },
});

export const filteredPoliciesSelector = selector({
  key: 'filteredPoliciesSelector',
  get: ({ get }) => {
    const search = get(policiesSearchAtom);
    const policies = get(policiesTableSelector);
    let filteredPolicies: IndividualPolicyTable[] = [];

    if (policies) {
      if (search) {
        policies.forEach((policy: IndividualPolicyTable) => {
          if (policy.employeeId.toLowerCase().includes(search.toLowerCase())) {
            filteredPolicies.push(policy);
          }
          if (policy.template.toLowerCase().includes(search.toLowerCase())) {
            filteredPolicies.push(policy);
          }
        });
      } else {
        filteredPolicies = policies;
      }
    }
    return filteredPolicies;
  },
});

export const filteredSegmentsCount = selector<number>({
  key: 'filteredSegmentsCount',
  get: ({ get }) => {
    return get(filteredPoliciesSelector)?.length || 0;
  },
});
